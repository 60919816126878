import {Message, Menu, Empty, Card, Divider, Typography, Button, List, Modal} from '@arco-design/web-react';
import styles from "./index.module.less"
import {Author, Avatar, FollowButton} from "../index";
import {get, post} from "../../utils/request";
import {getQuery} from "../../utils";
import { useState,useEffect } from "react";
import {post as store,userSelf} from "../../store";
import { observer } from "mobx-react-lite"
import {IconLink} from "@arco-design/web-react/icon";
import {useNavigate} from "react-router-dom";

export default observer(function ({user,type = "followee" ,isSearchResult=false}){
    const [imgFail,setImgFail]=useState(false)
    const navigate=useNavigate()
    const handleUnfollow = item => {
        Modal.confirm({
            title: "是否确认取消关注？",
            onOk: async () => {
                await post("/api/follow/delete", { followee: item.followee._id });
                // setList(await store.refresh(query));
                Message.success("取消成功");
            }
        });
    };
    return (
        <List.Item
            key={user.id}
            style={{
                padding: "20px 0",
                borderBottom: "1px solid var(--color-fill-3)"
            }}
            actions={
                [<FollowButton key={'follow'} user={user}/>]
            }
        >

            <List.Item.Meta
                avatar={<Avatar src={user?.avatar} user={user} key={user?._id} />}
                title={user?.usernameHighlightedList?.map((o,i)=>
                    (<span key={i} className={o.highlight?'highlight':''}>{o.text}</span>)
                )}
                description={
                    <Typography.Paragraph
                        ellipsis={{
                            rows: 1,
                            ellipsisStr: "... "
                        }}
                    >
                        {user?.introduce}
                    </Typography.Paragraph>
                }
            />
        </List.Item>
    )
})

import { makeAutoObservable } from "mobx";
import * as request from "../utils/request";
import dayjs from "dayjs";
import orderBy from "lodash/orderBy";
import { events, EVENTS } from "../utils/events";

class List {
  list = [];
  loading = false;
  limit = 10;
  offset = 0;
  total = 1;
  path = "/";
  hasMore = true;
  cursorKey = 'createdAt';
  cursorAt=new Date();
  constructor(path,options={}) {
    makeAutoObservable(this);
    this.path = path;
    this.method = options.method||'post';
    this.cursorKey = options.cursorKey ?? 'createdAt'; // 默认使用

    events.on(EVENTS.CATEGORY_SUBSCRIBE, this.onCategorySubscribe);
    events.on(EVENTS.FOLLOW_USER, this.onFollowUser);

  }

  onCategorySubscribe = (context) => {
    const { id, subscribed } = context;
    if (!this.list.length) return;

    this.list.forEach((item, index) => {
      if (item._id !== id) return;
      this.list[index].subscribed = subscribed;
    });
  };

  // 同步关注信息
  onFollowUser = (context: any) => {
    const {activate, followee} = context;
    if (!this.list.length) {
      return;
    }
    // 是否是文章
    if (this.list[0].postType) {
      this.list.forEach(post => {
        if (post.user?._id !== followee) {
          return;
        }
        if (activate) {
          post.followed = true;
        } else {
          post.followed = false;
        }
      });
      return;
    }
    // 不是带有关注的list
    if (this.list[0]?.user?.followed !== undefined) {
      this.list.forEach(item => {
        if (item.user?._id !== followee) {
          return;
        }
        if (activate) {
          item.user.followed = true;
        } else {
          item.user.followed = false;
        }
      });
    }
    // 关注的list
    if (this.list[0]?.followee?.followed !== undefined) {
      this.list.forEach(item => {
        if (item.followee?._id !== followee) {
          return;
        }
        if (activate) {
          item.followee.followed = true;
        } else {
          item.followee.followed = false;
        }
      });
    }
  };

  updateItem = (id, payload) => {
    this.list = this.list.map((item) => {
      if (item._id === id) {
        return {
          ...item,
          ...payload,
        };
      }
      return item;
    });
  };

  // 添加一个元素
  removeItem(_id) {
    const index = this.list.findIndex((item) => item._id === _id);
    this.list.splice(index, 1);
  }
  // 添加一个元素
  pushItem(payload) {
    this.list = [...this.list, payload];
  }

  unshiftItem(payload) {
    this.list = [payload,...this.list];
  }

  // 没有则添加，有则更新
  upsertItem(id, payload,top=false) {
    const index = this.list.findIndex((item) => item._id === id);
    if (index === -1) {
      top?this.unshiftItem(payload):this.pushItem(payload);
    } else {
      this.updateItem(id, payload);
    }
  }

  getItemById(id) {
    return this.list.find((item) => item._id === id);
  }

  // 从新排序
  sortListBy(...params) {
    this.list = orderBy(this.list, ...params);
  }

  async getList(query) {
    this.loading = true;
    if (this.method.toUpperCase() === 'POST' && this.cursorKey) {
      query = {
        ...query,
        // 增加时间游标，解决突然发文重复问题
        [this.cursorKey]: {
          $lte: this.cursorAt.toISOString(),
        },
      };
    }
    return await request[this.method](this.path, query).finally(() => {
      this.loading = false;
    });
  }

  reset() {
    this.list = [];
    this.loading = false;
    this.limit = 10;
    this.offset = 0;
    this.total = 1;
    this.cursorAt = new Date();
  }

  refresh = async (query) => {
    this.cursorAt = new Date(); // 更新时间戳游标
    return this.loadmore(query, true);
  };

  loadmore = async (query, refresh = false) => {
    if (this.loading) return this.list;
    if (refresh) {
      this.offset = 0;
      this.total = 1;
    }
    if (this.offset >= this.total) return this.list;
    const limit = query.limit ?? this.limit;
    const offset = query.offset ?? this.offset;
    const result = await this.getList({ ...query, offset, limit });
    if (!result) return this.list;
    this.list = [...(refresh ? [] : this.list), ...result.list];
    this.offset = offset + limit;
    this.total = result.total;
    return this.list;
  };
}

// 不刷新的常量
const homeList = new List("/api/post/list",{
  cursorKey:''
});
const homeList2 = new List("/api/post/list",{
  cursorKey:''
});
const conversation = new List("/api/conversation/list",{
  method:"get",
  useUpsert: true,
});

const category = new List("/api/category/list");

const last7DayISO = dayjs().subtract(7, "days").toISOString();
homeList.loadmore({
  createdAt: { $gte: last7DayISO },
  postType:1,
  limit: 3,
  sort: { dig: -1 },
  status: 1,
});
homeList2.loadmore({
  createdAt: { $gte: last7DayISO },
  postType:2,
  limit: 3,
  sort: { dig: -1 },
  status: 1,
});
category.loadmore({limit:20});
export { List, homeList,homeList2,conversation,category };

import { Notification } from "@arco-design/web-react";

export default class NetworkUtils {
  static isOnline(): boolean {
    return navigator.onLine;
  }

  static onOnline(callback: () => void): void {
    window.addEventListener("online", callback);
  }

  static onOffline(callback: () => void): void {
    window.addEventListener("offline", callback);
  }

  static showToast(message: string): void {
    // Code to display the toast message
  }
}

const div = document.createElement("div");
const body = document.querySelector("body");
body?.appendChild(div);
let networkNotify: any = null;

function close() {
  if (networkNotify) {
    networkNotify.close();
    networkNotify = null;
  }
}

// 全局的网络监听，掉线提醒
NetworkUtils.onOffline(() => {
  Notification.warning({
    title: '当前网络不可用',
    content: '请检查网络连接!',
  })
});

NetworkUtils.onOnline(() => {
  close();
});

import { Layout,Menu,Grid,BackTop } from '@arco-design/web-react';
import { Link ,useLocation} from "react-router-dom";
import { UserInfo,Search } from "../index";
import { useEffect,useState } from "react";
import styles from "./index.module.less"
import logo from "../../assets/images/logo.png"
import {MP_INTRODUCE_URL} from "../../utils/constants";
const Header = Layout.Header;
const MenuItem = Menu.Item;
const SubMenu = Menu.SubMenu;
const Row = Grid.Row;
const Col = Grid.Col;
function getKeys(){
    let selectedKeys=window.location.pathname.split('/').filter(Boolean)
    selectedKeys=selectedKeys.length?selectedKeys:['/']
    return selectedKeys
}
export default function (){

    const [selectedKeys,setSelectedKeys]=useState(getKeys())
    const location = useLocation();

    useEffect(() => {
        setSelectedKeys(getKeys())
    }, [location]);
    return (
        <Header className={styles.header}>
            <Row align={'center'} style={{flexWrap:'nowrap'}}>
                <Col flex='150px' >
                    <Link
                        to={'/'}
                        className={styles.logo}
                        style={{
                            backgroundImage: `url(https://meikan-public-images.oss-cn-beijing.aliyuncs.com/imeikan/images/635fe18c2d7516bf3ff4de1e.jpeg)`,
                        }}
                    />
                </Col>
                <Col flex='auto'>
                    <Menu ellipsis mode='horizontal' key={selectedKeys.toString()} defaultSelectedKeys={selectedKeys}>
                        <Link to={'/'}>
                            <MenuItem key='/'>
                                首页
                            </MenuItem>
                        </Link>
                        <Link to={'/posts'}>
                            <MenuItem key='posts'>
                                推文
                            </MenuItem>
                        </Link>
                        <Link to={'/category'}>
                            <MenuItem key='category'>
                                专题
                            </MenuItem>
                        </Link>
                        <a href={MP_INTRODUCE_URL} target={'_blank'} rel="noreferrer">
                            <MenuItem key='usage'>
                                使用说明
                            </MenuItem>
                        </a>
                        <Link to={'/about'}>
                            <MenuItem key='about'>
                                关于我们
                            </MenuItem>
                        </Link>
                        {/*<Link to={'/download'}>*/}
                        {/*    <MenuItem key='about'>*/}
                        {/*        每日美刊客户端下载*/}
                        {/*    </MenuItem>*/}
                        {/*</Link>*/}
                    </Menu>
                </Col>

                <Col flex='600px' className={styles.right} >
                    <Search />
                    <UserInfo/>
                </Col>
            </Row>
            <BackTop/>
        </Header>
    )
}

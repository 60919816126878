import { makeAutoObservable } from "mobx";
import { post } from "../utils/request";
import { notify, category } from "./index";
import Cookies from "js-cookie";
import settingStore from "./setting";
import { getSocket } from "../utils/socket";
import getTicket from "../utils/security";
import {Message} from "@arco-design/web-react";
import { events, EVENTS } from "../utils/events";

const LOGIN_KEY = process.env.REACT_APP_ENV === "development" ? "isLogin" : "isLogin";

class UserSelf {
  _id = "";
  id = "";
  name = "";
  introduce = "暂无简介";
  avatar = "";
  subscription = [];
  posts = 0;
  honorValue = 0;
  followers = 0;
  followed = false;
  points = 0;
  ip = {};
  totalDig = 0;
  wechatName = "";
  wechatAvatar = "";
  category = "";
  degree = "";
  major = "";
  university = "";
  weight = "";
  isCertified = false;
  certifiedCategory = '';
  allUnreadCount = 0;
  deletedAt = null;
  ban={}
  modifyStatus=0;
  modifyInfo=null;
  constructor() {
    makeAutoObservable(this);
    events.on(EVENTS.USER_INFO_CHANGE,()=>{
      this.getUserInfo();
    })
  }

  // FIXME: 可能存在不一致
  get isLogin() {
    return !!this.id || !!Cookies.get(LOGIN_KEY);
  }

  isSelf(userId) {
    return this.id && this.id === userId;
  }

  setUserInfo(userInfo) {
    this._id = userInfo._id;
    this.id = userInfo.id;
    this.name = userInfo.name;
    this.introduce = userInfo.introduce || this.introduce;
    this.avatar = userInfo.avatar;
    this.subscription = userInfo.subscription;
    this.posts = userInfo.posts;
    this.honorValue = userInfo.honorValue;
    this.followers = userInfo.followers;
    this.followed = userInfo.followed;
    this.points = userInfo.points;
    this.ip = userInfo.ip;
    this.totalDig = userInfo.totalDig;
    this.wechatName = userInfo.wechat?.nickname;
    this.wechatAvatar = userInfo.wechat?.headimgurl;
    this.category = userInfo?.certify?.category;
    this.isCertified = userInfo?.certify?.isCertified;
    this.degree = userInfo?.certify?.degree;
    this.major = userInfo?.certify?.major;
    this.university = userInfo?.certify?.university;
    this.weight = userInfo?.certify?.weight;
    this.certifiedCategory = userInfo?.certify?.category?.title;
    this.deletedAt = userInfo.deletedAt;
    this.ban = userInfo.ban;
    this.modifyStatus=userInfo.modifyStatus;
    this.modifyInfo=userInfo.modifyInfo;

    if(!userInfo._id) return;
    // initSocket.
    getSocket();
    // initSetting
    settingStore.fetchSelfSetting();
  }

  async getUserInfo() {
    const result = await post("/api/user/info");
    if (result && result.id) {
      this.setUserInfo(result);
      return result;
    }
    return false;
  }
  async getIsCertified() {
    const result = await post("/api/user/info");
    if (result && result.id) {
      return result.certify.isCertified;
    }
    return false;
  }
  updateUserInfo = async payload => {
    const success = await post("/api/user/update", payload);
    if (success) {
      await this.getUserInfo();
    }
    return success;
  };

  async register(data) {
    const result = await post("/api/auth/register", data);
    if (result) {
      return result;
    }
    return false;
  }

  async login(data) {
    const result = await post("/api/auth/login", data);
    if (result) {
      this.loginSuccess(result, true);
      return result;
    }
    return false;
  }

  loginSuccess(user) {
    this.setUserInfo(user);
    category.getList();
    notify.getCount();
  }

  async logout() {
    const result = await post("/api/auth/logout");
    if (result) {
      this.setUserInfo({});
      window.location.href = "/";
      return true;
    }
    return false;
  }

  async sendCheckCode(phoneNumber,smsType="register") {
    const { ticket, randstr } = await getTicket();
    const result = await post("/api/sms/sendcode", { phoneNumber,smsType,ticket, randstr });
  }

  async getPhoneNumber() {
    const result = await post("/api/user/number");
    return result.phoneNumber;
  }

  async deleteUser(checkCode) {
    const result = await post("/api/user/delete", { checkCode });
    if (result) {
      this.setUserInfo({});
      Message.success("注销成功");
      setTimeout(() => {
        window.location.href = "/";
      }, 800);
      return true;
    }
    return false;
  }

  async unbindWechat({checkCode}) {
    const result = await post("/api/user/unbind", { thirdAccountType: "wechat",checkCode });
    if (result) {
      this.setUserInfo(result);
      return true;
    }
    return false;
  }
}
const userSelf = new UserSelf();
userSelf.getUserInfo();
export default userSelf;

import {Popconfirm, Menu, Message, Card, Divider, Button} from '@arco-design/web-react';
import styles from "./index.module.less"
import {Author} from "../index";
import {get} from "../../utils/request";
import {getQuery} from "../../utils";
import {category,userSelf} from "../../store";
import { useState,useEffect } from "react";
import { IconRight } from '@arco-design/web-react/icon';
import { observer } from "mobx-react-lite"


export default observer(function (){
    const query=getQuery()
    const id=query.category
    const isSubscribed= !!userSelf?.subscription.find(o=>o._id===id)

    const handleSubscribe=async ()=>{
        await category.subscribe(id)
        && Message.success('订阅成功')
    }
    const handleUnsubscribe=async ()=>{
        await category.subscribe(id,'unsubscribe')
        && Message.success('取消成功')
    }
    return (
        <Card
            className={styles.subscribe}
            bordered={false}>
            {
                isSubscribed?
                    <Popconfirm
                        title='确认取消订阅吗?'
                        onOk={handleUnsubscribe}
                    >
                        <Button style={{width:'100%'}}>已订阅</Button>
                    </Popconfirm> :
                    <Button type={'primary'} style={{width:'100%'}} onClick={handleSubscribe}>订阅专栏</Button>
            }
        </Card>
    )
})

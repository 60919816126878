import { Card } from "@arco-design/web-react";
import { Form, Select, Button, Image, Message, Alert, Input } from "@arco-design/web-react";
import react, { useState, useEffect } from "react";
import Uploader from "./upload";
import styles from "./index.module.less";
import { List } from "@arco-design/web-react";
import certify from "../../../store/certify";
import { myComponents, evidenceOptions, evidenceStatus, certifyInfoStatus } from "./constant";
import { observer } from "mobx-react-lite";

const FormItem = Form.Item;
const Option = Select.Option;
const TextArea = Input.TextArea;
export default observer(function () {
  const [selectValue, setSelectValue] = useState("studentCard");
  const [IDCardUrl, setIDCardUrl] = useState();
  const [evidenceUrl, setEvidenceUrl] = useState();
  const [haveSubmit, setIsSubmit] = useState(false); //是否已经提交，防止用户多次提交
  const [haveCertified, setHaveCertified] = useState(); //是否提交过认证信息
  const [auditStatus, setAuditStatus] = useState(); //认证提交后对应的状态 0：审核中，1：成功，2：失败
  const [certifyData, setCertifyData] = useState({});
  const [isAnew, setIsAnew] = useState(false); //是否为重新提交，用户判断取消按钮是否需要出现
  const [loading, setLoading] = useState(true); //加载
  const [isUpload, setIsUpload] = useState(true); //是否是上传，用于判断upload组件是用于上传还是展示图片
  const [data, setData] = useState({});
  const initData = data => {
    setIDCardUrl(data.IDCardUrl);
    setEvidenceUrl(data.evidenceUrl);
    setSelectValue(data.evidenceType);
    setAuditStatus(data.status);
    setCertifyData(data.formData);
  };
  const getAuditStatus = async () => {
    await certify.getMessage().then(res => {
      if (JSON.stringify(res) === "{}") {
        setHaveCertified(false);
      } else {
        setHaveCertified(true);
        initData(res);
        setIsUpload(false);
        setData(res);
      }
      setLoading(false);
    });
  };
  useEffect(() => {
    getAuditStatus();
  }, []);
  const onSubmit = async () => {
    if ((auditStatus === 1 || auditStatus === 2) && !isAnew) {
      setHaveCertified(false);
      setEvidenceUrl();
      setIDCardUrl();
      setIsAnew(true);
      setIsUpload(true);
      setSelectValue("studentCard");
      return;
    }
    if (haveSubmit) return Message.info("请勿重复提交");
    if (!IDCardUrl || !evidenceUrl) return Message.info("请上传图片");
    const formdata = {
      evidenceType: selectValue,
      IDCardUrl,
      evidenceUrl
    };
    await certify.create(formdata).then(res => {
      setIsSubmit(true);
      setIsAnew(false);
      setHaveCertified(true);
      setAuditStatus(0);
      setIsUpload(false);
      Message.info("上传成功");
    });
  };
  const cancelRepeat = () => {
    setHaveCertified(true);
    setIsUpload(false);
    initData(data);
    setIsAnew(false);
  };

  return loading ? (
    <List bordered={false} loading={loading} className={styles.paddingTop}></List>
  ) : (
    <Card
      className={styles["degree-container"]}
      title={"填写学历认证信息"}
      bodyStyle={{ position: "relative" }}
    >
      <div className={styles["tip-container"]}>
        {haveCertified ? (
          myComponents[auditStatus]
        ) : auditStatus === 1 || auditStatus === 2 ? null : (
          <Alert banner={true} className={styles.banner} content="您还未认证学历" />
        )}
      </div>
      <br />
      <Form className={styles["form-container"]} autoComplete="off">
        <FormItem className={styles["from-item"]} required={!haveCertified} label={"身份证"}>
          <div className={styles["item-container"]}>
            <div className={styles["upload-container"]}>
              {isUpload ? (
                <Uploader
                  onChange={url => {
                    setIsSubmit(false);
                    setIDCardUrl(url);
                    Message.success("上传成功!");
                  }}
                />
              ) : (
                <div>
                  <Image className={styles["image"]} src={IDCardUrl} alt="身份证图片" />
                </div>
              )}
            </div>
            {!haveCertified && (
              <div className={styles["example"]}>
                <div className={styles["title"]}>示例</div>
                <div className={styles["image-container"]}>
                  <Image
                    className={styles["image"]}
                    src={require("../../../assets/images/sfz.jpeg")}
                    alt="身份证上传示例"
                  />
                </div>
              </div>
            )}
          </div>
        </FormItem>
        <FormItem required={!haveCertified}  label={"验证方式"}>
          <Select
            style={{ width: 200 }}
            value={selectValue}
            disabled={haveCertified}
            onChange={value => {
              setIsSubmit(false);
              setSelectValue(value);
            }}
          >
            {evidenceOptions.map(option => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </FormItem>
        <FormItem required={!haveCertified} label={`${evidenceStatus[selectValue]}`}>
          <div className={styles["item-container"]}>
            <div className={styles["upload-container"]}>
              {isUpload ? (
                <Uploader
                  onChange={url => {
                    setIsSubmit(false);
                    setEvidenceUrl(url);
                    Message.success("上传成功!");
                  }}
                />
              ) : (
                <Image className={styles["image"]} src={evidenceUrl} alt="证明材料" />
              )}
            </div>
            {!haveCertified && (
              <div className={styles["example"]}>
                <div className={styles["title"]}>示例</div>
                <div className={styles["image-container"]}>
                  <Image
                    className={styles["image"]}
                    src={require(`../../../assets/images/${selectValue}.jpeg`)}
                    alt="身份证上传示例"
                  ></Image>
                </div>
              </div>
            )}
          </div>
        </FormItem>
        {haveCertified &&
          (auditStatus === 1 ? (
            <div>
              {certifyInfoStatus
                .filter(item => certifyData[item.value])
                .map(item => (
                  <Form.Item label={item.label} key={item.value}>
                    <Input placeholder={certifyData[item.value]} disabled />
                  </Form.Item>
                ))}
            </div>
          ) : (
            auditStatus === 2 && (
              <Form.Item label="失败原因">
                <TextArea
                  disabled
                  placeholder={certifyData?.reason}
                  autoSize
                  style={{ minHeight: 100 }}
                />
              </Form.Item>
            )
          ))}
        <FormItem>
          {haveCertified ? (
            <Button
              style={{ width: 100, marginLeft: 81 }}
              type="primary"
              htmlType="submit"
              onClick={onSubmit}
              disabled={auditStatus === 0}
            >
              {auditStatus === 0 ? "提交" : "重新提交"}
            </Button>
          ) : (
            <div className={styles["btn-wrap"]}>
              <Button
                style={{ width: 100 }}
                type="primary"
                htmlType="submit"
                onClick={onSubmit}
                disabled={auditStatus === 0}
              >
                提交
              </Button>
              {isAnew && (
                <Button style={{ width: 100 }} htmlType="submit" onClick={cancelRepeat}>
                  取消
                </Button>
              )}
            </div>
          )}
        </FormItem>
        {!haveCertified && (
          <div className={styles["tip-container"]}>
            <div className={styles["tip-title"]}>提示：</div>
            <div className={styles["tip-content"]}>
              1.身份证除姓名部分外，其他信息可进行打码遮挡；
              <br />
              2.学生证、毕业证、学籍报告除姓名、专业、学历、学校部分外，其他信息可进行打码遮挡；
              <br />
              3.只针对专业/学位/学校&机构进行认证，不进行个人认证；
              <br />
              4.两张图片的姓名必须一致
            </div>
          </div>
        )}
      </Form>
    </Card>
  );
});

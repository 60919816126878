import {Layout, Card, Grid, Tabs, Button, Divider, Empty} from '@arco-design/web-react';
import styles from "./index.module.less"
import {useEffect, useState,useRef} from 'react'
import {getQuery} from '../../utils'
import {useNavigate, useLocation} from "react-router-dom";
import { IconPlus,IconSubscribeAdd } from '@arco-design/web-react/icon';
import {SubscribeCard, RenderList,Leaderboard,UserCard,CompanyInfo,Content,QrCard} from "../../components";
import {userSelf,category,post,} from "../../store";
import searchStore from "../../store/search";
import { observer } from "mobx-react-lite"
import { events, EVENTS } from "../../utils/events";

const Row = Grid.Row;
const Col = Grid.Col;
const TabPane = Tabs.TabPane;

export default observer(function (){
    const location = useLocation();
    const navigate = useNavigate();

    const query=getQuery()
    const {category:categoryId,tab}=query
    const postListRef=useRef()
    const userListRef=useRef()
    const subscriptionListRef=useRef()
    const refMap={
        post:postListRef,
        user:userListRef,
        subscription:subscriptionListRef,
    }
    useEffect(() => {
        events.on(EVENTS.SEARCH,()=>{
            let ref=refMap[searchStore.type]
            console.log(postListRef,userListRef)
            ref.current?.refresh({
                keyword:searchStore.keyword,
                type:searchStore.type,
            })
        })
        return ()=>{
            events.off(EVENTS.SEARCH)
        }
    }, []);


    const searchQuery={keyword:searchStore.keyword}

    const handleTabChange=(key)=>{
        searchStore.type=key
        navigate(`/search?${searchStore.queryStr}`, { replace: true });
        events.emit(EVENTS.SEARCH)
    }

    const renderUser=(item,index)=>{
        return <UserCard user={item}/>
    }


    return (
        <Content className={styles.postsPage}>
            {
                <Row gutter={24}>
                    <Col span={18} className={styles.posts}>
                        <Tabs
                            activeTab={searchStore.type}
                            onChange={handleTabChange}
                            size={'large'}>
                            <TabPane key='post' title='全部文章'>
                                <RenderList
                                    ref={postListRef}
                                    tab={'post'}
                                    showCategory
                                    query={searchQuery}
                                    path='/api/search/post'
                                    cursorKey={'publishedAt'}/>
                            </TabPane>
                            {
                                userSelf.isLogin?(
                                    <TabPane key='subscription' title='订阅专栏'>
                                        <RenderList
                                            ref={subscriptionListRef}
                                            tab={'subscription'}
                                            showCategory
                                            query={searchQuery}
                                            path='/api/search/subscription'
                                            cursorKey={'publishedAt'}/>
                                    </TabPane>
                                ):null
                            }
                            <TabPane key='user' title='用户'>
                                <RenderList
                                    ref={userListRef}
                                    tab={'user'}
                                    query={searchQuery}
                                    path='/api/search/user'
                                    renderItem={renderUser}
                                />
                            </TabPane>
                        </Tabs>
                    </Col>
                    <Col span={6}>
                        <QrCard/>
                        <CompanyInfo/>
                    </Col>
                </Row>
            }

        </Content>
    )
})

import {
  Menu,
  Grid,
  List,
  Button,
  Divider,
  Badge,
} from "@arco-design/web-react";
import styles from "./index.module.less";
import { Author, Content } from "../../components";
import MessageList from "./MessageList";
import {
  IconUserGroup,
  IconBulb,
  IconMessage,
  IconStar,
  IconThumbUp,
  IconNotification,
} from "@arco-design/web-react/icon";
import { BrowserRouter, Routes, Link, Outlet } from "react-router-dom";
import { useState } from "react";
import { userSelf, notify } from "../../store";
import { observer } from "mobx-react-lite";

const MenuItem = Menu.Item;
const SubMenu = Menu.SubMenu;
const Row = Grid.Row;
const Col = Grid.Col;

const LIST_TYPE = {
  ARTICLE_COMMENT: "article_comment",
  ARTICLE_DIG: "article_dig",
  ARTICLE_FAVOR: "article_favor",
  USER_FOLLOW: "user_follow",
  COMMENT_REPLY: "comment_reply",
  COMMENT_DIG: "comment_dig",
  PLATFORM_NOTIFY: "platform_notify",
};

const list = [
  {
    menu: "评论",
    title: "评论了你的推文",
    icon: <IconMessage />,
    type: LIST_TYPE.ARTICLE_COMMENT,
    unreadKey: "commentPost",
    query: { objectType: "Post", action: "comment" },
  },
  {
    menu: "点赞",
    title: "点赞了你的推文",
    icon: <IconThumbUp />,
    type: LIST_TYPE.ARTICLE_DIG,
    unreadKey: "digPost",
    query: { objectType: "Post", action: "dig" },
  },
  {
    menu: "收藏",
    title: "收藏了你的推文",
    icon: <IconStar />,
    type: LIST_TYPE.ARTICLE_FAVOR,
    unreadKey: "favoritePost",
    query: { objectType: "Post", action: "favorite" },
  },
  {
    menu: "用户关注",
    title: "关注了你",
    icon: <IconUserGroup />,
    type: LIST_TYPE.USER_FOLLOW,
    unreadKey: "followUser",
    query: { objectType: "User", action: "follow" },
  },
  {
    menu: "评论回复",
    title: "回复了你的评论",
    icon: <IconMessage />,
    type: LIST_TYPE.COMMENT_REPLY,
    unreadKey: "replyComment",
    query: { objectType: "Comment", action: "reply" },
  },
  {
    menu: "评论点赞",
    title: "点赞了你的评论",
    icon: <IconThumbUp />,
    type: LIST_TYPE.COMMENT_DIG,
    unreadKey: "digComment",
    query: { objectType: "Comment", action: "dig" },
  },
  {
    menu: "系统通知",
    title: (item) => {
      let object=''
      switch (item.objectType){
        case 'Certify':
          object='用户认证'
          break
        case 'Post':
          object='推文'
          break
      }
      if (item.action === "block") {
        return `${object}不符合社区规范`;
      } else if (item.action === "pass") {
        return `${object}审核通过`;
      } else {
        return "系统通知";
      }
    },
    icon: <IconNotification />,
    type: LIST_TYPE.PLATFORM_NOTIFY,
    unreadKey: "systemMessage",
    query: { type: "system" },
  },
];

export default observer(function () {
  const [selectedKey, setSelectedKey] = useState("article_comment");
  const [cacheSet] = useState(new Set(["article_comment"]));

  const handleMenuClick = (key) => {
    setSelectedKey(key);
    cacheSet.add(key); // 点击过的加入set
  };

  return (
    <Content className={styles.message}>
      <Row gutter={24} className={styles.row}>
        <Col span={4} className={styles.col}>
          <Menu
            className={styles.sideMenu}
            defaultSelectedKeys={[selectedKey]}
            onClickMenuItem={handleMenuClick}
          >
            {list.map((o) => (
              <MenuItem key={o.type}>
                <Badge
                  count={notify[o.unreadKey]}
                  key={o.type}
                  style={{ width: "100%" }}
                >
                  {o.icon} {o.menu}
                </Badge>
              </MenuItem>
            ))}
          </Menu>
        </Col>
        <Col span={20} className={styles.col}>
          {list.map(
            (o) =>
              cacheSet.has(o.type) && (
                <MessageList
                  key={o.type}
                  hide={selectedKey !== o.type}
                  query={o.query}
                  title={o.title}
                />
              )
          )}
        </Col>
      </Row>
    </Content>
  );
});

import { Button, Card, Modal,Popover} from '@arco-design/web-react';
import styles from "./index.module.less"
import { useState,useEffect } from "react";
import {IconBulb, IconFile, IconUserGroup, IconThumbUp, IconQuestionCircle} from '@arco-design/web-react/icon';
import { observer } from "mobx-react-lite"
import {userSelf,follow,post} from "../../store";

export default observer(function (){

    return (
        <Card
            className={styles.qrCard}
            title='关注公众号'
            // extra={(
            //     <Helper position='tr'>
            //         <div>荣誉值=评论数+收藏数+点赞数+阅读数／100</div>
            //     </Helper>
            // )}
            bordered={false}>
            <div className={styles.img}>
                <img width={200} src={'https://meikan-public-images.oss-cn-beijing.aliyuncs.com/imeikan/assets/2022-12-08233452-qr.jpeg'} alt=""/>
            </div>
            <div className={styles.text}>
                关注公众号：美刊网，可以在手机端浏览&发文，浏览&发文入口在公众号“服务”里
            </div>

        </Card>
    )
})

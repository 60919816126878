import styles from "./index.module.less";
import { IconMessage, IconSubscribeAdd } from "@arco-design/web-react/icon";
import { IChatMessage } from "../../types";
import React, { useRef, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { simpleDate } from "../../utils";
import dayjs from "dayjs";
import {Avatar} from "@arco-design/web-react";
// import { Avatar } from "@arco-design/mobile-react";
import { IconMore,IconExclamationCircleFill } from "@arco-design/web-react/icon";

const MAX_DURATION = 30 * 60 * 1000; // 30分钟
interface IProps {
  children?: React.ReactNode;
  icon?: "message" | "subscribe";
  style?: React.CSSProperties;
  message: IChatMessage;
  lastMessage?: IChatMessage; // 当有lastMessage时，如果两条消息间隔超过30分钟，则显示时间
  onRead?: (message: IChatMessage) => void;
}
export default observer(function Message({ children = "暂无数据", onRead, style, message, lastMessage }: IProps) {
  const { fromUser, isSelf, createdAt } = message;
  const showTime =
    !lastMessage || new Date(createdAt).getTime() - new Date(lastMessage.createdAt).getTime() > MAX_DURATION;
  const messageRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          message?.read === 0 && onRead?.(message); // 发送message read消息给服务端
        }
      });
    }, {});

    if (messageRef.current) {
      observer.observe(messageRef.current);
    }

    return () => {
      if (messageRef.current) {
        observer.unobserve(messageRef.current);
      }
    };
  }, [message]);

  return (
    <div className={styles.messageBlock} id={"msg_" + message._id}>
      {showTime && <div className={styles.time}>{simpleDate(message.createdAt)}</div>}
      <div className={`${styles.message} ${isSelf ? styles.isSelf : ""}`} ref={messageRef}>
        <Avatar className={styles.avatar}>
          <img src={fromUser.avatar} alt=""/>
        </Avatar>

        <div className={`${styles.content} ${isSelf ? styles.isSelf : ""}`}>
          {/*<div className={styles.triangle}></div>*/}
          {message?.content}
        </div>
        {message?.sendStatus !== 1 && <IconExclamationCircleFill className={styles.warn} />}
      </div>
      {message.failReason && <div className={styles.reason}>{message.failReason}</div>}
    </div>
  );
});

import { IUser } from "../types";
import { List } from "./List";
import { get } from "../utils/request";
import { makeAutoObservable } from "mobx";

interface ICache {
  user: IUser;
  listStore: List;
}

class Users {
  cache: Record<string, ICache>;
  constructor() {
    makeAutoObservable(this);
    this.cache = {};
  }
  async getUser(id: string) {
    if (this.cache[id]?.user) return this.cache[id]?.user;
    const result = await get("/api/user/get", { _id: id });
    if (result && result.id) {
      this.cache[id] = this.cache[id] || {};
      this.cache[id].user = result;
      return this.cache[id].user;
    }
    return {};
  }

  getListStore(id: string): List {
    if (this.cache[id]?.listStore) return this.cache[id]?.listStore;
    this.cache[id] = this.cache[id] || {};
    this.cache[id].listStore = new List("/api/post/userlist");
    return this.cache[id].listStore;
  }
}

// @ts-ignore
export default new Users();

import { makeAutoObservable } from "mobx"
import { get,post } from "../utils/request"
import {Message} from '@arco-design/web-react';
import userSelf from './userSelf';
import network from "../utils/network";
import { EVENTS, events } from "../utils/events";
const pageReadyTime = Date.now();

class Notify {
    digPost=0
    digComment=0
    commentPost=0
    replyComment=0
    favoritePost=0
    followUser=0
    systemMessage=0

    constructor() {
        makeAutoObservable(this)
    }

    get allUnreadCount(){
        return  Object.values(this).reduce((n,sum)=>sum+n,0)
        // return this.digPost+this.digComment+this.commentPost+this.favoritePost+this.replyComment
    }

     async getCount(){
         if (!network.isOnline()) return;
        const list=await get('/api/notify/count',{})
         if(!list) return
         // 记住获取前的系统消息数，如果新一次大于上一次，需要变更审核通过的文章状态
         const lastSystemMessage = this.systemMessage;
         // 自身属性
        Object.keys(this).forEach(key=>{
            this[key]=list.find(o=>o._id===key)?.unreadCount??0
        })
         list.forEach(item=>{
             if(item.type==='system'){
                 this.systemMessage+=item.unreadCount
             }
             if (item._id === 'banUser') {
                 // 禁言，解禁，更新用户状态
                 userSelf.getUserInfo();
             }
         })
         // 更新系统消息数，并尝试更新文章审核后的状态；系统消息有增量的时候刷新文章
         if (lastSystemMessage !== this.systemMessage) {
             const data = await post("/api/notify/list", {
                 toUser: userSelf.id,
                 type: "system",
                 updatedAt: { $gt: pageReadyTime },
             });
             const userList = data.list.filter((item) => item.objectType === "User");
             userList.length&&events.emit(EVENTS.USER_INFO_CHANGE, { list: userList });
         }
    }

    async readNotify(firstNotify){
        const {type}=firstNotify
        let query={
            _id:firstNotify._id,
            objectType:firstNotify.objectType,
            action:firstNotify.action,
            type
        }
        // 系统消息全部直接已读
        if(type==='system'){
            query={
                _id:firstNotify._id,
                type:'system'
            }
        }
        await post('/api/notify/read',query)
        setTimeout(async ()=>{
            await this.getCount()
        },1000)
    }

    async follow(userId){
        await post('/api/follow/create',{followee:userId})
        Message.success('关注成功')
    }
    async unfollow(userId){
        return await post('/api/follow/remove',{followee:userId})
    }
}
const notify=new Notify()
setInterval(async ()=>{
    userSelf.avatar&&await notify.getCount()
},60*1000)
export default notify

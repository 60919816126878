import styles from "./index.module.less";
import { Content } from "../../components";

export default function () {
  return (
    <Content className={styles.about}>
        我们团队是一群热爱真理，追求行业信息同步效率的年轻人。
        <br />
      <br />
        您可以在这里向业内同伴们推荐最新研究成果、期刊内容、学术&行业动态、科普文章，经验&动态交流分享！同时，也可以看到别人的精彩推荐及分享！
        <br />
        这些分享可能是来自于你阅读学术研究成果、优秀文章、业内动态所带来的疑问、思考或启发，它们可能结合了学术效率，深度及广度。大家可以通过我们的网站更便捷地把具有启发性的最新内容&动态推荐分享给需要的业内朋友们。
        <br />
      <br />
        欢迎大家积极分享自己的热爱。
    </Content>
  );
}

import {List, Radio, Spin} from "@arco-design/web-react";
import styles from "./index.module.less";
import { Article } from "../index";
import { userSelf, category, post, homeList,homeList2 } from "../../store";
import useTouchBottom from "../../hooks/useTouchBottom";
import { useEffect, useState } from "react";
import { List as ListStore } from "../../store/List";
import { observer } from "mobx-react-lite";

export default observer(function ({
  showCategory,
  path = "/api/post/list",
  query = {},
  tab,
  method = "post",
    cursorKey="createdAt"
}) {
  const [store] = useState(new ListStore(path,{
      method,
      cursorKey
  }));
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const loadmore = async () => {
    if (tab && post.activeTab !== tab) return;
    if ("user" in query && !query.user) return;
    setList(await store.loadmore(query));
  };
  useEffect(() => {
    loadmore().then(() => {
      setLoading(false);
    });
  }, [query.user]);

  const handleDeleteClick = (item) => {
    setList((list) => list.filter((o) => o.id !== item.id));
  };

  useTouchBottom(loadmore);
  return (
    <List
      bordered={false}
      loading={loading}
      footer={store.loading && list.length > 0 && <Spin />}
      className={`${styles.postList} ${!tab && styles.paddingTop}`}
    >
      {list.map((item, index) => {
        return (
          <List.Item key={index}>
            <Article
              showCategory={showCategory}
              item={item}
              onDelete={handleDeleteClick.bind(this, item)}
              self={item?.user?._id === userSelf.id}
              showDelete={tab === "mypost"}
            />
          </List.Item>
        );
      })}
    </List>
  );
});

export const HomeList = observer(() => {
    // type 1 文章，2 动态
    const [postType,setPostType]=useState(1)
  return (
      <div className={`${styles.postList} ${styles.paddingTop}`}>
        <div className={styles.radioGroup}>
            <Radio.Group
                type='button'
                name='lang'
                value={postType}
                onChange={setPostType}
            >
                <Radio value={1}>推文</Radio>
                <Radio value={2}>动态</Radio>
            </Radio.Group>
        </div>
          <List bordered={false} style={{display:postType===1?undefined:'none'}}>
              {homeList.list.map((item, index) => {
                  return (
                      <List.Item key={index}>
                          <Article
                              showCategory
                              item={item}
                              self={item.user?.id === userSelf.id}
                          />
                      </List.Item>
                  );
              })}
          </List>
          <List bordered={false} style={{display:postType===2?undefined:'none'}}>
              {homeList2.list.map((item, index) => {
                  return (
                      <List.Item key={index}>
                          <Article
                              showCategory
                              item={item}
                              self={item.user?.id === userSelf.id}
                          />
                      </List.Item>
                  );
              })}
          </List>
      </div>

  );
});

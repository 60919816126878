export const list=[
    {
        title:'植物工厂——以工业思维带动传统农业突围',
        description:'事实上，这些生菜并非传统种植，而是产自当地首家“植物工厂”。这种生产方式让原本土地里生长的蔬菜成了流水线上产品，既以工业思维带动传统农业数字化突围 ...',
    },
    {
        title:'新型纳米材料生物复合膜用于鲜果活性包装应用',
        description:'介绍了纳米材料在食品包装中的应用,指出了与传统的食品包装材料相比,纳米材料在保鲜效果、抗菌 ... 研究主要侧重于可食性膜[11] 、生物可降解用膜[12] 等...',
    },
    {
        title:'影响番茄果实品质的外部调节手段',
        description:'番茄属茄科番茄属植物，番茄果实营养丰富，具有独特的风味，既可作为 ... 者对番茄的品质要求也大大提高， 寿光地区出现了越来越多的口感型番茄...',
    },
    {
        title:'园艺植物花青素合成相关调控因子',
        description:'该文对植物花青素的合成途径、相关酶和各种调控因子进行了综述，并概述了植物花青素合成. 代谢中基因突变与花色变异的关系，旨在为今后深入研究花青素的分子调控机制...',
    },
    {
        title:'提高切开郁金香花采后质量的基本原理',
        description:'适用于工厂化穴盘育苗的种子，其质量好坏应从以下几个方面来衡量：种子的外形是否适于 ... 对于提高和保持种子活力的处理，应在种子形成发育成熟过程、采前采后、贮藏 ...',
    },
    {
        title:'森林土壤中微生物的多样性及其影响因素',
        description:'土壤微生物是土壤生态系统的重要组成部分, 在土壤有机物质分解和养分释放、能量转移等生物地化循环. 中起着重要作用。随着人们对生物多样性...'
    },
    {
        title:'影响水稻的丛枝菌根、粒长及磷素转运的某些基因',
        description:'AM不仅直接调节宿主植物对低磷胁迫的响应, 还可能通过分泌物影响相邻的非菌根植物。该研究采用分室培养系统, 以玉米(Zea mays)和AM真菌...'
    },
    {
        title:'玉米育种行业创新现状',
        description:'玉米作为常见的农作物,在我国甚至全世界都分布极广,产量、用途极为广泛,在畜牧、化工、食品等多种行业中都有着重要的作用,并且,玉米的高产量可以保证我国粮食 ...'
    },
    {
        title:'保护性耕作的实践研究',
        description:'保护性耕作是对农田实行免耕、少耕，并用作物秸秆、残茬覆盖地表，减少土壤风蚀、水蚀，提高土壤肥力和抗旱能力的一项先进耕作技术。目前主要应用于干旱、半干旱地区农作物 ...'
    },
    {
        title:'中国农业企业经营管理',
        description:'当前，中国证券监督管理委员会（以下简称“证监会”）持续推进资本市场改革开放深化，畜牧农业企业股权融资的制度环境不断优化。证监会2021年2月明确表示将 ...'
    }

]

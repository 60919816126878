import styles from "./index.module.less";
import {Content, InfiniteList, ChatMessage, FeedBack,Avatar} from "../../components";
import { conversation } from "../../store/List";
import userSelf  from "../../store/userSelf";
import MessageList  from "./MessageList";
import { Comment,  } from '@arco-design/web-react';
import useInfiniteScroll from 'react-easy-infinite-scroll-hook';
import {formatDate, simpleDate} from '../../utils'
import {getSocket} from '../../utils/socket'
import {useEffect, useState,useRef} from 'react'
import { useLocation, useNavigate } from "react-router-dom";
import { Input, Space ,Button,Drawer,Switch,Popconfirm,Message,Badge} from '@arco-design/web-react';
const TextArea = Input.TextArea;
import {getQuery} from "../../utils";
import { IconMore, IconExclamationCircle } from "@arco-design/web-react/icon";
import { observer } from "mobx-react-lite";
import { EVENTS, events } from "../../utils/events";
import { post } from "../../utils/request";
import setting from "../../store/setting";
import {conversation as conversationStore} from "../../store/List";


import ChatStore from "../../store/chat";
export default observer(function () {
    const location = useLocation();
    const { fromUserId, toUserId } = location.state||{};
    const [defaultToUserId,setDefaultToUserId] = useState(getQuery()?.to);
    const [chatStore,setChatStore] = useState(null);
    const [isScrollToBottom,setIsScrollToBottom] = useState(false);
    const inputRef = useRef(null);
    const [visible, setVisible] = useState(false);
    const refWrapper = useRef(null);
    const [isBlocked, setIsBlocked] = useState(false);

    const handleSendClick = () => {
        if (!userSelf.isLogin) {
            return
        }
        // if (!network.isOnline()) {
        //     return Toast.warn("当前网络不可用，请稍后操作");
        // }

        if (chatStore.content === "") return;
        chatStore.sendMessage(chatStore.content);
        chatStore.content=""
    };

    const handleTextChange=(text)=>{
        chatStore.content=text
    }
    const handleClick = async (conversation, friend) => {
        const store=await ChatStore.getChatInstance(userSelf.id,friend._id,conversation._id)
        setChatStore(store);
        setIsBlocked(setting.blackList.includes(friend._id))
    };

    const handleClickMore= async () => {
        chatStore.conversation.pinned=conversationStore.getItemById(chatStore.conversation._id).pinned
        setVisible(true)
        await setting.fetchSelfSetting(); // 获取最新配置
        setIsBlocked(setting.blackList.includes(chatStore.toUserId))
    }

    const handlePinnedChange = (value) => {
        events.emit(EVENTS.CONVERSATION_PIN, { _id:chatStore.conversationId, pinned: value });
        chatStore.conversation.pinned=conversationStore.getItemById(chatStore.conversation._id).pinned
    };

    const handleBlockedChange = (value) => {
        setIsBlocked(value);
        setting.updateBlackList(chatStore.toUserId, value ? "add" : "remove");
    };

    const handleDeleteConversation =async () => {
        // const result = await post("/api/conversation/delete", { _id: chatStore.conversation._id });
        // if (result) {
        //     events.emit(EVENTS.CONVERSATION_DELETE, {
        //         _id: chatStore.conversation._id,
        //         toUser:chatStore.toUser,
        //         fromUser:chatStore.fromUser,
        //     });
        //     Message.success("删除成功");
        //     setVisible(false)
        //     setChatStore(null)
        // }

        const socket= getSocket();
        socket.emit("user:conversation:delete", { _id: chatStore.conversation._id },(response)=>{
            const {code,data:conversation}=response;
            if(code!==0) return;
            events.emit(EVENTS.CONVERSATION_DELETE, conversation);
            Message.success("删除成功");
            setVisible(false)
            setChatStore(null)
        });
    }

    useEffect(()=>{
        const {from,to}=getQuery();
        if(userSelf.id&&to){
            ChatStore.getChatInstance(userSelf.id,to).then((store)=>{
                setChatStore(store);
            })
        }
    },[userSelf.id])

  return (
    <Content className={styles.container}>
        <div className={styles.left}>
            <div className={styles.header}>
                私信列表
            </div>
            <InfiniteList className={styles.list} store={conversation} render={(item,index)=>{
                const { lastMessage, lastMessageTime,participants } = item;
                const [toUser, fromUser] = participants;
                const isSendToMe = toUser._id === userSelf._id;
                let friend = isSendToMe ? fromUser : toUser;
                return (
                    <div key={item._id} onClick={handleClick.bind(null, item, friend)}>
                        <Comment
                            align='right'
                            author={friend.name}
                            className={`${styles.conversation} ${item.pinned?styles.pin:''} ${item._id=== chatStore?.conversationId ? styles.selected : ''}`}
                            avatar={
                                <Badge count={item.unreadCount}>
                                    <Avatar src={friend.avatar} user={friend}/>
                                </Badge>
                            }
                            content={
                                <div className={styles.lastMessage}>
                                    {item.lastMessage?.content}
                                </div>
                            }
                            datetime={simpleDate(lastMessageTime)}
                        />
                    </div>
                )
            }}/>
        </div>
        <div className={styles.right} ref={refWrapper}>
            {
                chatStore?(
                    <div className={styles.chatWindow}>
                        <div className={styles.chatHeader}>
                            <div></div>
                            <div>{chatStore.toUser?.name}</div>
                            <div className={styles.more} onClick={handleClickMore}>
                                <IconMore />
                            </div>
                        </div>
                        <div className={styles.messages}>
                            <MessageList
                                key={chatStore.conversationId}
                                direction={'up'}
                                store={chatStore}
                                render={(message,index)=>{
                                    return (
                                        <ChatMessage
                                            onRead={chatStore.debuncedReadMessage}
                                            key={`${message?._id}${index}${message.isSelf}`}
                                            message={message}
                                            lastMessage={chatStore.messages[index - 1]}
                                        />
                                    )
                                }}/>
                        </div>
                        <div className={styles.footer}>
                            <TextArea
                                autoSize={false}
                                onChange={handleTextChange}
                                onPressEnter={handleSendClick}
                                value={chatStore.content}
                                className={styles.textarea}
                                disabled={chatStore.disableSend}
                                placeholder='输入聊天...'/>
                            <div className={styles.btnLine}>
                                <Space>
                                    <span className={styles.tips}>按enter 键发送</span>
                                    <Button
                                        disabled={chatStore.disableSend}
                                        className={styles.btn} type='primary' onClick={handleSendClick}>发送</Button>
                                </Space>
                            </div>
                        </div>
                    </div>
                ):(
                    <div className={styles.empty}/>
                )
            }
            <Drawer
                title='聊天设置'
                visible={visible}
                className={styles.drawer}
                width={400}
                getPopupContainer={() => refWrapper && refWrapper.current}
                footer={null}
                onOk={() => {
                    setVisible(false);
                }}
                onCancel={() => {
                    setVisible(false);
                }}
            >
                <div className={styles.line}>
                    <div className={styles.label}>置顶聊天</div>
                    <Switch checked={chatStore?.conversation?.pinned} onChange={handlePinnedChange}/>
                </div>
                <div className={styles.line}>
                    <div className={styles.label}>加入黑名单</div>
                    <Switch checked={isBlocked} onChange={handleBlockedChange}/>
                </div>
                <div className={styles.line}>
                    <div className={styles.label}>举报用户</div>
                    <FeedBack id={chatStore?.toUserId} objectType={'User'}/>
                </div>
                <div className={styles.line} style={{border:'none'}}>
                    <Popconfirm
                        title='确认删除聊天记录吗'
                        content='删除后不可恢复！'
                        onOk={handleDeleteConversation}
                    >
                        <Button type='outline' status='danger' style={{width:'100%'}}>删除聊天记录</Button>
                    </Popconfirm>
                </div>
            </Drawer>
        </div>

    </Content>
  );
})

import {Select, Grid, Button, Input, Form, Message, Radio, Spin, Empty} from '@arco-design/web-react';
import styles from "./index.module.less"
import {Content,ImageUploader} from "../../components"
import idea from "../../assets/images/idea.png"
import {Link, useNavigate} from "react-router-dom";
import {useRef,useState} from "react";
import {userSelf,category,post} from "../../store";
import {extractFirstUrl} from "../../utils";
import { observer } from "mobx-react-lite"
import {IconLink, IconSubscribeAdd} from "@arco-design/web-react/icon";

const Row = Grid.Row;
const Col = Grid.Col;
const FormItem = Form.Item;
const Option = Select.Option;
const RadioGroup = Radio.Group;

export default observer(function (){
    const [isSubmitting,setIsSubmitting]=useState(false)
    const [loading, setLoading] = useState(false);

    // type 1 文章，2 动态
    const [postType,setPostType]=useState(1)
    const [ogInfo,setOgInfo]=useState(null)
    const [form] = Form.useForm();
    const navigate=useNavigate()
    const uploaderRef = useRef()

    const handleSubmit=async (formData)=>{
        if(postType===2){
            if (!formData?.images?.length && !formData?.content) {
                return Message.info('还没有内容或图片哦～');
            }
            const uploaderStatus=uploaderRef.current.getStatus()
            if(uploaderStatus==='uploading'){
                return Message.info('请等待图片上传完成！')
            }
            if(uploaderStatus==='error'){
                return Message.info('有图片上传失败，请更换或删除！')
            }
        }

        let curOgInfo=ogInfo
        if(!curOgInfo) {
            curOgInfo=await post.getOgInfo(formData.source)
        }

        if(isSubmitting) return
        setIsSubmitting(true)
        const result=await post.create({...formData,postType,cover:curOgInfo?.ogImage||''})
        if(!result){
            setIsSubmitting(false)
            return // Message.error('发布失败！')
        }
        if(result){
            Message.success('发布成功。')
        }
        setTimeout(()=>{
            navigate(`/posts?tab=all`)
            setIsSubmitting(false)
        },1000)

    }
    const handleSubmitFailed=(errors)=>{
        console.log(errors)
    }
    const handleSourceChange=async (e)=>{
        const url=e.target.value
        if(!url) return
        if(!url.startsWith('http')) return
        await post.urlDeduplicate(url)
        // if(!form.getFieldValue('title')){
        //     const data=await post.getOgInfo(url)
        //     form.setFieldsValue({title:data.ogTitle||''})
        //     setOgInfo(data)
        // }
        setLoading(true);
        try {
            const data=await post.getOgInfo(url)
            form.setFieldsValue({title:data.ogTitle||''})
            setOgInfo(data)
        }catch (e) {
            console.log(e)
        }finally {
            setLoading(false);
        }

    }

    const handlePaste=(e)=>{
        const url=extractFirstUrl(e.clipboardData.getData('text'))
        console.log(url,'handlePaste')

        if(!url) return
        setTimeout(()=>{
            form.setFieldsValue({source:url})
        },0)
    }
    return (
        <Content className={styles.creation}>
            <Row>
                <Col span={14}>
                    <FormItem label='类型' >
                        <RadioGroup
                            type='button'
                            name='lang'
                            value={postType}
                            onChange={setPostType}
                        >
                            <Radio value={1}>推文</Radio>
                            <Radio value={2}>动态</Radio>
                        </RadioGroup>
                    </FormItem>
                    <Form
                        autoComplete="off"
                        form={form}
                        onSubmit={handleSubmit}
                        onSubmitFailed={handleSubmitFailed}>
                        <FormItem label='专栏类目' field={'category'} rules={[{ required: true,  message:'请选择专栏' }]}>
                            <Select
                                notFoundContent={
                                    <Empty
                                        icon={<IconSubscribeAdd />}
                                        description={<Link to={'/category'}><Button type='primary' style={{marginBottom:30}}>去订阅专栏</Button></Link>}/>
                                }
                                placeholder='请选择已订阅专栏'>
                                {userSelf.subscription.map((option) => (
                                    <Option key={option.id} value={option.id}>
                                        {option.title}
                                    </Option>
                                ))}
                            </Select>
                        </FormItem>
                        {
                            postType===1?(
                                <>
                                    <FormItem
                                        label='原文地址'
                                        field='source'
                                        extra={'链接获取方法：在想推荐文章的页面找到右上角“...”查看“复制链接”点击复制即可，每个原文链接限制推荐一次。'}
                                        rules={[
                                            { required: true, message:'请输入原文地址' },
                                            {match:/^(http|https):\/\//i,message:'请输入正确的链接地址'}
                                        ]}
                                    >
                                        <Input placeholder='请输入原文地址' onPaste={handlePaste} onBlur={handleSourceChange}/>
                                    </FormItem>
                                    <FormItem
                                        label='原文标题'
                                        field='title'
                                        rules={[
                                            { required: true,  message:'请输入原文标题'},
                                        ]}
                                        extra={'如输入链接后自动获取标题失败，请手动输入。'}
                                    >
                                        <Input
                                            placeholder='请输入推荐文章的原文标题'
                                            className={styles.titleInput}
                                            addBefore={
                                                <Spin loading={loading}>
                                                    {
                                                        ogInfo?.ogImage?(
                                                            <img className={styles.cover} src={ogInfo?.ogImage} alt=""/>
                                                        ):(
                                                            <div className={styles.coverBox}>
                                                                <IconLink fontSize={16}/>
                                                            </div>
                                                        )
                                                    }
                                                </Spin>
                                            }/>
                                    </FormItem>
                                    <FormItem
                                        extra={'内容发布者观点及评论仅供其表达个人看法或观点，并不表明美刊立场。'}
                                        label='推荐理由' field='content'>
                                        <Input.TextArea
                                            maxLength={700}
                                            showWordLimit
                                            placeholder='一个精彩的推荐理由，有助于提升榜单排名哦～'
                                            autoSize={{ minRows:12, maxRows:20 }}/>
                                    </FormItem>
                                </>
                            ):(
                                <>
                                    <FormItem
                                        extra={'内容发布者观点及评论仅供其表达个人看法或观点，并不表明美刊立场。'}
                                        label='动态内容' field='content' >
                                        <Input.TextArea
                                            maxLength={700}
                                            showWordLimit
                                            placeholder='动态内容输入框提示：动态内容需要属于所选专栏类目范畴'
                                            autoSize={{ minRows:12, maxRows:20 }}/>
                                    </FormItem>
                                    <FormItem label='动态图片' field={'images'}>
                                        <ImageUploader ref={uploaderRef} process={'image/resize,h_1000,w_1000'}/>
                                        {/*<Upload*/}
                                        {/*    multiple*/}
                                        {/*    imagePreview*/}
                                        {/*    action='/api/file/upload?process=image/resize,w_1000,h_1000'*/}
                                        {/*    listType='picture-card'*/}
                                        {/*    accept={".png,.jpeg,.jpg"}*/}
                                        {/*/>*/}
                                    </FormItem>
                                </>
                            )
                        }
                        <FormItem
                            extra={
                                postType===1?(
                                    <span>每人每天可推文2篇，推文收益可向平台自行领取，快来推文！
                                        <a className={styles.rule} href="https://mp.weixin.qq.com/s/URpOaUHvITHi4_V5vGopqQ" rel="noreferrer" target={'_blank'}>点击查看详情</a>
                                        <a className={styles.rule} target={'_blank'} rel="noreferrer" href={'./rules'}> <IconLink /> 社区规范</a>
                                    </span>
                                    ):'未认证用户每天可发动态1条，认证用户可发2条，动态不参与榜单排名计算。'
                            }
                            label={'  '}>
                            <Button style={{width:'100%'}} type='primary' htmlType='submit' disabled={isSubmitting}>
                                发布
                            </Button>
                        </FormItem>

                    </Form>
                </Col>
                <Col span={10} >
                    {/*<img src={idea} alt=""/>*/}
                    <div className={styles.img} style={{backgroundImage:`url(${idea})`}}/>
                </Col>
            </Row>
        </Content>
    )
})

import {Layout, Menu, Grid, Button, Divider, Tabs} from '@arco-design/web-react';
import styles from "./index.module.less"
const Content = Layout.Content;
const Row = Grid.Row;
const Col = Grid.Col;

export default function ({children,className,right,...props}){
    return (
        <Content className={`${styles.content} ${className}`} {...props}>
            {right?(
                <Row gutter={24}>
                    <Col span={18} className={styles.left}>
                        {children}
                    </Col>
                    <Col span={6}>
                        {right}
                    </Col>
                </Row>
            ):children}
        </Content>
    )
}

import {
  Menu,
  Grid,
  List,
  Button,
  Card,
  Badge,
  Upload,
  Message,
  Modal,
  Form,
  Input
} from "@arco-design/web-react";
import styles from "./index.module.less";
import { UserForm, Content, WechatLogin } from "../../components";
import Cropper from "./Cropper";
import WechatBind from "./WechatBind";
import { IconMessage, IconWechat, IconStamp,IconLock,IconCloseCircle } from "@arco-design/web-react/icon";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { userSelf, notify } from "../../store";
import { observer } from "mobx-react-lite";
const MenuItem = Menu.Item;
const Row = Grid.Row;
const Col = Grid.Col;

const list = [
  {
    menu: "账号设置",
    key: "account", // as last path
    icon: <IconMessage />
  },
  {
    menu: "绑定微信",
    key: "bind",
    icon: <IconWechat />
  },
  {
    menu: "用户认证",
    key: "certify",
    icon: <IconStamp />
  },
  {
    menu: "隐私设置",
    key: "privacy",
    icon: <IconLock />
  },
  {
    menu: "账号注销",
    key: "delete",
    icon: <IconCloseCircle />
  },
];

export default observer(function () {
  // const [selectedKey,setSelectedKey]=useState('account')
  // const [cacheSet]=useState(new Set(['account']))
  const navigate = useNavigate();
  const handleMenuClick = key => {
    // setSelectedKey(key)
    // cacheSet.add(key) // 点击过的加入set
    navigate(key, { replace: true });
  };

  const handleUploadSuccess = url => {};

  const handleSubmit = formData => {
    console.log(formData);
    userSelf.updateUserInfo(formData) && Message.success("更新成功！");
  };

  return (
    <Content className={styles.message}>
      <Row gutter={24} className={styles.row}>
        <Col span={4} className={styles.col}>
          <Menu
            className={styles.sideMenu}
            defaultSelectedKeys={[location.pathname.split("/").pop()]}
            onClickMenuItem={handleMenuClick}
          >
            {list.map(o => (
              <MenuItem key={o.key}>
                {o.icon} {o.menu}
              </MenuItem>
            ))}
          </Menu>
        </Col>
        <Col span={20} className={styles.col}>
          <Outlet />
        </Col>
      </Row>
    </Content>
  );
});

import { Message } from "@arco-design/web-react";
const queryString = require("query-string");

/**
 * 服务端通过message 下发的业务错误
 */
class NotifyError extends Error {
  constructor(message, payload, options) {
    // Needs to pass both `message` and `options` to install the "cause" property.
    super(message, options);
    this.payload = payload;
  }
}
export async function request(url, options) {
  if (!navigator.onLine) {
    return Promise.reject(
      new NotifyError("当前网络不可用，请检查网络连接状态。", { url, options })
    );
  }

  console.log("request", url);
  return fetch(url, options)
    .then(response => {
      if (response.status === 401) {
        setTimeout(() => {
          window.location.replace("/login");
        }, 1000);
        throw new NotifyError("请登录后操作。", { url, options });
      }
      return response.json();
    })
    .then(response => {
      const { code, message, data } = response;
      if (code === 0) {
        return data ?? true;
      }
      if (code === 412) {
        setTimeout(() => {
          if (window.location.pathname === "/bind") return;
          window.location.replace(`/bind?uid=${message}`);
        }, 1000);
        throw new NotifyError("请先绑定微信账号。", { url, options });
      }
      throw new NotifyError(message, { url, options });
    })
    .catch(e => {
      // 走到这也还返回promise 值，为undefined；所以如果要处理失败的情况，还是需要判空；
      // FIXME
      if (e instanceof NotifyError) {
        Message.warning(e.message);
      } else {
        console.error(e);
        // Message.warning("网络错误，请联系反馈邮箱。");
      }
    });
}

export async function get(url, data = {}) {
  let queryStr = queryString.stringify(data);
  if (url.includes("?")) {
    queryStr = "&" + queryStr;
  } else {
    queryStr = "?" + queryStr;
  }
  return request(url + queryStr);
}

export async function post(url, data) {
  return request(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  });
}

export async function upload(url, formData) {
  return request(url, {
    method: "POST",
    body: formData
  });
}

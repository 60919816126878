import { Divider, Grid,Typography,Dropdown ,Button} from '@arco-design/web-react';
import styles from "./index.module.less"
import { Content } from '../index';
import {useState} from "react";
import { Link } from "react-router-dom";

const Row = Grid.Row;
const Col = Grid.Col;
const { Text } = Typography;


export default function ({children}){
    const [visible, setVisible] = useState(false);
    const onCancel=()=>setVisible(false)
    return (
        <Content className={styles.register}>
            <div className={styles.container}>
                <div className={styles.left}>
                </div>
                <div className={styles.right}>
                    {children}
                </div>
            </div>
        </Content>
    )
}

import {List, Empty, Spin} from '@arco-design/web-react';
import styles from './index.module.less'
import {Comment} from '../index'
import {userSelf,category} from "../../store";
import {post} from "../../utils/request";
import useTouchBottom from "../../hooks/useTouchBottom";
import {useEffect, useState} from "react";
import { observer } from "mobx-react-lite"
import {IconMessage} from '@arco-design/web-react/icon';


export default observer(function ({query={},store}){
    const [loading,setLoading]=useState(true)
    useEffect( ()=>{
        refresh()
            .then(()=>{
                setLoading(false)
            })
    },[])

    const refresh=()=>{
        return store.loadmore(query,true)
    }

    useTouchBottom(store.loadmore.bind(null,query))
    return (
        <List
            bordered={false}
            loading={loading}
            noDataElement={<Empty description={'快来发表评论吧'} icon={<IconMessage />}/>}
            footer={store.loading&&store.list.length>0&&<Spin/>}
            className={styles.postList}>
            {store.list.map((item, index) => {
                return (
                    <List.Item key={item._id}>
                        <Comment
                            refresh={refresh}
                            store={store}
                            item={item}
                            self={item.user?._id===userSelf.id}
                        />
                    </List.Item>
                );
            })}
        </List>
    )
})

import {Layout, Card, Grid, Tabs, Button, Divider, Empty} from '@arco-design/web-react';
import styles from "./index.module.less"
import {useEffect, useState} from 'react'
import {getQuery} from '../../utils'
import {Link, useLocation} from "react-router-dom";
import { IconPlus,IconSubscribeAdd } from '@arco-design/web-react/icon';
import {SubscribeCard, PostList,Leaderboard,Breadcrumb,CompanyInfo,Content,QrCard} from "../../components";
import {userSelf,category,post} from "../../store";
import { observer } from "mobx-react-lite"
const Row = Grid.Row;
const Col = Grid.Col;
const TabPane = Tabs.TabPane;

export default observer(function (){
    const location = useLocation();
    const query=getQuery()
    const {category:categoryId,tab}=query
    // useEffect(() => {
    //     post.activeTab=categoryId
    // }, [location]);

    useEffect(() => {
        post.activeTab=tab||(userSelf.isLogin?'subscription':'all')
    }, []);

    const isSubPage=!!query.title
    const routes=[{
        path:'/',
        name:'首页'
    },{
        name:query.title
    }]

    const handleTabChange=(key)=>{
        post.activeTab=key
    }

    function getList(){
        if(userSelf.id&&userSelf.subscription.length===0){
            return(
                <TabPane key='subscription' title='订阅专栏'>
                    <Empty
                        icon={<IconSubscribeAdd />}
                        description={<Link to={'/category'}><Button type='primary' style={{marginBottom:30}}>订阅专题</Button></Link>}/>
                </TabPane>
            )
        }else if(userSelf.id){
            return (
                <TabPane key='subscription' title='订阅专栏'>
                    <PostList
                        tab={'subscription'}
                        showCategory
                        method={'post'}
                        path='/api/post/feed'
                        cursorKey={'publishedAt'}
                        query={{category:{$in:userSelf.subscription.map(o=>o._id)}}} />
                </TabPane>
            )

        }else{
            return null
        }
    }
    return (
        <Content className={styles.postsPage}>
            {
                isSubPage?(
                    <Row gutter={24}>
                        <Col span={24}>
                            <Breadcrumb routes={routes}/>
                        </Col>
                        <Col span={18} className={styles.posts}>
                            <PostList
                                query={{category:categoryId}}
                                cursorKey={'publishedAt'}
                                path='/api/post/feed'/>
                        </Col>
                        <Col span={6}>
                            <SubscribeCard/>
                            <Leaderboard/>
                            <QrCard/>
                        </Col>
                    </Row>
                ):(
                    <Row gutter={24}>
                        <Col span={18} className={styles.posts}>
                            <Tabs
                                activeTab={post.activeTab}
                                onChange={handleTabChange}
                                extra={
                                    <Link to={'/category'} title={'添加类目'}>
                                        <Button
                                            size='small'
                                            type='secondary'
                                            icon={<IconPlus />}
                                        />
                                    </Link>
                                }
                                size={'large'}>

                                <TabPane key='all' title='全部'>
                                    <PostList tab={'all'} showCategory path='/api/post/feed' cursorKey={'publishedAt'}/>
                                </TabPane>

                                {
                                    getList()
                                }

                                {
                                    userSelf.id&&(
                                        <TabPane key='followee' title='关注作者'>
                                            <PostList tab={'followee'} showCategory path={'/api/post/list/followee'} cursorKey={'publishedAt'}/>
                                        </TabPane>
                                    )
                                }
                                {
                                    userSelf.subscription?.map((category)=>(
                                        <TabPane key={category.id} title={category.title}>
                                            <PostList tab={category.id} category={category} query={{category:category.id}} path='/api/post/feed' cursorKey={'publishedAt'}/>
                                        </TabPane>
                                    ))
                                }
                            </Tabs>
                        </Col>
                        <Col span={6}>
                            <QrCard/>
                            <CompanyInfo/>
                        </Col>
                    </Row>
                )
            }

        </Content>
    )
})

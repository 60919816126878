import { Input, Space } from '@arco-design/web-react';
const InputSearch = Input.Search;
import styles from "./index.module.less"
import searchStore from "../../store/search"
import { observer } from "mobx-react-lite";
import { Link, useNavigate } from "react-router-dom";
import { events, EVENTS } from "../../utils/events";


export default observer(function ({children,className,right,...props}){
    const navigate = useNavigate();

    const handleChange=(value)=>{
        searchStore.keyword=value
    }
    const handlePressEnter=(value)=>{
        console.log(searchStore.queryStr)
        navigate(`/search?${searchStore.queryStr}`);
        events.emit(EVENTS.SEARCH)
        // searchStore.postSearch()
    }
    return (
        <InputSearch
            searchButton
            allowClear
            onChange={handleChange}
            onPressEnter={handlePressEnter}
            defaultValue=''
            value={searchStore.keyword}
            placeholder='搜索关键词/用户'
            // size={'large'}
            style={{ "minWidth":150,width:'16vw',"maxWidth":300,marginRight:10 }}
        />
    )
})

import { Menu, Message, Button, Dropdown, Input, Upload, Grid } from "@arco-design/web-react";
import { IconMore, IconExclamationCircle } from "@arco-design/web-react/icon";
import { useRef, useState } from "react";
import Modal from "../Modal";
import { reasons, ObjectTypeStatus } from "./constant";
import styles from "./index.module.less";
import { Radio } from "@arco-design/web-react";
import { post } from "../../utils/request";
const Row = Grid.Row;
const Col = Grid.Col;
const RadioGroup = Radio.Group;
const TextArea = Input.TextArea;
export default function ({ id, className, objectType = "Post" }) {
  const [visible, setVisible] = useState(false);
  const [haveUpload, setHaveUpload] = useState(false);
  const [type, setType] = useState("ad");
  const [otherReason, setOtherReason] = useState(null);
  const [images, setImages] = useState([]);
  const isUser = objectType === "User" ? 1 : 0;
  const handleClick = async () => {
    if (isUser && !otherReason) return Message.warning("请输入补充说明");
    let reason =
      type === "custom" ? otherReason : reasons[objectType].flat().find(o => o.type === type)?.reason;
    if (!reason) {
      Message.warning("请选择反馈原因");
      return;
    }
    const props = {
      objectType,
      object: id,
      type,
      reason
    };
    if (isUser) {
      props.detail = otherReason;
      props.images = images.map(item => item.url);
    }
    await post("/api/feedback/create", props).then(res => {
      if (res.status === 0) {
        setOtherReason("");
        setType("ad");
        setImages([]);
        setVisible(false);
        Message.success("提交成功，感谢你的反馈。");
      } else {
        Message.error("反馈失败，请联系反馈邮箱。");
      }
    });
  };
  return (
    <>
      <Dropdown
        className={`${className} ${styles.feedback}`}
        droplist={
          <Menu>
            <Menu.Item
              key="feedback"
              onClick={() => {
                setVisible(true);
              }}
            >
              <IconExclamationCircle style={{ marginRight: "5px" }} />
              反馈
            </Menu.Item>
          </Menu>
        }
      >
        <Button type="text" className={styles.btn}>
          <IconMore />
        </Button>
      </Dropdown>
      <Modal
        title={`${ObjectTypeStatus[objectType]}反馈`}
        visible={visible}
        onCancel={() => {
          setVisible(false);
        }}
        onOk={handleClick}
        unmountOnExit
      >
        <div className={styles.feedback_modal}>
          <div className={styles.title}>
            <span className={styles.title_tip}>*</span>请选择一项反馈原因：
          </div>
          <RadioGroup
            size="large"
            value={type}
            style={{ width: "100%" }}
            className={styles.feedback_radios}
            onChange={value => {
              setType(value);
            }}
          >
            {reasons[objectType].map((item, index) => {
              return (
                <Row key={index}>
                  {item.map(item => {
                    return (
                      <Col key={item.type} span={8}>
                        <Radio value={item.type}>{item.reason}</Radio>
                      </Col>
                    );
                  })}
                </Row>
              );
            })}
          </RadioGroup>
          {isUser ? (
            <div className={styles.title}>
              <span className={styles.title_tip}>*</span>请输入补充说明：
            </div>
          ) : null}
          {isUser || type === "custom" ? (
            <TextArea
              statisticsmaxlength={200}
              className={styles.textarea}
              rows={3}
              maxLength={200}
              placeholder={isUser ? "请输入补充信息" : "请输入反馈原因"}
              border="none"
              onInput={e => setOtherReason(e.target.value)}
              value={otherReason}
            />
          ) : null}
          {isUser ? (
            <div className={styles.feedback_upload}>
              <div className={styles.title}>请上传证明图片：</div>
              <Upload
                multiple
                limit={3}
                imagePreview
                action="/api/file/upload/?compress=0"
                listType="picture-card"
                onChange={(_, currentFile) => {
                  const { status, response } = currentFile;
                  switch (status) {
                    case "init":
                    case "uploading":
                      if (currentFile.originFile.size > 1024 * 1024 * 10) {
                        Message.error("图片大小不能大于10M");
                        break;
                      }
                      break;
                    case "error":
                      Message.error(response.message || "上传失败，请重试!");
                      break;
                    case "done":
                      setImages([
                        ...images,
                        {
                          url: currentFile.response.data
                        }
                      ]);
                      break;
                  }
                }}
              />
            </div>
          ) : null}
        </div>
      </Modal>
    </>
  );
}

import {
  Form,
  Input,
  Grid,
  Message,
  Modal,
  Tabs,
  Button,
  Alert,
  Checkbox
} from "@arco-design/web-react";
import styles from "./index.module.less";
import { WechatLogin, LoginPanel, UserForm, Safe } from "../../components";
import { userSelf } from "../../store";
import { getQuery, isWechat } from "../../utils";
import getTicket from "../../utils/security";
import wechat from "../../assets/images/wechat.png";
import { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { PROTOCOL } from "../../utils/constants";

const TabPane = Tabs.TabPane;

const Row = Grid.Row;
const Col = Grid.Col;
const FormItem = Form.Item;

export default function () {
  const [countDown, setCountDown] = useState(0);
  const [agree, setAgree] = useState(false);
  const [userFormShow, setUserFormShow] = useState(false);
  const [form] = Form.useForm();
  const [smsForm] = Form.useForm();
  const navigate = useNavigate();
  const timer = useRef(0);
  const query = getQuery();

  useEffect(() => {
    if (countDown === 60) timer.current = setInterval(() => setCountDown(time => --time), 1000);
    else if (countDown === 0) {
      clearInterval(timer.current);
      timer.current = 0;
    }
  }, [countDown]);

  const handleSendClick = async () => {
    const phoneNumber = smsForm.getFieldValue("phoneNumber");
    if (phoneNumber?.length!==11) return Message.warning("请输入正确的手机号");
    if (timer.current) return;
    await userSelf.sendCheckCode(phoneNumber);
    setCountDown(60);
  };
  const handleSubmit = async formData => {
    const result = await userSelf.login(formData);
    if (result) {
      Message.success("登录成功！");
      navigate(`/posts`);
    }
  };

  const handleSmsSubmit = async formData => {
    const { ticket, randstr } = await getTicket();
    const user = await userSelf.login({ ...formData, ticket, randstr });
    if (user.isNew) {
      // 新用户登录
      Message.success("注册成功！");
      setUserFormShow(true);
      // navigate(`/`)
    } else if (user) {
      // 老用户登录
      Message.success("登录成功！");
      navigate(`/posts`);
    } else {
      console.error("登录出错");
    }
  };

  const handleUserFormSuccess = async () => {
    navigate(`/bind`);
  };

  const handleSubmitFailed = errors => {
    console.log(errors);
  };
  const handleAgreeClick = () => {
    setAgree(!agree);
  };

  const handleTabChange = key => {
    // if(key==='wechat'){
    //     generateLoginQR()
    // }
  };

  function getWechatUrl(state = {}) {
    return `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxd00f604cee46a909&redirect_uri=${encodeURIComponent(
      "https://imeikan.cn/api/mp/oauth/callback"
    )}&response_type=code&scope=snsapi_userinfo&state=${JSON.stringify(state)}#wechat_redirect`;
  }

  useEffect(() => {
    try {
      const data = JSON.parse(window.name);
      if (data.phoneNumber && data.password) {
        form.setFieldsValue(data);
      }
    } catch (e) {
      console.log();
    }
  });

  return (
    <LoginPanel>
      {userFormShow ? (
        <div>
          {/*<div className={styles.title}>绑定微信</div>*/}
          <Alert
            className={styles.alert}
            style={{ marginBottom: 20 }}
            type="success"
            title={"注册成功"}
            content='首次登录，请输入昵称并上传用户头像。以后可以在"个人设置"页进行更改。'
          />
          <UserForm onSuccess={handleUserFormSuccess} />
        </div>
      ) : (
        <Tabs defaultActiveTab={query.tab ?? "sms"} onChange={handleTabChange} lazyload={false}>
          <TabPane key="sms" title="短信登录/注册">
            <Form
              autoComplete="off"
              form={smsForm}
              // initialValues={{
              //     phoneNumber:'18500595626',
              //     checkCode:'456351',
              // }}
              onSubmit={handleSmsSubmit}
              onSubmitFailed={handleSubmitFailed}
            >
              <FormItem
                label="手机号码"
                field={"phoneNumber"}
                rules={[
                  // { required: true, message: "请输入正确的电话号码" },
                  // { match: /^[0-9]{11}$/, message: "请输入正确的电话号码" }
                ]}
              >
                <Input placeholder="请输入手机号..." />
              </FormItem>
              <FormItem label="验证码" style={{ marginBottom: 0 }}>
                <Grid.Row gutter={8}>
                  <Grid.Col span={14}>
                    <Form.Item
                      field="checkCode"
                      rules={[{ required: true, message: "请输入正确的验证码" }]}
                    >
                      <Input placeholder="请输入验证码..." />
                    </Form.Item>
                  </Grid.Col>
                  <Grid.Col span={10}>
                    <Form.Item>
                      <Button
                        type={"primary"}
                        style={{ width: "100%" }}
                        onClick={handleSendClick}
                        disabled={countDown > 0}
                      >
                        {countDown <= 0 ? "发送验证码" : countDown + "s后重发"}
                      </Button>
                    </Form.Item>
                  </Grid.Col>
                </Grid.Row>
              </FormItem>

              <FormItem label={"  "}>
                <Button style={{ width: "100%" }} type="primary" htmlType="submit">
                  登录/注册
                </Button>
              </FormItem>
              <FormItem
                requiredSymbol={false}
                label=" "
                field="agree"
                rules={[
                  { required: true, message: "请先阅读和同意用户协议" },
                  { match: /true/, message: "请先阅读和同意用户协议" }
                ]}
              >
                未注册手机验证后自动登录，注册即代表同意
                <a target={"_blank"} href={PROTOCOL.REGISTRATION_AGREEMENT.url} rel="noreferrer">
                  《{PROTOCOL.REGISTRATION_AGREEMENT.title}》
                </a>
                和
                <a target={"_blank"} href={PROTOCOL.PRIVACY_AGREEMENT.url} rel="noreferrer">
                  《{PROTOCOL.PRIVACY_AGREEMENT.title}》
                </a>
              </FormItem>
            </Form>
          </TabPane>
          {process.env.REACT_APP_ENV === "development" || query.pass ? (
            <TabPane key="pass" title="密码登录">
              <Form
                autoComplete="off"
                form={form}
                onSubmit={handleSubmit}
                onSubmitFailed={handleSubmitFailed}
              >
                <FormItem
                  label="手机号码"
                  field={"phoneNumber"}
                  rules={process.env.REACT_APP_ENV === "development" ?[]:[
                    { required: true },
                    { match: /^[0-9]{11}$/, message: "请输入正确的电话号码" }
                  ]}
                >
                  <Input placeholder="请输入手机号..." />
                </FormItem>
                <FormItem label="密码" field="password" rules={[{ required: true }]}>
                  <Input type={"password"} placeholder="请输入密码..." />
                </FormItem>
                <FormItem label={"  "}>
                  <Button style={{ width: "100%" }} type="primary" htmlType="submit">
                    登录
                  </Button>
                </FormItem>
              </Form>
            </TabPane>
          ) : null}
          <TabPane
            key="wechat"
            title={
              <span>
                <img className={styles.wechatLogo} src={wechat} alt="" />
                微信登录
              </span>
            }
          >
            {isWechat() ? (
              <Button className={styles.wexinBtn} type={"primary"} href={getWechatUrl()}>
                微信一键登录
              </Button>
            ) : (
              <WechatLogin state={{type:'login',redirect:'/'}} />
            )}

            {query.message && (
              <Alert
                style={{ marginTop: -100 }}
                type="error"
                title={"登录异常"}
                content={query.message}
              />
            )}
          </TabPane>
        </Tabs>
      )}
      {/*<Safe/>*/}
    </LoginPanel>
  );
}

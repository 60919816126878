import {Layout, Menu, Grid, Card, Button, Message} from '@arco-design/web-react';
import {useState} from 'react'
import { Link } from "react-router-dom";
import { IconPlus, IconDelete } from '@arco-design/web-react/icon';
import {category} from "../../store"
import { observer } from "mobx-react-lite"

import styles from "./index.module.less"
const Row = Grid.Row;
const Col = Grid.Col;
const { Meta } = Card;


export default observer(function ({list=[],singleLine=false}){

    const getCount=()=>{
        const w=document.documentElement.clientWidth
        if(w>=1200)return 4
        if(w>=992)return 3
        if(w>=576)return 3
        else return 3
    }
    const [count,setCount]=useState(getCount)
    window.addEventListener('resize', ()=>{
        setCount(getCount())
    });

    const handleSubscribe=async (item,e)=>{
        e.stopPropagation()
        e.preventDefault()
        const {subscribed,id}=item
        if(await category.subscribe(id,subscribed?'unsubscribe':'subscribe')){
            Message.success(subscribed?'取消成功！':'订阅成功！')
        }
    }
    return (
        <Row gutter={30}>
            {
                list.slice(0,singleLine?count:100).map((o,i)=>(
                    <Col xl={6} lg={8} sm={8} xs={8}  key={i}>
                        <Link to={`/posts?title=${encodeURIComponent(o.title)}&category=${o.id}`}>
                            <Card
                                hoverable
                                bordered={false}
                                className={styles.card}
                                cover={
                                    <div
                                        className={styles.cover}
                                        style={{
                                            backgroundImage:`url(${o.image})`
                                        }}
                                    />
                                }
                            >
                                <Meta
                                    title={
                                    <div className={styles.info}>
                                        <span className={styles.title}>{o.title}</span>
                                        {
                                            o.subscribed?(
                                                <Button className={styles.btn} size={'small'} onClick={handleSubscribe.bind(this,o)} type={'secondary'} >已订阅</Button>
                                            ):(
                                                <Button className={styles.btn} size={'small'} onClick={handleSubscribe.bind(this,o)} type='primary' icon={<IconPlus />} >订阅</Button>
                                            )
                                        }
                                    </div>
                                    }
                                    // description={o.description}
                                />
                            </Card>
                        </Link>
                    </Col>
                ))
            }
        </Row>
    )
})

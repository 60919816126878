import React, {useEffect, useRef, useState} from 'react';
import {Modal, Button, Form, Input, Select, Message, Grid} from '@arco-design/web-react';
import { observer } from "mobx-react-lite"
import {userSelf} from "../../store"
import styles from "./index.module.less";
const FormItem = Form.Item;

export default observer(function ({onOk,smsType,buttonText,title,...props}){
    const [visible, setVisible] = React.useState(false);
    const [form] = Form.useForm();
    const timer = useRef(0);
    const [countDown, setCountDown] = useState(0);
    const [phoneNumber, setPhoneNumber] = useState("");

    useEffect(() => {
        if(!phoneNumber){
            userSelf.getPhoneNumber()
                .then(phoneNumber => setPhoneNumber(phoneNumber));
        }

        if(visible){
            form.setFieldsValue({phoneNumber})
        }
    },[visible])
    useEffect(() => {
        if (countDown === 60) timer.current = setInterval(() => setCountDown(time => --time), 1000);
        else if (countDown === 0) {
            clearInterval(timer.current);
            timer.current = 0;
        }
    }, [countDown]);
    const formItemLayout = {
        labelCol: {
            span: 4,
        },
        wrapperCol: {
            span: 20,
        },
    };
    const handleSendClick = async () => {
        console.log(smsType,12)
        if (timer.current) return;
        await userSelf.sendCheckCode(phoneNumber,smsType);
        setCountDown(60);
    };
    const handleOk = async () => {
        const result=await form.validate()
        const formData=await form.getFieldsValue()
        console.log(formData,result)
        onOk?.(formData)
        setVisible(false)
    }
  return (
      <>
          <Button onClick={() => setVisible(true)} type='primary'>
              {buttonText}
          </Button>
          <Modal
              title={title}
              alignCenter={true}
              style={{ marginTop: '-100px' }}
              visible={visible}
              okText={"提交"}
              onOk={handleOk}
              onCancel={() => setVisible(false)}
              maskClosable={false}
              {...props}
          >

              <Form
                  {...formItemLayout}
                  form={form}
                  autoComplete="off"
                  labelCol={{
                      style: { flexBasis: 90 },
                  }}
                  wrapperCol={{
                      style: { flexBasis: 'calc(100% - 90px)' },
                  }}
              >
                  <FormItem
                      label="手机号码"
                      field={"phoneNumber"}
                      rules={[
                          { required: true, message: "请输入正确的电话号码" },
                          { match: /^[0-9]{11}$/, message: "请输入正确的电话号码" }
                      ]}
                      disabled
                  >
                      <Input placeholder="请输入手机号..." />
                  </FormItem>
                  <FormItem label="验证码" style={{ marginBottom: 0 }}>
                      <Grid.Row gutter={8}>
                          <Grid.Col span={14}>
                              <Form.Item
                                  field="checkCode"
                                  rules={[{ required: true, message: "请输入正确的验证码" }]}
                              >
                                  <Input placeholder="请输入验证码..." />
                              </Form.Item>
                          </Grid.Col>
                          <Grid.Col span={10}>
                              <Form.Item>
                                  <Button
                                      type={"outline"}
                                      style={{ width: "100%" }}
                                      onClick={handleSendClick}
                                      disabled={countDown > 0}
                                  >
                                      {countDown <= 0 ? "发送验证码" : countDown + "s后重发"}
                                  </Button>
                              </Form.Item>
                          </Grid.Col>
                      </Grid.Row>
                  </FormItem>
              </Form>
          </Modal>
      </>
  );
})

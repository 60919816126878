import { Divider, Grid,Typography,Dropdown ,Button,Menu,Avatar} from '@arco-design/web-react';
import styles from "./index.module.less"
import {userSelf} from "../../store"
import userImg from "../../assets/images/userhead.png"
import {useState} from "react"
import { Link,useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite"

const Row = Grid.Row;
const Col = Grid.Col;
const { Text } = Typography;

export default observer(function (props){
    const  Comp=(
        <Avatar className={styles.avatar} {...props}>
            <img src={userSelf.avatar||userImg}/>
        </Avatar>
    )
    return userSelf.id?(
        <Link to={'/user'}>
            {Comp}
        </Link>
    ):Comp
})

import {Message, Menu, Empty, Card, Divider, Typography, Button} from '@arco-design/web-react';
import styles from "./index.module.less"
import {Author,Helper} from "../index";
import {get} from "../../utils/request";
import {getQuery} from "../../utils";
import { useState,useEffect } from "react";
import {post as store,userSelf} from "../../store";
import { observer } from "mobx-react-lite"
import {IconLink} from "@arco-design/web-react/icon";
import {useNavigate} from "react-router-dom";

export default observer(function ({text,url,post,scene,isSearchResult=false}){
    const [imgFail,setImgFail]=useState(false)
    const navigate=useNavigate()
    const handleSourceClick=async (e)=>{
        e.stopPropagation()
        if(!userSelf.isLogin){
            Message.info('请登录后查看原文。')
            setTimeout(()=>{
                navigate('/login')
            },1000)
            return
        }
        store.clickSource(post._id,scene)
        window.open(url)
    }
    const handleLoadFail=()=>{
        setImgFail(true)
    }
    return (
        <Button
            icon={post.cover&&!imgFail?<img onError={handleLoadFail} src={post.cover} className={styles.cover}/>:<IconLink className={styles.icon}/>}
            size='large'
            target={'_blank'}
            type={'secondary'}
            onClick={handleSourceClick}
            // href={''}
            className={styles.link}>
            {/*<Typography.Paragraph ellipsis={{ rows: 1}}>*/}
            {/*    {text}*/}
            {/*</Typography.Paragraph>*/}
            <div className={styles.content}>
                {isSearchResult?(
                    post.titleHighlightedList?.map((o,i)=>(
                            <span key={i} style={{color:o.highlight?'#ea4335':undefined}}>{o.text}</span>
                        ))
                ):text}
            </div>
        </Button>
    )
})

import { Form, Input, Grid,Message,Modal,Button ,Alert} from '@arco-design/web-react';
import styles from "./index.module.less"
import { WechatLogin,LoginPanel } from '../../components';
import { userSelf } from '../../store';
import { getQuery } from '../../utils';
import {useState,useRef,useEffect} from "react";
import { observer } from "mobx-react-lite"
import {Link} from "react-router-dom";


export default observer(function (){
    const query=getQuery()

    return (
        <LoginPanel>
            <div className={styles.title}>绑定微信</div>
            {
                query.message?(
                    <Alert
                        className={styles.alert}
                        type='error'
                        title={'绑定失败'}
                        content={query.message} />
                ):(
                    <Alert
                        className={styles.alert}
                        type='success'
                        title={'注册成功'}
                        content={
                            '请用微信扫码，绑定微信后可在微信中免登录阅读、发布文章。你也可跳过绑定，以后在"个人设置"页面进行微信绑定'
                        } />
                )
            }
            <WechatLogin state={{type:'bind',userId:query.uid,redirect:'/category'}}/>
            <div className={styles.jump}>
                {
                    query.code==='1001'?(
                        <Link to={'/login'}>
                            <Button type={'primary'} className={styles.btn}>短信登录</Button>
                        </Link>
                    ):(
                        <Link to={'/'}>
                            <Button type={'primary'} className={styles.btn}>跳过</Button>
                        </Link>
                    )
                }

            </div>
        </LoginPanel>
    )
})

import useInfiniteScroll from 'react-easy-infinite-scroll-hook';
import { observer } from "mobx-react-lite";
import {useEffect, useState} from "react";
import {Empty, Spin} from '@arco-design/web-react';
import styles from './index.module.less'

const InfiniteListComponent = ({ store,render,listKey='list',direction='down',load=true,className }) => {
    const [loadmoreOpen,setLoadmoreOpen] = useState(false);
    // TypeScript example:
    // const ref = useInfiniteScroll<YourElemntType>(...props);
    const ref = useInfiniteScroll({
        // Function to fetch more items
        next:(curDirection)=>{
            if(direction!==curDirection) return;
            store.loadmore({})
        },
        // The number of items loaded if you use the "Y-scroll" axis ("up" and "down")
        // if you are using the "X-scroll" axis ("left" and "right") use "columnCount" instead
        // you can also use "rowCount" and "columnCount" if you use "Y-scroll" and "X-scroll" at the same time
        rowCount: store[listKey].length,
        // Whether there are more items to load
        // if marked "true" in the specified direction, it will try to load more items if the threshold is reached
        // support for all directions "up", "down", "left", "right", both individually and in all directions at the same time
        hasMore: { [direction]: store.hasMore, },
        // reverse:{column:true}
        scrollThreshold:'20px',
    });
    function scrollToBottom(behavior = "smooth") {
        const container = ref.current;
        if (!container) return;

        container.scrollTo({
            top: container.scrollHeight,
            behavior,
        });
    }

    useEffect(()=>{
        load&&store.loadmore({})
    },[])

    return (
        <div
            ref={ref}
            className={`${styles.list} ${className}`}
        >
            <Spin loading={store.list.length===0&&store.loading} style={{ display: 'block',height:'100%' }}>
                {
                    store.list.length?
                    store.list.map(render):<Empty description={'暂无私信'}/>
                }
            </Spin>
        </div>
    );
};

export default observer(InfiniteListComponent);
import { makeAutoObservable } from "mobx";
import { get, post} from "../utils/request";
import {getQuery} from "../utils";

import queryString from "query-string";
const query=getQuery()
class Search {
  keyword=query.keyword||''
  type=query.type||'post'
  constructor() {
    makeAutoObservable(this);
  }

  get queryStr(){
    return queryString.stringify({type:this.type,keyword:this.keyword})
  }

  async postSearch() {
    if(!this.keyword) return
    const result = await get("/api/search/post",{keyword:this.keyword});
    if (result) {
      return result;
    }
    return false;
  }
  async userSearch(data) {
    const result = await post("/api/search/user", data);
    if (result) {
      return true;
    }
    return false;
  }
}
const search = new Search();
export default search;

import {Breadcrumb, Menu, Grid, Button, Divider} from '@arco-design/web-react';
import styles from "./index.module.less"
import { Link } from "react-router-dom";
const BreadcrumbItem = Breadcrumb.Item;

const Row = Grid.Row;
const Col = Grid.Col;

export default function ({routes=[],...props}){
    return (
        <Row gutter={24} className={styles.breadcrumb}>
            <Breadcrumb {...props}>
                {
                    routes.map((route,i)=>(
                        <BreadcrumbItem key={i}>
                            {
                                route.path?(
                                    <Link to={route.path}>
                                        {route.name}
                                    </Link>
                                ):route.name
                            }
                        </BreadcrumbItem>
                    ))
                }
            </Breadcrumb>
        </Row>
    )
}

import { List, Modal, Typography, Button, Message } from "@arco-design/web-react";
import styles from "./index.module.less";
import { List as ListStore } from "../../../store/List";
import { Avatar } from "../../../components";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import useTouchBottom from "../../../hooks/useTouchBottom";
import { get, post } from "../../../utils/request";

export default observer(({ type = "follower" }) => {
  const store = new ListStore("/api/follow/list");
  const [list, setList] = useState([]);
  const queryType = type === "follower" ? "followee" : "follower";
  const query = { type: queryType };
  const loadmore = async () => {
    setList(await store.loadmore(query));
  };
  useEffect(() => {
    loadmore();
  }, []);

  useTouchBottom(loadmore);

  const handleUnfollow = item => {
    Modal.confirm({
      title: "是否确认取消关注？",
      onOk: async () => {
        await post("/api/follow/delete", { followee: item.followee._id });
        setList(await store.refresh(query));
        Message.success("取消成功");
      }
    });
  };

  return (
    <List
      className={styles.subscription}
      bordered={false}
      dataSource={list}
      render={(item, index) => (
        <List.Item
          key={item.id}
          style={{
            padding: "20px 0",
            borderBottom: "1px solid var(--color-fill-3)"
          }}
          actions={
            type === "followee" && [
              <Button key="followee" onClick={handleUnfollow.bind(this, item)}>
                已关注
              </Button>
            ]
          }
        >
          <List.Item.Meta
            avatar={<Avatar src={item[type]?.avatar} user={item[type]} key={item[type]?._id} />}
            title={<div>{item[type]?.name}</div>}
            description={
              <Typography.Paragraph
                ellipsis={{
                  rows: 1,
                  ellipsisStr: "... "
                }}
              >
                {item[type]?.introduce}
              </Typography.Paragraph>
            }
          />
        </List.Item>
      )}
    />
  );
});

import { Modal } from "@arco-design/web-react";
import React from "react";
import styles from "./index.module.less";
export default function (props, ref) {
  return (
    <Modal {...props} className={styles.modal} alignCenter={false}>
        {props.children}
    </Modal>
  );
}

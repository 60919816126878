import { Popover, Button } from '@arco-design/web-react';
import { IconQuestionCircle } from '@arco-design/web-react/icon';


const Helper = ({children,...rest}) => {
    return (
        <Popover
            content={children}
            {...rest}
        >
            <IconQuestionCircle fontSize={22} style={{color:'#888'}}/>
        </Popover>
    );
};

export default Helper;

import { Avatar} from '@arco-design/web-react';
import userImg from "../../assets/images/userhead.png"
import { observer } from "mobx-react-lite"
import {userSelf} from "../../store"
import {Link} from "react-router-dom";
import v from "../../assets/images/v.svg";
import styles from "./index.module.less";
export default observer(function ({src,user,...props}){
  const id=user?._id||user?.id
  const Comp=(
      <div className={styles.avatar_wrap}>
          <Avatar {...props}>
              <img src={src||userImg}/>
          </Avatar>
          {/* <img src={v} className={styles.v} /> */}
          {user?.certify?.isCertified ? <img src={v} className={styles.v} /> : null}
      </div>
  )
  if(id===userSelf.id){
    return (
        <Link to={'/user'}>
          {Comp}
        </Link>
    )
  }
  return id&&user.name!=='系统通知'?(
      <a target='_blank' href={`/user/${id}`} rel="noreferrer">
        {Comp}
      </a>
  ):Comp
})

import React, { useState, useEffect } from "react";
import { Upload, Message, Progress, Modal } from "@arco-design/web-react";
import { IconPlus, IconEdit } from "@arco-design/web-react/icon";
import styles from "./index.module.less";

export default function (props) {
  const [file, setFile] = useState({ url: props.value });
  const cs = `arco-upload-list-item${file && file.status === "error" ? " is-error" : ""}`;
  const beforeUpload = file => {
    return new Promise(resolve => {
      const modal = Modal.confirm({
        title: "图片预览",
        icon: null,
        onCancel: () => {
          Message.info("取消上传");
          resolve(false);
          modal.close();
        },
        onOk: () => {
          resolve(file);
          modal.close();
        },
        alignCenter: false,
        className: styles.imgmodal,
        simple: false,
        content: (
          <div className={styles.imgwrap}>
            <div className={styles.imgcontent}>
              <img className={styles.img} src={URL.createObjectURL(file)}></img>
            </div>
          </div>
        )
      });
    });
  };
  return (
    <Upload
      className={styles["upload"]}
      style={{ width: 224, height: 120 }}
      action="/api/file/upload/?encrypt=1"
      fileList={file ? [file] : []}
      tip={file?.status === "error" ? "上传失败" : "支持10M以内的图片"}
      accept={".png,.jpeg,.jpg"}
      showUploadList={false}
      beforeUpload={beforeUpload}
      onChange={(_, currentFile) => {
        const { status, response } = currentFile;
        switch (status) {
          case "init":
          case "uploading":
            if (currentFile.originFile.size > 1024 * 1024 * 10) {
              Message.error("图片大小不能大于10M");
              break;
            }
            setFile({
              ...currentFile,
              url: URL.createObjectURL(currentFile.originFile)
            });
            break;
          case "error":
            Message.error(response.message || "上传失败，请重试!");
            setFile({
              ...currentFile,
              url: ""
            });
            break;
          case "done":
            setFile({
              ...currentFile,
              url: currentFile.response.data
            });
            props.onChange?.(currentFile.response.data);
            break;
        }
      }}
      onProgress={currentFile => {
        setFile(currentFile);
        if (currentFile.percent === 100) {
          setFile({
            ...currentFile,
            percent: 90
          });
        }
      }}
    >
      <div className={cs}>
        {file && file.url ? (
          <div className="arco-upload-list-item-picture custom-upload-avatar">
            <img src={file.url} />
            <div className="arco-upload-list-item-picture-mask">
              <IconEdit />
            </div>
            {file.status === "uploading" && file.percent < 100 && (
              <Progress
                percent={file.percent}
                type="circle"
                size="mini"
                style={{
                  position: "absolute",
                  left: "50%",
                  top: "50%",
                  transform: "translateX(-50%) translateY(-50%)"
                }}
              />
            )}
          </div>
        ) : (
          <div className="arco-upload-trigger-picture">
            <div className="arco-upload-trigger-picture-text">
              <IconPlus />
            </div>
          </div>
        )}
      </div>
    </Upload>
  );
}

import { io, Socket } from "socket.io-client";
import Conversation from "../store/conversation";

let socket;
function init(socket) {
  // 系统事件
  socket.on("disconnect", (msg) => {
    console.log("#disconnect", msg);
  });

  socket.on("disconnecting", () => {
    console.log("#disconnecting");
  });

  socket.on("error", () => {
    console.log("#error");
  });
}
export function getSocket() {
  const devHost=process.env.REACT_APP_BOE?'http://124.220.209.121:7001/':'http://192.168.18.202:7001/'

  if (!socket) {
    socket =
      process.env.NODE_ENV !== "production"
          ? io(devHost, {
            transports: ["websocket"],
            withCredentials: true,
          })
        : io({
            transports: ["websocket"],
            withCredentials: true,
          });
    init(socket);
    new Conversation(socket);
  }
  // 判断连接状态
  if (!socket.connected) {
    // 连接已经断开，尝试重新连接
    socket.connect();
  }
  return socket;
}

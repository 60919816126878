import { makeAutoObservable } from "mobx";
import { get, post } from "../utils/request";
class Certify {
 
  constructor() {
    makeAutoObservable(this);
  }
  async getMessage() {
    const result = await get("/api/user/certify/status");
    if (result) {
      return result;
    }
    return false;
  }
  async create(data) {
    const result = await post("/api/user/certify/create", data);
    if (result) {
      return true;
    }
    return false;
  }
}
const certify = new Certify();
export default certify;

import {Layout, Menu, Empty, Card, Divider} from '@arco-design/web-react';
import styles from "./index.module.less"
import {Author,Helper} from "../index";
import {get} from "../../utils/request";
import {getQuery} from "../../utils";
import { useState,useEffect } from "react";
import dayjs  from "dayjs";

function Num({n}){
    switch (n){
        case 1:
            return <span className={styles.emoji}>🥇</span>
        case 2:
            return <span className={styles.emoji}>🥈</span>
        case 3:
            return <span className={styles.emoji}>🥉</span>
        default:
            return n
    }
}
export default function ({last}){
    const [authors,setAuthors]=useState([])
    const [isEmpty,setIsEmpty]=useState(false)
    const query=getQuery()
    async function getCalcList(){
        let data=await get(`/api/leaderboard/calculate`,{
            category:query.category,
            limit:100,
            last
        })
        setAuthors(data.list)
        if(!data.list?.length){
            setIsEmpty(true)
        }
    }

    async function getList(isLast){
        const time=isLast?dayjs().add(-1,'month'):dayjs()
        const data=await get(`/api/leaderboard/list`,{
            category:query.category,
            limit:isLast?100:20,
            realTime:isLast?undefined:1,
            month:time.month()+1,  // todo
            year:time.year(),
        })
        setAuthors(data.list)
        if(!data.list?.length){
            setIsEmpty(true)
        }
    }
    useEffect(()=>{
        getList(last)
    },[])
    return (
        <div className={styles.list}>
            {
                isEmpty?
                    <Empty description={'暂无排名'} imgSrc={'https://images.redream.cn/upic/2019/20220813131805-No (1).png'}/>:
                    authors.map((author,i)=>(
                        <div key={i} className={styles.line}>
                            <span className={styles.num}>
                                <Num n={i+1}></Num>
                            </span>
                            <Author user={author.user} followed={author.user.followed}/>
                            {process.env.REACT_APP_ENV==='development'?author.points:null}
                        </div>
                    ))
            }
        </div>
    )
}

import {PageHeader, Menu, Grid, Button, Divider} from '@arco-design/web-react';
import styles from "./index.module.less"
import { Link } from "react-router-dom";
import { IconRight } from '@arco-design/web-react/icon';

const Row = Grid.Row;
const Col = Grid.Col;

export default function ({title,url='',moreText='查看更多'}){
    return (
        <Row align={'center'} className={styles.containerTitle}>
            <Col flex='1' className={styles.title}>
                {title}
            </Col>
            <Col flex='1' className={styles.more}>
                <Link to={url}>
                    {moreText}<IconRight />
                </Link>
            </Col>
        </Row>
    )
}

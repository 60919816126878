import React, { useEffect } from "react";
import styles from "./index.module.less";
export default function (props) {
  const { type } = props;
  const searchType = {
    post: "帖子",
    subscribe: "订阅",
    collect: "收藏",
    dig: "点赞",
    followee: "我的关注",
    follower: "我的粉丝"
  };
  useEffect(() => {
    console.log(searchType[type]);
  });

  return (
    <div className={styles.tabtitle}>
      {searchType[type]}
      {/* <span className={styles.count}></span> */}
    </div>
  );
}

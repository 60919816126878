import useInfiniteScroll from 'react-easy-infinite-scroll-hook';
import { observer } from "mobx-react-lite";
import {useEffect, useState} from "react";
import styles from './index.module.less'
import { Spin} from '@arco-design/web-react';


const InfiniteListComponent = ({ store,render,direction='down',loadmore=true }) => {
    const [loadmoreOpen,setLoadmoreOpen] = useState(false);
    // TypeScript example:
    // const ref = useInfiniteScroll<YourElemntType>(...props);
    const ref = useInfiniteScroll({
        // Function to fetch more items
        next:(curDirection)=>{
            if(!store.historyInit) return;
            if(!loadmoreOpen) return;
            console.log(curDirection,'curDirection',loadmoreOpen)
            if(direction!==curDirection) return;
            store.loadmore(false)
        },
        // The number of items loaded if you use the "Y-scroll" axis ("up" and "down")
        // if you are using the "X-scroll" axis ("left" and "right") use "columnCount" instead
        // you can also use "rowCount" and "columnCount" if you use "Y-scroll" and "X-scroll" at the same time
        rowCount: store.messages.length,
        // Whether there are more items to load
        // if marked "true" in the specified direction, it will try to load more items if the threshold is reached
        // support for all directions "up", "down", "left", "right", both individually and in all directions at the same time
        hasMore: { [direction]: store.hasMore, },
        // reverse:{column:true},
        scrollThreshold:'0px',

    });
    function scrollToBottom(behavior = "smooth") {
        const container = ref.current;
        if (!container) return;
        setLoadmoreOpen(false)
        setTimeout(()=>{
            container.scrollTo({
                top: container.scrollHeight,
                behavior,
            });
        },0)
        setTimeout(()=>{
            setLoadmoreOpen(true)
        },1000)
    }
    useEffect(()=>{

        scrollToBottom('auto')

    },[store.historyInit])

    useEffect(()=>{
        if(store.isNewMessage){
            scrollToBottom('smooth')
        }
    },[store.messages.length])

    return (
        <div
            ref={ref}
            // style={{ height: "100%", overflow: "auto" }}
            className={styles.messageList}
        >
            <Spin loading={!store.isInit&&store.isLoading} style={{ display: 'block',height:'100%' }}>
                {store.messages.map(render)}
                {store.tips && <div className={styles.tips}>{store.tips}</div>}
                <br/>
            </Spin>

        </div>
    );
};

export default observer(InfiniteListComponent);
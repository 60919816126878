import { makeAutoObservable } from "mobx"
import { get,post } from "../utils/request"
import {userSelf} from '../store'

class Comments {
    list=[]
    loading=false
    limit=10
    offset=0
    total=1
    postId=''
    activeCommentId='' // 全局只有一个回复框

    constructor() {
        makeAutoObservable(this)
    }

    async getList(query){
        this.loading=true
        return await get('/api/comment/list',query).finally(()=>this.loading=false)
    }

    reset(){
        this.list=[]
        this.loading=false
        this.limit=10
        this.offset=0
        this.total=1
        this.postId=''
    }

    loadmore=async (query,refresh=false)=>{
        if(this.loading) return;
        this.postId=query.post
        let limit=query.limit||this.limit
        if(refresh){
            this.offset=0
            this.total=1
            limit=this.list.length<10?this.limit:this.list.length // 如果是刷新，当前列表长度大于10，就一次性请求目前所有；但对于新增一条知乎的刷新，最后一条会挤出去
        }
        if(this.offset>=this.total) return;
        const result=await this.getList({...query,offset:this.offset,limit})
        if(!result) return
        this.list=[...(refresh?[]:this.list),...result.list]
        this.offset=this.offset+limit
        this.total=result.total
        return result
    }
}
export {Comments}
export default new Comments()

import styles from "./index.module.less"
import {useEffect} from "react"

// state:login|bind|uid
export default function ({state,tips='请确保在个人设置页面绑定过微信'}){
    const stateStr=JSON.stringify(state||{type:'login'})
    useEffect(()=>{
        new window.WxLogin({
            self_redirect:false,
            id:"login_container",
            appid: "wx21e9d07051d46a84",
            scope: "snsapi_login",
            // redirect_uri: "https://www.imeikan.com/",
            redirect_uri: `https://www.imeikan.com/api/auth/wechat/callback`,
            state: stateStr,
            // style: "white",
            // href: "https://images.redream.cn/upic/2019/20220616182538-login.css"
            // fixme:hash
            href:"https://meikan-public-images.oss-cn-beijing.aliyuncs.com/imeikan/assets/index.css"
        });
    },[])
    return (
        <div className={styles.loginContainer}>
            <div className={styles.tips}>{tips}</div>
            <div id={'login_container'}></div>
        </div>
    )
}

export const ObjectTypeStatus = {
  Post: "文章",
  Comment: "评论",
  User: "用户"
};

export const reasons = {
  Comment: [
    [
      { type: "ad", reason: "涉及广告" },
      { type: "Inappropriate", reason: "不当言论" },
      { type: "violate", reason: "违规违法" }
    ],
    [
      { type: "attack", reason: "辱骂&人身攻击" },
      { type: "rumour", reason: "造谣传谣" },
      { type: "custom", reason: "其他原因" }
    ]
  ],
  Post: [
    [
      { type: "ad", reason: "涉及广告" },
      { type: "attack", reason: "辱骂&人身攻击" },
      { type: "old", reason: "内容信息陈旧" }
    ],
    [
      { type: "violate", reason: "违规违法" },
      { type: "badjump", reason: "链接恶意跳转 " },
      { type: "factdiff", reason: "描述与事实不符" }
    ],
    [
      { type: "copy", reason: "内容抄袭" },
      { type: "cantopen", reason: "链接无法打开" },
      { type: "bad", reason: "描述夸张，内容质量差" }
    ],
    [
      { type: "vulgar", reason: "低俗色情" },
      { type: "linkad", reason: "链接内容涉及广告" },
      { type: "linkdiff", reason: "描述与链接内容不相符 " }
    ],
    [
      { type: "tort", reason: "侵犯隐私、著作、名誉等权益" },
      { type: "custom", reason: "其他原因" }
    ]
  ],
  User: [
    [
      { type: "ad", reason: "营销广告" },
      { type: "vulgar", reason: "色情低俗 " },
      { type: "copy", reason: "内容抄袭" }
    ],
    [
      { type: "attack", reason: "辱骂谩骂" },
      { type: "violence", reason: "血腥暴力" },
      { type: "illegal", reason: "违法违禁" }
    ],
    [
      { type: "harass", reason: "骚扰" },
      { type: "rumour", reason: "造谣传谣" },
      { type: "politics", reason: "涉政有害言论" }
    ]
  ]
};

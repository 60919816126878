/**
 * https://stackoverflow.com/questions/69864165/error-privateroute-is-not-a-route-component-all-component-children-of-rou
 */
import { Navigate, Outlet } from 'react-router-dom';
import { observer } from "mobx-react-lite"
import {userSelf} from "../../store";

export default  observer(function (){
    return userSelf.isLogin ? <Outlet /> : <Navigate to="/login" />;
})

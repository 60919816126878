import { EventEmitter } from "eventemitter3";
export const EVENTS = {
  POST_ACTION: "post_action",
  OPEN_LINK: "open_link",
  FOLLOW_USER: "follow_user",
  COMMENT_POST: "comment_post",
  DELETE_POST: "delete_post",
  USERINFO_READY: "userinfo_ready",
  POST_STATUS_CHANGE: "post_status_change",
  CERTIFY_STATUS_CHANGE: "certify_status_change",
  CONVERSATION_PIN: "conversation_pin",
  CONVERSATION_DELETE: "conversation_delete",
  CONVERSATION_NEW: "conversation_new",
  CATEGORY_SUBSCRIBE: "category_subscribe",
  USER_INFO_CHANGE:'USER_INFO_CHANGE',
  SEARCH:'SEARCH',

};
export const events = new EventEmitter();

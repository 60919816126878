import styles from "./index.module.less";
import {Content, UserForm} from "../../../components";
import {Alert, Card} from "@arco-design/web-react";
import {observer} from "mobx-react-lite";
import { userSelf } from '../../../store';

export default observer(function () {
  return (
      <Card title={"修改用户信息"} style={{height:'100%'}} bodyStyle={{position:"relative"}}>
          {
              userSelf.modifyStatus!==0&&(
                  <>
                      <Alert
                          banner={true}
                          className={styles.banner}
                          type={userSelf.modifyStatus===2?'warning':'success'}
                          content={userSelf.modifyStatus===2?'个人信息修改不通过，请修正后重新提交':'个人信息修改审核中'} />
                      <br/>
                      <br/>
                  </>
              )
          }
          <UserForm init={true} />
      </Card>
  );
})

import { makeAutoObservable } from "mobx";
import { get } from "../utils/request";
const defaultIntroduce = "暂无简介";
class User {
  id = "";
  name = "";
  introduce = "";
  avatar = "";
  subscription = [];
  posts = 0;
  honorValue = 0;
  points = 0;
  followers = 0;
  followed = false;
  totalDig = 0;
  category = "";
  degree = "";
  major = "";
  university = "";
  weight = "";
  isCertified = false;
  certifiedCategory=''
  ip={}
  ban={}
  deletedAt = null;
  constructor() {
    makeAutoObservable(this);
    this.cache = {};
  }

  setUserInfo(userInfo) {
    this.id = userInfo.id;
    this.name = userInfo.name;
    this.introduce = userInfo.introduce || defaultIntroduce;
    this.avatar = userInfo.avatar;
    this.subscription = userInfo.subscription;
    this.honorValue = userInfo.honorValue;
    this.followers = userInfo.followers;
    this.followed = userInfo.followed;
    this.points = userInfo.points;
    this.posts = userInfo.posts;
    this.ip = userInfo.ip;
    this.totalDig = userInfo.totalDig;
    this.category = userInfo?.certify?.category;
    this.isCertified = userInfo?.certify?.isCertified;
    this.degree = userInfo?.certify?.degree;
    this.major = userInfo?.certify?.major;
    this.university = userInfo?.certify?.university;
    this.weight = userInfo?.certify?.weight;
    this.certifiedCategory = userInfo?.certify?.category?.title;
    this.deletedAt = userInfo.deletedAt;
    this.ban = userInfo.ban;
  }

  async getUser(id) {
    if (this.cache[id]?.user) return this.cache[id]?.user;
    const result = await get("/api/user/get", { _id: id });
    if (result && result.id) {
      this.cache[id] = this.cache[id] || {};
      this.cache[id].user = result;
      return this.cache[id].user;
    }
    return {};
  }

  async getUserInfo(_id) {
    const result = await get("/api/user/get", { _id });
    if (result && result.id) {
      this.setUserInfo(result);
      return result;
    }
    return false;
  }
}

export default User;

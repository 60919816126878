import { makeAutoObservable } from "mobx";
import { get, post } from "../utils/request";
import { ICategory } from "../types/index";
import userSelf from "./userSelf";

class Setting {
  blackList: string[] = [];
  chat: Record<string, any> = {};
  constructor() {
    makeAutoObservable(this);
  }

  setField(result: any) {
    if (!result) return false;
    this.blackList = result.blackList || [];
    this.chat = result.chat || {};
    return true;
  }

  async fetchSelfSetting() {
    const result = await get("/api/setting/get");
    return this.setField(result);
  }

  async fetchSetting(userId: string) {
    return await get("/api/setting/get", { userId });
  }

  async updateBlackList(userId: string, type: "add" | "remove") {
    const result = await post("/api/setting/blacklist", { userId, type });
    return this.setField(result);
  }

  async updateChat(status: "all" | "followedOnly" | "none") {
    const result = await post("/api/setting/chat/update", { openStatus: status });
    return this.setField(result);
  }
}

export default new Setting();

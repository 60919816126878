import {Layout, Menu, Grid, Modal, Button, Typography} from '@arco-design/web-react';
import styles from "./index.module.less"
import {Content,PayList} from "../../components"
import {list} from "../PayNews/data"
import {useState} from "react"
import {getQuery} from "../../utils"
const { Title, Paragraph, Text } = Typography;

const MenuItem = Menu.Item;
const SubMenu = Menu.SubMenu;
const Row = Grid.Row;
const Col = Grid.Col;

export default function (){
    const query=getQuery()
    const {title,description}=list[query.id>>0]
    const [visible, setVisible] = useState(false);

    return (
        <Content className={styles.about}>
            <Row gutter={24} align={'center'}>
                <Col span={24} className={styles.content}>
                    <Title heading={5}>社区推文规范：</Title>
                    <Paragraph>
                        <ul>
                            <li>
                                描述及链接内容不涉及违规内容（例如：广告、暴恐、色情、政治、谩骂）。
                            </li>
                            <li>
                                链接有效性：a.链接是能够正常打开访问；b.打开的网址属于大型内容平台的，期刊的，官方网站的，即链接是否为可靠信息来源（期刊、公众号、知乎、头条等的链接）， 禁止私人博客
                            </li>
                            <li>
                                内容对应性：a.标题为链接下的原文标题；b.内容的描述和链接内的内容相对应；c.文本部分及链接内容符合专栏用户群（例如农学专栏只能发布和农学相关的内容）
                            </li>
                            <li>
                                内容不涉及直接搬运（复制链接原文内容）
                            </li>
                        </ul>
                    </Paragraph>
                    <Title heading={5}>社区动态规范：</Title>
                    <Paragraph>
                        <ul>
                            <li>
                                文字及图片内容不涉及违规内容（例如：广告、暴恐、色情、政治、谩骂、挑衅、抹黑他人及组织、人肉搜索他人、不正当交友等违法违规信息）
                            </li>
                            <li>
                                文字及图片内容是否符合专栏用户群（例如农学专栏只能发布和农学相关的内容）
                            </li>

                        </ul>
                    </Paragraph>
                    <Title heading={6}>学习的路上，你并不孤单！</Title>
                </Col>
            </Row>
            <Modal
                title='付费阅读'
                visible={visible}
                onOk={() => setVisible(false)}
                onCancel={() => setVisible(false)}
                autoFocus={false}
                focusLock={true}
            >
                <Row gutter={24} align={'center'}>
                    <Col span={12} className={styles.s}>
                        <img width={'200px'} src="https://images.redream.cn/upic/2019/20220611182354-dTGQ62.jpg" alt=""/>
                    </Col>
                    <Col span={12} className={styles.s}>
                        <img width={'200px'} src="https://images.redream.cn/upic/2019/20220611182445-25S4ri.jpg" alt=""/>
                    </Col>
                </Row>
            </Modal>
        </Content>
    )
}

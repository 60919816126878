import React, { memo, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { Button, Grid, Slider } from "@arco-design/web-react";
import { IconMinus, IconPlus } from "@arco-design/web-react/icon";
import EasyCropper from "react-easy-crop";

const Cropper = memo(props => {
  const { file, onOk } = props;
  const url = URL.createObjectURL(file);
  const [crop, setCrop] = useState({
    x: 0,
    y: 0
  });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(undefined);
  const onCropComplete = async (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  async function _getCroppedImg(url, pixelCrop) {
    const image = await new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", error => reject(error));
      image.src = url;
    });
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    if (!ctx || !image) {
      return null;
    }
    const scale = 100 / pixelCrop.width;
    const width = image.width;
    const height = image.height;
    const sw = width * scale;
    const sh = height * scale;
    canvas.width = sw;
    canvas.height = sh;
    ctx.drawImage(image, 0, 0, width, height, 0, 0, sw, sh);
    const data = ctx.getImageData(0, 0, sw, sh);
    canvas.width = pixelCrop.width * scale;
    canvas.height = pixelCrop.height * scale;
    ctx.putImageData(
      data,
      Math.round(0 - pixelCrop.x * scale),
      Math.round(0 - pixelCrop.y * scale)
    );
    return new Promise(resolve => {
      canvas.toBlob(blob => {
        resolve(blob);
      });
    });
  }

  const CropImage = async () => {
    const blob = await _getCroppedImg(url, croppedAreaPixels);
    if (blob) {
      const newFile = new File([blob], file.name || "image", {
        type: file.type || "image/*"
      });
      onOk(newFile);
    }
  };

  return (
    <div>
      <div
        style={{
          width: "100%",
          height: 280,
          position: "relative"
        }}
      >
        <EasyCropper
          style={{
            containerStyle: {
              width: "100%",
              height: 280
            }
          }}
          aspect={4 / 4}
          image={url}
          crop={crop}
          zoom={zoom}
          onCropComplete={onCropComplete}
          onCropChange={setCrop}
          onZoomChange={setZoom}
          cropSize={{ width: 200, height: 200 }}
        />
      </div>
      <Grid.Row justify="space-between" style={{ marginTop: 20, marginBottom: 20 }}>
        <Grid.Row
          style={{
            flex: 1,
            marginLeft: 12,
            marginRight: 12
          }}
        >
          <IconMinus
            style={{ marginRight: 10 }}
            onClick={() => {
              setZoom(Math.max(1, zoom - 0.1));
            }}
          />
          <Slider
            style={{ flex: 1 }}
            step={0.1}
            value={zoom}
            onChange={v => {
              setZoom(v);
            }}
            min={0.8}
            max={3}
          />
          <IconPlus
            style={{ marginLeft: 10 }}
            onClick={() => {
              setZoom(Math.min(3, zoom + 0.1));
            }}
          />
        </Grid.Row>
      </Grid.Row>

      <Grid.Row justify="end">
        <Button onClick={props.onCancel} style={{ marginRight: 20 }}>
          取消
        </Button>
        <Button type="primary" onClick={() => CropImage()}>
          确定
        </Button>
      </Grid.Row>
    </div>
  );
});
export default Cropper;

import { makeAutoObservable } from "mobx";
import { nanoid } from "nanoid";
import { IChatMessage, IUser, IConversation } from "../types";
import { get } from "../utils/request";
import users from "./users";
import userSelf from "./userSelf";
import setting from "./setting";
import { getSocket } from "../utils/socket";
import { EVENTS, events } from "../utils/events";

function debounceWithMaxId(fn: (message: IChatMessage) => void, delay: number) {
  let timerId: NodeJS.Timeout | null;
  let maxIdMsg: any = null;

  return function (this: any) {
    const args = arguments;

    // 如果已经设置了定时器，清除它
    if (timerId) {
      clearTimeout(timerId);
    }

    // 获取当前函数的 Mongo _id
    const currentId = args[0]._id;
    const currentMsg = args[0];

    // 如果 maxId 为 null 或者当前函数的 Mongo _id 比它大，更新 maxId
    if (maxIdMsg === null || currentId > maxIdMsg._id) {
      maxIdMsg = currentMsg;
    }

    // 设置新的定时器
    timerId = setTimeout(() => {
      // 执行函数，并且传入保留的最大的 Mongo _id
      fn(maxIdMsg);
      // 定时器执行完后，清空 maxId 和 timerId
      maxIdMsg = null;
      timerId = null;
    }, delay);
  };
}

type IPartialMessage = Partial<Pick<IChatMessage, "content" | "isSelf">> & {
  status?: "success" | "fail" | "loading";
  id?: number;
};
class ChatStore {
  type = "chat";
  messages: IChatMessage[] = [];
  hasMore = true;
  toUserId = "";
  fromUserId = "";
  toUser!: IUser;
  fromUser!: IUser;
  historyInit = false;
  conversation!: IConversation;
  conversationId = "";
  toUserOpenStatus = "all";
  isNewMessage = true;
  isInit = false;
  isLoading=false;
  firstMessageId = ""; // 队列里第一条消息的id,在向上loadmore 后恢复滚动位置
  content="";
  // tips = "在对方回复之前只能发送一条私信";
  // disableSend = false;
  constructor(fromUserId: string, toUserId: string, conversationId?: string) {
    makeAutoObservable(this);
    this.toUserId = toUserId;
    this.fromUserId = fromUserId;
    this.conversationId = conversationId || ""; //直接从个人中心进入聊天，没有conversationId

  }

  get disableSend() {
    console.log(this.toUserOpenStatus, this.toUser?.followed);
    if (this.toUserOpenStatus === "none") return true;
    if (this.toUserOpenStatus === "followedOnly" && this.toUser?.followed === false) return true;
    if (!this.conversation) return true;
    if (this.conversation.historySendCount === 1&&this.conversation.historyReceiveCount===0) return true;
    return false;
  }

  get tips() {
    if (this.toUserOpenStatus === "none") return "对方已关闭私信功能";
    if (this.toUserOpenStatus === "followedOnly" && this.toUser?.followed === false)
      return "对方已设置仅对关注用户开放私信功能";
    if(!this.conversation) return "";
    if (this.conversation.historySendCount === 0) return "在对方回复之前只能发送一条私信";
    if (this.conversation.historySendCount === 1&&this.conversation.historyReceiveCount===0) return "在对方回复之前只能发送一条私信";
    if (this.conversation.historySendCount === 0&&this.conversation.historyReceiveCount===1) return "在你回复之前对方只能发送一条私信";
    return "";
  }

  async init() {

    if (!userSelf.isLogin) return;
    if(this.isInit) return
    this.isInit = true;
    await this.getConversation()
    await this.fetchHistoryMessage();
    this.getFriendChatSetting();
    this.toUser = (await users.getUser(this.toUserId)) as IUser;
    this.fromUser = (await users.getUser(this.fromUserId)) as IUser;
    this.onReceiveMessage();

  }

  async getFriendChatSetting() {
    const hisSetting = (await setting.fetchSetting(this.toUserId)) || {};
    if (!hisSetting.openSatatus) {
      this.toUserOpenStatus = "all";
    }
    const { openStatus } = hisSetting.chat||{};
    this.toUserOpenStatus = openStatus;
  }

  async getConversation() {
    // 没有会话，创建会话；返回空就有bug了
    const conversation = await get(
      "/api/conversation/get",
      this.conversationId
        ? {
            conversation: this.conversationId,
          }
        : {
            fromUser: this.fromUserId,
            toUser: this.toUserId,
          },
    );
    this.conversation=conversation
    this.conversationId = this.conversation._id;
    this.fromUser=this.conversation.participants[0]
    this.toUser=this.conversation.participants[1]
    // this.conversation.participants.forEach((user) => {
    //   if (user._id === this.fromUserId) {
    //     this.fromUser = user;
    //   } else {
    //     this.toUser = user;
    //   }
    // });

    events.emit(EVENTS.CONVERSATION_NEW, {conversation});

  }
  async fetchHistoryMessage() {

    await this.loadmore(true);
    this.historyInit=true

  }

  async loadmore(isNew = false) {
    if (!this.hasMore) return;
    if(this.isLoading) return;
    this.isLoading=true;
    const data = await get("/api/message/list", {
      conversationId: this.conversationId,
      limit: 15,
      offset: this.messages.length,
    });
    // data.list.forEach(this.unshift);
    this.messages = [
      ...data.list.reverse().map((item: any) => ({ ...item, isSelf: item.fromUser._id === this.fromUserId })),
      ...this.messages,
    ];
    this.firstMessageId = this.messages[0]?._id!;
    this.hasMore = this.messages.length < data.total;
    this.isNewMessage = isNew;
    this.isLoading=false;
  }

  concat(list: IChatMessage[], newList: IChatMessage[]) {
    newList.forEach((item) => {
      const index = list.findIndex((o) => o._id === item._id);
      if (index === -1) {
        this.unshift(item);
      }
    });
    return list;
  }

  // 从队头添加消息，用于历史消息
  unshift = (message: Partial<IChatMessage>) => {
    if (this.isInit) {
      // 首次拉的历史消息也算新消息
      this.isNewMessage = false;
    }

    this.messages.unshift({
      isSelf: message.fromUser?._id === this.fromUserId,
      ...(message as any),
    });
  };

  // 只能用这两个方法添加消息
  push(message: Partial<IChatMessage>) {
    this.isNewMessage = true;
    this.messages.push({
      createdAt: new Date().toISOString(),
      read: 1,
      sendStatus: 1,
      content: "",
      isSelf: message.fromUser?._id === this.fromUserId,
      ...(message as any),
    });
    //发送一条后，等待对方回复，不能再发送
    // if (this.messages.length === 1 && this.messages[0].isSelf) {
    //   this.disableSend = true;
    // }
  }

  replace(index: number, message: IChatMessage) {
    this.messages[index] = {
      isSelf: message.fromUser?._id === this.fromUserId,
      ...(message as any),
    };
  }

  sendMessage(content: string) {
    const socket = getSocket();
    const message = {
      conversation: this.conversationId,
      toUser: this.toUserId,
      fromUser: this.fromUserId,
      content,
    };
    const currentIndex = this.messages.length;
    // 本地暂存message
    const tempMessage = {
      ...message,
      fromUser: this.fromUser,
      toUser: this.toUser,
    } as unknown as IChatMessage;
    this.push(tempMessage);
    // const timer=setTimeout(()=>{
    //   this.replace(currentIndex,{
    //     ...tempMessage,
    //     sendStatus:2,
    //     failReason:'消息发送超时，请稍后再试'
    //   })
    //   console.log(this.messages,1111)
    // },1000)
    socket.emit("user:message", message, (response: any) => {
      const { code, sendStatus, msg, data: ackMessage } = response;
      if (code !== 0) {
        return
      }
      // 替换本地临时消息
      this.replace(currentIndex, ackMessage);
      // 重新拉取conversation,确保 disableSend 更新
      if (this.conversation.historySendCount === 0){
        this.conversation.historySendCount++
      }
    });
  }

  readMessage = (message: IChatMessage) => {
    const socket = getSocket();
    const { _id } = message;
    // 自己发的消息暂时没有id
    if (!_id) return;
    socket.emit("user:message:read", {
      conversationId: this.conversation._id,
      messageId: _id,
    });
  };

  debuncedReadMessage = debounceWithMaxId(this.readMessage, 1000);

  onReceiveMessage = () => {
    const socket = getSocket();
    socket.on("user:message", (msg) => {
      // 不是当前会话的消息不加入消息队列
      if (!this.isCurrentConversationMessage(msg)) return;
      this.push(msg);
      // if (this.disableSend) {
      //   this.disableSend = false;
      // }
      if (this.conversation.historyReceiveCount===0){
        this.conversation.historyReceiveCount++
      }
    });
  };

  isCurrentConversationMessage(message: IChatMessage) {
    // 来自好友的消息
    if (message.fromUser._id === this.toUserId) return true;
    // 自己发送的消息
    if (message.toUser._id === this.toUserId) return true;
    return false;
  }

  get lastMessage() {
    return this.messages[this.messages.length - 1];
  }

  // 用于缓存chat 实例
  static chatsMap: Record<string, ChatStore> = {};

  static async getChatInstance(fromUserId: string, toUserId: string,conversationId:string) {
    const key = `${fromUserId}-${toUserId}`;
    if (!this.chatsMap[key]) {

      this.chatsMap[key] = new ChatStore(fromUserId, toUserId,conversationId);
      this.chatsMap[key].init()
    }
    return this.chatsMap[key];
  }

  static removeChatInstance(fromUserId: string, toUserId: string) {
    const key = `${fromUserId}-${toUserId}`;
    delete this.chatsMap[key];
  }
}

export default ChatStore;

// 触底加载 hook
import { useEffect } from 'react';
import { throttle } from 'lodash';

const isTouchBottom = (handler) => {
    // 文档显示区域高度
    const showHeight = window.innerHeight;
    // 网页卷曲高度
    const scrollTopHeight = document.body.scrollTop || document.documentElement.scrollTop;
    // 所有内容高度
    const allHeight = document.body.scrollHeight;
    // (所有内容高度 = 文档显示区域高度 + 网页卷曲高度) 时即为触底
    // document.body.scrollTop ==0 的时候，可能是从别的页面跳转过来引发的滚动

    if (allHeight - 10 <= showHeight + scrollTopHeight&&scrollTopHeight!==0) {
        console.log('touch Bottom')
        handler();
    }
};

const useTouchBottom = (fn) => {
    const useFn = throttle(() => {
        if (typeof fn === 'function') {
            isTouchBottom(fn);
        };
    }, 500);
    useEffect(() => {
        window.addEventListener('scroll', useFn);
        return () => {
            window.removeEventListener('scroll', useFn);
        };
    }, [fn]);
};

export default useTouchBottom;


import {
  Divider,
  Badge,
  Typography,
  Dropdown,
  Button,
  Menu,
} from "@arco-design/web-react";
import styles from "./index.module.less";
import { userSelf, notify } from "../../store";
import { SelfAvatar } from "../index";
import { IconNotification, IconBulb,IconEmail } from "@arco-design/web-react/icon";
import { Link, useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";

export default observer(function () {
  const navigate = useNavigate();
  const handleMenuClick = async (key) => {
    if (key === "logout") {
      await userSelf.logout();
      navigate(`/`);
    }
  };
  return (
    <div className={styles.userInfo}>
      {userSelf.isLogin ? (
        <div className={styles.login}>
          <Link to={"/chat"}>
            <Badge count={userSelf.allUnreadCount}>
              <Button
                  className={styles.notify}
                  type={"text"}
                  // type='outline'
                  shape="circle"
                  icon={<IconEmail className={styles.icon} />}
              ></Button>
            </Badge>
          </Link>
          <Link to={"/message"}>
            <Badge count={notify.allUnreadCount}>
              <Button
                className={styles.notify}
                type={"text"}
                // type='outline'
                shape="circle"
                icon={<IconNotification className={styles.icon} />}
              ></Button>
            </Badge>
          </Link>
          <a target={'_blank'} rel="noreferrer" href={"/creation"}>
            <Button
              className={styles.creation}
              type={"primary"}
              icon={<IconBulb />}
            >
              发布推文/动态
            </Button>
          </a>
          <Dropdown
            droplist={
              <Menu onClickMenuItem={handleMenuClick}>
                <Link to={"/user"}>
                  <Menu.Item key="info">个人主页</Menu.Item>
                </Link>
                <Link to={"/setting/account"}>
                  <Menu.Item key="info">用户设置</Menu.Item>
                </Link>
                <Divider style={{ margin: "4px 0" }} />
                <Menu.Item key="logout">退出登录</Menu.Item>
              </Menu>
            }
          >
            <div className={styles.userWrap}>
              <SelfAvatar className={styles.avatar} />
              <span className={styles.username}>{userSelf.name}</span>
            </div>
          </Dropdown>
        </div>
      ) : (
        <div className={styles.unlogin}>
          <Link to={"/login"}>
            <Button type="text">登录 | 注册</Button>
          </Link>
          {/*<Divider type='vertical' style={{margin:0}}/>*/}
          {/*<Link to={'/register'}>*/}
          {/*    <Button type='text' >注册</Button>*/}
          {/*</Link>*/}
        </div>
      )}
    </div>
  );
});

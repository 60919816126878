import { makeAutoObservable } from "mobx";
import { get, post } from "../utils/request";
import { userSelf } from "../store";
import { events, EVENTS } from "../utils/events";

class Category {
  rawList = [];
  total = 0;
  loading = true

  constructor() {
    makeAutoObservable(this);
  }

  get list() {
    return this.rawList.map((o) => {
      return {
        ...o,
      };
    });
  }

  async getList() {
    const result = await get("/api/category/list", { limit: 20 });
    if (result) {
      this.rawList = result.list;
      this.total = result.total;
      this.loading = false
      return this.list;
    }
    return false;
  }

  async subscribe(id, action = "subscribe") {
    const result = await post("/api/category/subscribe", { id, action });
    if (result) {
      events.emit(EVENTS.CATEGORY_SUBSCRIBE, { id, subscribed: action === "subscribe" });

      await userSelf.getUserInfo();
      // await this.getList()
      return true;
    }
    return false;
  }
}
const category = new Category();
// category.getList();
export default category;

import {Form, Input, Radio, Message, Modal, Button, Alert, Card} from '@arco-design/web-react';
import styles from "./index.module.less"
import setting from '../../../store/setting';
import {useState,useRef,useEffect} from "react";
import { observer } from "mobx-react-lite"
const FormItem = Form.Item;
const RadioGroup = Radio.Group;


export default observer(function (){
    const [status, setStatus] = useState(setting.chat.openStatus || "all");
    const handleChange = (value) => {
        setStatus(value);
        setting.updateChat(value);
    };
    useEffect(() => {
        setStatus(setting.chat.openStatus);
    }, [setting.chat.openStatus]);
    useEffect(() => {
        setting.fetchSelfSetting();
    },[])
    return (
        <Card title={'隐私设置'} className={styles.card}>
            <Form
                layout={'vertical'}
                style={{ width: 400 }}
            >
            <FormItem className={''} label={"谁可以私信我:"} >
                <RadioGroup
                    type='button'
                    name='lang'
                    value={status}
                    onChange={handleChange}
                    style={{ marginRight: 20, marginBottom: 20 }}
                >
                    <Radio value='all'>所有人</Radio>
                    <Radio value='followedOnly'>仅关注用户</Radio>
                    <Radio value='none'>
                        任何人都不行
                    </Radio>
                </RadioGroup>
            </FormItem>
            </Form>

        </Card>
    )
})

import { Comment,Space,Divider,Message} from '@arco-design/web-react';
import {
    IconMessage,
    IconStarFill,
    IconStar,
    IconThumbUp,
    IconThumbUpFill,
    IconDelete,
    IconEye
} from '@arco-design/web-react/icon';
import {useState} from 'react'
import {userSelf} from '../../store'
import {FeedBack,Avatar,Favicon} from '../index'
import dayjs from 'dayjs'
import {formatDate} from '../../utils'
import 'dayjs/locale/zh-cn'
import relativeTime from 'dayjs/plugin/relativeTime'
import styles from "./index.module.less"
import { useReward } from "react-rewards";

dayjs.extend(relativeTime)
dayjs.locale('zh-cn')

function ActionButton({children,activeNode,inactiveNode,active,hoverable=true,...rest}){
    return (
        <span
            {...rest}
            className={`${styles.action} ${hoverable?styles.hoverable:''}`}
        >
            { active ? activeNode : inactiveNode}
            {children}
        </span>
    )
}

/**
 * dig and favor
 * @returns {JSX.Element}
 * @constructor
 */
function ToggleButton({defaultActive,initCount=0,animation=false,isArticle,id,...props}){
    const [active, setActive] = useState(defaultActive);
    const [count, setCount] = useState(initCount);
    const [isLoading, setIsLoading] = useState(false);
    const { reward, isAnimating } = useReward(`animation_${id}`, "emoji", {
        zIndex: 999999999,
        position: "absolute",
        emoji: ["🥳", "🤪", "❤️", " 🎉", " 🎊"],
    });

    const handleDigToggle=()=>{
        if(!userSelf.isLogin) {
            Message.info('请登录后操作')
            return window.location.replace('/login')
        }
        if(isLoading) return
        setIsLoading(true)
        // 激活点赞才有动画
        if (animation&&!active) {
            reward();
        }
        setActive(status=>{
            setCount(count=>status?count-1:count+1)
            props.onClick?.(status)
                .then((data)=>{
                    // 撤销
                    if(!data){
                        setActive(status)
                        setCount(count=>status?count+1:count-1)
                    }
                })
                .finally(()=>{
                    setIsLoading(false)
                })
            return !status
        })
    }

    return (
        <ActionButton
            onClick={handleDigToggle}
            active={active}
            inactiveNode={props.inactiveNode}
            activeNode={props.activeNode}
            {...{id:animation?`animation_${id}`:undefined}}
        >
            {' '}{isArticle?'':count}
        </ActionButton>
    )
}

export default ({item,self,showFavorite=true,showDelete,showReply=true,showView=false,content,children,...props}) => {

    const {name,avatar}=item?.user||{}
    const {view=0,click=0}=item
    let dateTime=formatDate(item.createdAt)
    const isArticle=props.parentType==='article'

    const actions = [
        showDelete&&self&&<ActionButton
            key={'view'}
            onClick={props?.onDelete}
            inactiveNode={<IconDelete />}>
            {' '}删除
        </ActionButton>,
        // showView&&<ActionButton
        //     key={'view'}
        //     hoverable={false}
        //     inactiveNode={<IconEye />}
        //     activeNode={<IconEye />}>
        //     {' '}{view+click}
        // </ActionButton>,
        <ToggleButton
            key={'dig'}
            onClick={props?.onDig}
            defaultActive={item.digged}
            initCount={item.dig}
            animation
            isArticle={isArticle}
            id={item._id}
            inactiveNode={<IconThumbUp  />}
            activeNode={<IconThumbUpFill style={{color: '#ffb400'}}/>}
        />,
        // <ActionButton
        //     key={'dig'}
        //     onClick={handleDigToggle}
        //     active={digged}
        //     inactiveNode={<IconThumbUp  />}
        //     activeNode={<IconThumbUpFill style={{color: '#ffb400'}} />}>
        //     {' '}{digCount}
        // </ActionButton>,
        showFavorite&&<ToggleButton
            key={'star'}
            onClick={props?.onFavorite}
            defaultActive={item.favorited}
            initCount={item.favorite}
            isArticle={isArticle}
            inactiveNode={<IconStar  />}
            activeNode={<IconStarFill style={{color: '#ffb400'}} />}
        />,
        // showFavorite&&<ActionButton
        //     key={'star'}
        //     onClick={handleFavoriteToggle }
        //     active={favorited}
        //     inactiveNode={<IconStar  />}
        //     activeNode={<IconStarFill style={{color: '#ffb400'}} />}>
        //     {' '}{favoriteCount}
        // </ActionButton>,
        showReply&&<ActionButton
            key={'reply'}
            hoverable={!!props.onReply}
            onClick={props.onReply&&props.onReply }
            inactiveNode={<IconMessage  />}
            activeNode={<IconMessage style={{color: '#ffb400'}}/>}>
            {' '}{item.replyCount||0}{isArticle?' 评论':' 回复'}
        </ActionButton>,
    ];

    const certify=item.user?.certify||{}
    // 暂时隐藏专科
    const certifyList=[certify?.university,certify?.major,certify?.degree].filter(Boolean).filter(x=>x!=='专科')

    return (
        <div className={styles.commentWrap}>
            <div className={styles.feedback}><FeedBack id={item._id} objectType={props.parentType==='article'?'Post':'Comment'}/></div>
            <Comment
                actions={actions}
                align='right'
                author={props.author??
                    <a target={'_blank'} href={`/user/${item?.user?._id}`} rel="noreferrer">
                        {name}
                    </a>
                }
                avatar={props.avatar??<Avatar src={avatar} user={item?.user} key={item?.user?._id} {...props?.avatarProps}/>}
                content={content}
                datetime={
                    <Space split={<Divider type={'vertical'}/>}>
                        {dateTime}
                        {props.datetime}
                        { certifyList.length>0?certifyList.join('-'):item.ip?.province||null}
                        {props.status||null}
                    </Space>
                }
            >
                {children}
            </Comment>
            {
                props.parentType==='article'&&item?.source?(
                    <span className={styles.from}>来源 <Favicon url={item?.faviconUrl} source={item?.source}/></span>
                ):null
            }
        </div>
    );
};

import { Grid, Card, Divider} from '@arco-design/web-react';
import styles from "./index.module.less"
import { Link } from "react-router-dom";
import beiang  from "../../assets/images/bei.png";
import {PROTOCOL,MP_INTRODUCE_URL} from "../../utils/constants";

export default function ({state}){

    return (
        <Card
            className={styles.company}
            title='关于我们'
            bordered={false}
        >
            <div>
                <a target={'_blank'} href={'mailto:hr@imeikan.com'} rel="noreferrer"><span>加入我们：</span>hr@imeikan.com</a>
            </div>
            <div>
                <a target={'_blank'} href={'mailto:business@imeikan.com'} rel="noreferrer"><span>商务合作：</span>business@imeikan.com</a>
            </div>
            <div>
                <a target={'_blank'} href={'mailto:report@imeikan.com'} rel="noreferrer"><span>举报：</span>report@imeikan.com</a>
            </div>
            <div>
                <a target={'_blank'} href={'mailto:service@imeikan.com'} rel="noreferrer"><span >建议&咨询：</span>service@imeikan.com</a>
            </div>
            <div>
                <a target={'_blank'} href={MP_INTRODUCE_URL} rel="noreferrer">使用说明</a>
                <Divider type={'vertical'}/>
                <Link to={'/about'}>公司介绍</Link>
                <Divider type={'vertical'}/>
                <a target={'_blank'} href={PROTOCOL.REGISTRATION_AGREEMENT.url} rel="noreferrer">用户协议</a>
            </div>
            <div>
                <a target={'_blank'} href={'https://beian.miit.gov.cn/'} rel="noreferrer">京ICP备2022015664号</a>
            </div>
            <div>
                <a target={'_blank'} href={'https://tsm.miit.gov.cn/dxxzsp/'} rel="noreferrer">ICP许可证：京B2-20222634</a>
            </div>
            <div >
                <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802040028"  rel="noreferrer">
                <img src={beiang} style={{width:18,marginBottom:-4}}/>京公网安备 11010802040028号</a>
            </div>
            <div>
                <a>©2024 北京美刊科技有限公司</a>
            </div>
            <a>地址：北京市海淀区青云里满庭芳园小区9号楼青云当代大厦20层2006号</a>
        </Card>
    )
}

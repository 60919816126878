import { makeAutoObservable } from "mobx"
import { get,post } from "../utils/request"
import {userSelf} from '../store'

class Post {
    list=[]
    total=0
    activeTab=userSelf.isLogin?'subscription':'all'

    constructor() {
        makeAutoObservable(this)
    }

    async create(data){
        return await post('/api/post/create',data)
    }

    getList=async(query={})=>{
        const result =await get('/api/post/list',{limit:10,...query})
        if(result){
            this.list=result.list
            this.total=result.total
            return result
        }
        return false
    }
     getActionList=async(query={})=>{
        const result =await get('/api/post/action/list',{limit:10,...query})
        if(result){
            return result
        }
        return false
    }

    getActionPostList=async(query={})=>{
        const result =await get('/api/post/action/list/post',{limit:10,...query})
        if(result){
            return result
        }
        return false
    }

    async getPost(query={}){
        const result =await get('/api/post/get',query)
        if(result){
            return result
        }
        return false
    }

    async deletePost(_id){
        return await post('/api/post/delete',{_id})
    }

    async urlDeduplicate(url){
        return await post('/api/post/deduplicate',{url})
    }

    async digPost(payload,dig=true){
        const {_id,scene}=payload
        return await post(`/api/post/action/${dig?'create':'delete'}`,{post:_id,scene,type:'dig'})
    }

    async viewPost(_id,scene){
        // 登录用户才上报
        if(!userSelf.isLogin) return
        return await post(`/api/post/action/view`,{post:_id,scene})
    }

    async favoritePost(payload,create=true){
        const {_id,scene}=payload
        return await post(`/api/post/action/${create?'create':'delete'}`,{post:_id,scene,type:'favorite'})
    }

    async getOgInfo(url){
        // return await post(`/api/post/ogtitle`,{url})
        return await post(`/api/opengraph/get`,{url})
    }

    async clickSource(_id,scene){
        return await post(`/api/post/action/click`,{post:_id,scene})
    }

    async subscribe(id,action='subscribe'){
        const result =await post('/api/category/subscribe',{id,action})
        if(result){
            await userSelf.getUserInfo()
            return true
        }
        return false
    }
}
export default new Post()

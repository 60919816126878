import {
  Layout,
  Menu,
  Grid,
  Card,
  Avatar,
  Link,
  Typography,
  Spin,
  Skeleton,
  List,
} from "@arco-design/web-react";
import {
  ContainerTitle,
  CardList,
  HomeList,
  CompanyInfo,
  Content,
  QrCard,
} from "../../components";
import styles from "./index.module.less";
import {  homeList, userSelf } from "../../store";
import { category } from "../../store/List";
import { observer } from "mobx-react-lite";

const Row = Grid.Row;
const Col = Grid.Col;

export default observer(function () {

  return (
    <Content className={styles.home}>
      <ContainerTitle title={"精品专栏"} url={"/category"} />
      <CardList list={category.list} singleLine={true} />
      <Row gutter={24}>
        <Col span={18}>
          <ContainerTitle
            title={"精品内容"}
            url={"/posts?tab=all"}
            moreText={"全部"}
          />
          <HomeList />
        </Col>
        <Col span={6}>
          {/*<Leaderboard/>*/}
          {/*<QrCard/>*/}
          <CompanyInfo />
        </Col>
      </Row>
    </Content>
  );
});

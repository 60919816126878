import {Message, Menu, Empty, Card, Divider, Typography, Button, List, Modal} from '@arco-design/web-react';
import styles from "./index.module.less"
import {Author, Avatar, Helper} from "../index";
import {get, post} from "../../utils/request";
import {getQuery} from "../../utils";
import { useState,useEffect } from "react";
import {post as store,userSelf,follow} from "../../store";
import { observer } from "mobx-react-lite"
import {IconLink} from "@arco-design/web-react/icon";
import {useNavigate} from "react-router-dom";
import { events, EVENTS } from "../../utils/events";

export default observer(function ({user}){
    const [followed, setFollowed] = useState(user.followed);
    useEffect(() => {
        setFollowed(user.followed);
    }, [user]);

    const handleFollow = async () => {
        // if (!auth.isLogin) {
        //     return navigation.navigate(Screen.LOGIN);
        // }
        if (
            await post(`/api/follow/${followed ? 'delete' : 'create'}`, {
                followee: user._id,
            })
        ) {
            setFollowed(!followed);
            events.emit(EVENTS.FOLLOW_USER, {
                followee: user._id,
                activate: !followed,
            });


            // await follow.refresh({});
            Message.info(`${followed ? '取消' : ''}关注成功`);
        }
    };
    return followed ? (
        <Button
            onClick={() => handleFollow()}
            className={styles.follow}
            type={'secondary'}
            shape={'square'}>
            已关注
        </Button>
    ) : (
        <Button
            type={'primary'}
            shape={'square'}
            onClick={() => handleFollow()}
            className={styles.follow}>
            关注
        </Button>
    );
})

import {List, Radio, Spin} from "@arco-design/web-react";
import styles from "./index.module.less";
import { Article } from "../index";
import { userSelf, category, post, homeList,homeList2 } from "../../store";
import useTouchBottom from "../../hooks/useTouchBottom";
import { useEffect, useState,forwardRef,useRef,useImperativeHandle ,useCallback} from "react";
import { List as ListStore } from "../../store/List";
import { observer } from "mobx-react-lite";

export default observer(forwardRef(function ({
  showCategory,
  path = "/api/post/list",
  query = {},
  tab,
  method = "post",
  cursorKey="createdAt",
  renderItem,
  isSearchResult=false
},ref) {
  const [store] = useState(new ListStore(path,{
      method,
      cursorKey
  }));
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);

  const apiRef = useRef(null);

  // 刷新函数
  const refresh =async  (query) => {
      console.log('Refreshing...');
    setLoading(true);
    setList(await store.refresh(query))
    setLoading(false);
  };

  // 使用 useImperativeHandle 将刷新函数暴露给外部
  useImperativeHandle(ref, () => ({
      refresh,
  }));
  const loadmore = useCallback( async () => {
      console.log('query：',query)
    if(isSearchResult&&query.keyword==='')return
    setList(await store.loadmore(query))
  },[query]);
  useEffect(() => {
    loadmore().then(() => {
      setLoading(false);
    });
  }, []);

  const handleDeleteClick = (item) => {
    setList((list) => list.filter((o) => o.id !== item.id));
  };

  useTouchBottom(loadmore);

  const renderListItem=(item, index)=>{
      if(renderItem) return renderItem(item, index)
      return (
          <List.Item key={index}>
            <Article
                isSearchResult
                showCategory={showCategory}
                item={item}
                onDelete={handleDeleteClick.bind(this, item)}
                self={item?.user?._id === userSelf.id}
            />
          </List.Item>
      )
  }
  return (
    <List
      bordered={false}
      loading={loading}
      footer={store.loading && list.length > 0 &&!loading&&  <Spin />}
      className={`${styles.postList} ${!tab && styles.paddingTop}`}
    >
      {list.map(renderListItem)}
    </List>
  );
}));


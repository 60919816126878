import {
  List,
  Popconfirm,
  Message,
  Button,
  Divider,
} from "@arco-design/web-react";
import styles from "./index.module.less";

import { IconHeart, IconMessage, IconFile } from "@arco-design/web-react/icon";
import { user, category } from "../../../store";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";

export default observer(() => {
  return (
    <List
      className={styles.subscription}
      bordered={false}
      dataSource={category.list.filter((o) => Boolean(o.subscribed))}
      render={(item, index) => (
        <List.Item
          key={item.id}
          style={{
            padding: "20px 0",
            borderBottom: "1px solid var(--color-fill-3)",
          }}
          actions={[
            <Popconfirm
              title="确认取消订阅吗?"
              key={'unsubscribe'}
              onOk={async () => {
                if (await category.subscribe(item._id, "unsubscribe")) {
                  Message.success("取消成功！");
                }
              }}
            >
              <Button>取消订阅</Button>
            </Popconfirm>,
          ]}
          extra={
            <Link
              to={`/posts?title=${encodeURIComponent(item.title)}&category=${
                item._id
              }`}
            >
              <img className={styles.cover} src={item.image} />
            </Link>
          }
        >
          <Link
            to={`/posts?title=${encodeURIComponent(item.title)}&category=${
              item._id
            }`}
          >
            <List.Item.Meta
              title={<div className={styles.title}>{item.title}</div>}
              description={
                <span key={1}>
                  <IconFile />
                  {""}共 {item.posts} 篇帖子
                </span>
              }
            />
          </Link>
        </List.Item>
      )}
    />
  );
});

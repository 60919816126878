import React, { useRef,useState,useImperativeHandle } from "react";
import { Upload, Message, Progress, Modal } from "@arco-design/web-react";
import { IconPlus, IconEdit } from "@arco-design/web-react/icon";

export default React.forwardRef(function (props,ref) {
    const {process,onChange,...rest}=props
    const uploadListRef = useRef([]);

    useImperativeHandle(ref, () => ({
        getStatus: ()=>{
            let curUploadList=uploadListRef.current
            for(let i=0;i<curUploadList.length;i++){
                let item=curUploadList[i]
                if(item.status==='init'||item.status==='uploading') return 'uploading'
                if(item.status==='error') return 'error'
            }
            return 'success'
        },
    }));
    function getImages(uploadList){
        return uploadList.map(item=>item.response.data)
    }
    return (
        <Upload
            action={`/api/file/upload?process=${process||''}`}
            // fileList={fileList}
            tip={"请上传10M以内的png、jpg图片"}
            accept={".png,.jpeg,.jpg"}
            limit={4}
            listType='picture-card'
            onChange={(uploadList, currentFile) => {
                const { status } = currentFile;
                let images=[],allSuccess=false
                uploadListRef.current=uploadList
                switch (status) {
                    case "init":
                        console.log('init',)
                        break
                    case "uploading":
                        break;
                    case "error":
                        Message.error("上传失败，请重试!");
                        break;
                    case "done":
                        Message.success("上传成功!");
                        images=getImages(uploadList)
                        allSuccess=images.filter(Boolean).length===uploadList.length
                        onChange?.(images,allSuccess);
                        break;
                }
            }}
            {...rest}
        >
        </Upload>
    );
})

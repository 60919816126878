import {Tabs, Radio, Empty, Card, Divider} from '@arco-design/web-react';
import styles from "./index.module.less"
import {Author,Helper} from "../index";
import {get} from "../../utils/request";
import {getQuery} from "../../utils";
import List from "./List";
import { useState,useEffect } from "react";
import dayjs  from "dayjs";

const options = [
    {
        value: 0,
        label: '本月',
    },
    {
        value: 1,
        label: '上月',
    }
];
export default function (){
    const [tab,setTab]=useState(0)
    const query=getQuery()

    //${dayjs().format('M月')}
    return (
        <Card
            className={styles.leaderboard}
            title={`${query.title}推荐者榜单`}
            extra={(
                <Helper position='tr'>
                    <div>榜单排名根据每个自然月发文的点赞、收藏、评论数和阅读量计算（榜单统计有延迟，以实际展示榜单为主）</div>
                </Helper>
            )}
            bordered={false}>
            <Radio.Group
                type='button'
                name='direction'
                size='mini'
                defaultValue={0}
                onChange={(v)=>setTab(v)}
                options={options}
            ></Radio.Group>
            {
                tab===0?
                    <List key={'current'}/>
                    :<List key={'last'} last={tab}/>
            }
        </Card>
    )
}

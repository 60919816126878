import { Tag, Space, Image, Modal, Message } from "@arco-design/web-react";
import { BaseComment, LinkCard } from "../index";
import styles from "./index.module.less";
import { Link, useNavigate } from "react-router-dom";
import { post, userSelf } from "../../store";
import { observer } from "mobx-react-lite";
import { useInView } from "react-intersection-observer";

export default observer(({ item, showCategory, self, showDelete, showReply = true,isSearchResult=false, ...props }) => {
  const navigate = useNavigate();
  const isDetailPage = window.location.pathname === "/post";
  const scene = isDetailPage ? "detail" : "feed";
  const showView = window.location.pathname === "/user";

  const {
    ref: observerRef,
    inView,
    entry
  } = useInView({
    /* Optional options */
    threshold: 0,
    triggerOnce: true,
    onChange: (inView, entry) => {
      if (inView && item._id) {
        post.viewPost(item._id, scene);
      }
    }
  });

  const handleDeleteClick = item => {
    Modal.confirm({
      title: "确认删除本条内容吗？",
      okButtonProps: {
        status: "danger"
      },
      onOk: async () => {
        if (await post.deletePost(item._id)) {
          Message.success("删除成功！");
          props.onDelete?.();
        }
      }
    });
  };

  const handleDigClick = async (item, curStatus) => {
    return await post.digPost({ _id: item._id, scene }, !curStatus);
  };
  const handleFavoriteClick = async (item, curStatus) => {
    return await post.favoritePost({ _id: item._id, scene }, !curStatus);
  };
  const handleImageClick = event => {
    console.log(event.target);
    event.stopPropagation();
  };

  const handleReplyClick = item => {
    // navigate(`/post?id=${item._id}`)
    if (window.location.pathname !== "/post") {
      window.open(`/post?id=${item._id}`);
    }
  };

  const handleContentClick = event => {
    let targetNode = event.target;
    while (targetNode !== null) {
      if (targetNode?.classList?.contains("arco-image-preview-wrapper")) {
        return;
      }
      targetNode = targetNode.parentNode;
    }
    if (isDetailPage) return;
    window.open(`/post?id=${item._id}`);
  };

  const getContentEle = () => {
    if (!item.content) return null;
    if (isDetailPage) return item.content;
    if(isSearchResult){
      return (
          <div className={styles.ellipsis}>
            {item.contentHighlightedList.map((o,i)=>(
                <span key={i} className={o.highlight?'highlight':''}>{o.text}</span>
            ))}
          </div>
      );
    }
    return <div className={styles.ellipsis}>{item.content}</div>;
  };

  return (
    <BaseComment
      item={item}
      showDelete={showDelete}
      showView={showView}
      self={self}
      onReply={handleReplyClick.bind(this, item)}
      onDelete={handleDeleteClick.bind(this, item)}
      onDig={handleDigClick.bind(this, item)}
      onFavorite={handleFavoriteClick.bind(this, item)}
      parentType={"article"}
      datetime={
        showCategory && (
          <>
            {/*<Link to={`/posts?category=${item?.category?._id}&title=${encodeURIComponent(item?.category?.title)}`}>{item?.category?.title}</Link>*/}
            <a
              target={"_blank"}
              href={`/posts?category=${item?.category?._id}&title=${encodeURIComponent(
                item?.category?.title
              )}`}
              rel="noreferrer"
            >
              {item?.category?.title}
            </a>
          </>
        )
      }
      status={
       item.user?._id===userSelf._id? (item?.status === -1 ? (
           <Tag color={"gray"} size={"small"}>
             不符合社区规范
           </Tag>
       ) : ["block"].includes(item?.audit?.status) ? (
           <Tag color={"gray"} size={"small"}>
             不符合社区规范
           </Tag>
       ) : null):null
      }
      content={
        <div
          ref={observerRef}
          className={`${styles.content} ${isDetailPage ? "" : styles.inFeed}`}
          onClick={handleContentClick}
        >
          <div className={styles.desc}>{getContentEle()}</div>
          {item.postType === 1 ? (
            <LinkCard isSearchResult={isSearchResult} text={item.title} url={item.source} post={item} scene={scene} />
          ) : (
            <Image.PreviewGroup infinite>
              {item.images?.map((src, index) => (
                <Image
                  key={index}
                  src={src}
                  width={100}
                  height={100}
                  style={{ marginRight: 10 }}
                  onClick={handleImageClick}
                />
              ))}
            </Image.PreviewGroup>
          )}
        </div>
      }
    ></BaseComment>
  );
});

import {Layout, Menu, Grid, Modal, Button, Typography} from '@arco-design/web-react';
import styles from "./index.module.less"
import {Content,PayList} from "../../components"
import {list} from "../PayNews/data"
import {useState} from "react"
import {getQuery} from "../../utils"
const { Title, Paragraph, Text } = Typography;

const MenuItem = Menu.Item;
const SubMenu = Menu.SubMenu;
const Row = Grid.Row;
const Col = Grid.Col;

export default function (){
    const query=getQuery()
    const {title,description}=list[query.id>>0]
    const [visible, setVisible] = useState(false);

    return (
        <Content className={styles.about}>
            <Row gutter={24} align={'center'}>
                <Col span={24} className={styles.content}>
                    <Title>{title}</Title>
                    <div>
                        添加时间：2022-06-09　 浏览次数：10次
                    </div>
                    <br/>
                    <Paragraph>
                        {description}
                    </Paragraph>
                    <br/>
                    <br/>
                    <br/>
                    <Button type={'primary'} onClick={() => setVisible(true)}>点我付费2元阅读全文</Button>
                </Col>
            </Row>
            <Modal
                title='付费阅读'
                visible={visible}
                onOk={() => setVisible(false)}
                onCancel={() => setVisible(false)}
                autoFocus={false}
                focusLock={true}
            >
                <Row gutter={24} align={'center'}>
                    <Col span={12} className={styles.s}>
                        <img width={'200px'} src="https://images.redream.cn/upic/2019/20220611182354-dTGQ62.jpg" alt=""/>
                    </Col>
                    <Col span={12} className={styles.s}>
                        <img width={'200px'} src="https://images.redream.cn/upic/2019/20220611182445-25S4ri.jpg" alt=""/>
                    </Col>
                </Row>
            </Modal>
        </Content>
    )
}

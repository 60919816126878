import { Button, Card, Modal, Popover,Space } from "@arco-design/web-react";
import styles from "./index.module.less";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import {
  IconBulb,
  IconFile,
  IconUserGroup,
  IconThumbUp,
  IconQuestionCircle
} from "@arco-design/web-react/icon";
import { observer } from "mobx-react-lite";
import { userSelf, follow, post } from "../../store";
import FeedBack from "../FeedBack";

export default observer(function ({ user }) {
  const [isFollowed, setIsFollowed] = useState(user.followed);
  let { userId } = useParams();

  const isSelf = !userId || userId === userSelf.id;
  useEffect(() => {
    setIsFollowed(user.followed);
  }, [user.followed]);
  const handleFollow = async () => {
    try {
      if(await follow.follow(user.id)){
        setIsFollowed(true);
      }
    }catch (e){
      console.log(e)
    }
  };
  const handleUnfollow = () => {
    Modal.confirm({
      title: "是否确认取消关注？",
      onOk: async () => {
        await follow.unfollow(user.id);
        setIsFollowed(false);
      }
    });
  };
  const handleMessage = () => {
    // navigate(`/chat?to=${userId}`,{ replace: true });
    window.open(`/chat?from=${userSelf.id}&to=${userId}`)
  }
  return (
    <Card
      className={styles.achievement}
      title={
        <div className={styles.achievement_title}>
          <div>个人成就</div> {!isSelf && <FeedBack id={userId} objectType={`User`} />}
        </div>
      }
      bordered={false}
    >
      <div className={styles.line}>
        <IconThumbUp className={styles.icon} />
        获赞数 {user.totalDig}
      </div>
      <div className={styles.line}>
        <IconFile className={styles.icon} />
        帖子数 {user.posts}
      </div>
      <div className={styles.line}>
        <IconUserGroup className={styles.icon} />
        粉丝数 {user.followers}
      </div>
      {userSelf.isSelf(user.id) ? (
        <div className={styles.line}>
          <IconBulb className={styles.icon} />
          积分数 {user.points}
          <a
            href={"https://mp.weixin.qq.com/s/URpOaUHvITHi4_V5vGopqQ"}
            rel="noreferrer"
            target={"_blank"}
          >
            <IconQuestionCircle fontSize={18} className={styles.helper} />
          </a>
          {/*<Popover*/}
          {/*    title="请添加客服微信服务号兑换积分"*/}
          {/*    content={*/}
          {/*      <img*/}
          {/*          width={220}*/}
          {/*          src="https://meikan-public-images.oss-cn-beijing.aliyuncs.com/imeikan/images/63594fafd750097ad12e8f84.jpeg"*/}
          {/*          alt=""*/}
          {/*      />*/}
          {/*    }*/}
          {/*>*/}
          {/*  <Button size={"mini"} style={{ marginLeft: "auto" }} type={"primary"}>*/}
          {/*    兑换*/}
          {/*  </Button>*/}
          {/*</Popover>*/}
          <Button size={"mini"} style={{ marginLeft: "auto" }} type={"primary"} target={'_blank'} href={'https://imeikan.com/protocol/withdraw.html'}>
            兑换
          </Button>

        </div>
      ) : null}
      <div className={styles.btnGroup}>
        {/*不是自己，且未注销*/}
        {user.id === userSelf.id||user.deletedAt ? null : (
            <>
              <Button className={styles.btn} onClick={handleMessage}>
                私信
              </Button>
              {
                isFollowed ? (
                    <Button className={styles.btn} onClick={handleUnfollow}>
                      取消关注
                    </Button>
                ) : (
                    <Button type={"primary"} className={styles.btn} onClick={handleFollow}>
                      关注
                    </Button>
                )
              }
            </>
        )}
      </div>
    </Card>
  );
});

import {Layout, Menu, Grid, Link, Button, Divider} from '@arco-design/web-react';
import styles from "./index.module.less"
import React from 'react'

import {IconHeart, IconHeartFill, IconMessage, IconStar, IconStarFill} from "@arco-design/web-react/icon";
const Footer = Layout.Footer;
const MenuItem = Menu.Item;
const SubMenu = Menu.SubMenu;
const Row = Grid.Row;
const Col = Grid.Col;


// function actions(item){
//     const [like, setLike] = React.useState(true);
//     const [star, setStar] = React.useState(true);
//
//     return [
//         <span className={styles.action} key={1}>
//           <IconHeart />
//             {83}
//         </span>,
//         <span className={styles.action} key={2}>
//            <IconStar />
//             {item.index}
//         </span>,
//         <span className={styles.action} key={3}>
//             <IconMessage />
//             Reply
//         </span>
//     ]
// }

export default function (){
    const [like, setLike] = React.useState(true);
    const [star, setStar] = React.useState(true);
    const actions = [
        <span
            className='custom-comment-action'
            key='heart'
            onClick={() => setLike(!like) }
        >
      { like ? <IconHeartFill style={{color: '#f53f3f'}} /> : <IconHeart  />}
            {' '}{83 + (like ? 1 : 0)}
    </span>,
        <span
            className='custom-comment-action'
            key='star'
            onClick={() => setStar(!star) }
        >
      {star ? <IconStarFill style={{color: '#ffb400'}} /> : <IconStar  />}
            {' '}{3 + (star ? 1 : 0)}
    </span>,
        <span className='custom-comment-action' key='reply'><IconMessage /> Reply</span>,
    ];
    return (
        <div className={styles.actionBar}>
            {actions}
        </div>
    )
}

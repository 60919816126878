import { Button, Comment,Input,Modal,Message} from '@arco-design/web-react';
import {useState} from 'react'
import {BaseComment} from '../index'
import styles from "./index.module.less"
import {Link} from "react-router-dom";
import * as request from "../../utils/request";
import {post} from "../../utils/request";
import {userSelf} from "../../store";
import { observer } from "mobx-react-lite"

// parent 是父级评论
const CommentItem= observer(({parent,item,self,showDelete,showReply=true,refresh,store,...props}) => {
    const [value, setValue] = useState();
    const handleReplyClick=()=>{
        store.activeCommentId=item._id
    }
    const handleCancelClick=()=>{
        store.activeCommentId=''
    }
    const handleReplyChange=(value)=>{
        setValue(value)
    }
    const handleReplySubmit=async ()=>{
        if(!value) return Message.info('请先填写回复内容！')

        store.activeCommentId=''
        const result=await request.post('/api/comment/create',{
            user:userSelf.id,
            content:value,
            post:item.post,
            reply:item._id,
            parent:parent?._id || item._id // 有parent 的是二级评论的回复，没有的是一级评论的回复
        })
        if(result){
            setValue('')
            refresh?.()
            Message.success('发表成功！')
        }
    }

    const handleDeleteClick=(item)=>{
        Modal.confirm({
            title: '确认删除本条内容吗？',
            okButtonProps: {
                status: 'danger',
            },
            onOk: async () => {
                (await post('/api/comment/delete',{
                    _id:item._id
                }))&&Message.success('删除成功！')
                refresh()
            },
        });
    }

    const handleDigClick=async (item,curStatus)=>{
        return request.post(`/api/comment/action/${!curStatus?'create':'delete'}`,{comment:item._id,type:'dig'})
    }
    // const handleFavoriteClick=async (item,curStatus)=>{
    //     await request.post(`/api/comment/action/${curStatus?'create':'delete'}`,{post:item._id,type:'favorite'})
    // }
    return (
            <div className={styles.comment}>
                <BaseComment
                    item={item}
                    showFavorite={false}
                    avatarProps={{size:34}}
                    self={self}
                    showDelete
                    author={
                        item.reply?(
                            <span className={styles.reply}>
                            <Link to={`/user/${item?.user?._id}`}>
                                {item.user?.name}
                            </Link>
                                {' '}回复 @
                            <Link to={`/user/${item?.reply?.user?._id}`}>
                                {item.reply?.user?.name}
                            </Link>
                        </span>
                        ):undefined
                    }
                    onDelete={handleDeleteClick.bind(this,item)}
                    onReply={handleReplyClick.bind(this,item)}
                    onDig={handleDigClick.bind(this,item)}
                    content={item.content}
                >
                    {
                        store.activeCommentId===item._id&&(
                            <Comment
                                align='right'
                                actions={[
                                    <Button key='0' type='secondary' onClick={handleCancelClick}>取消</Button>,
                                    <Button key='1' type='primary' onClick={handleReplySubmit}>回复</Button>,
                                ]}
                                content={
                                <div style={{marginTop:'10px'}}>
                                    <Input.TextArea
                                        value={value}
                                        maxLength={300}
                                        showWordLimit
                                        onChange={handleReplyChange}
                                        placeholder={`回复 ${item.user?.name}`} />
                                    <div className={'arco-form-extra'}>内容发布者观点及评论仅供其表达个人看法或观点，并不表明美刊立场。</div>
                                </div>}
                            />
                        )
                    }
                    {
                        item.comments?.map((reply)=>(
                            <CommentItem key={reply._id} refresh={refresh} parent={item} item={reply} store={store} self={reply.user._id===userSelf.id} showDelete/>
                        ))
                    }
                </BaseComment>
            </div>
    );
});

export default CommentItem

import { useState } from "react";
import { Message, Card } from "@arco-design/web-react";
import styles from "./index.module.less";
import {CheckCodeModal} from "../../../components";
import {userSelf} from "../../../store"

export default function Delete() {
  const handleUserDelete = async (formData) => {
    const {checkCode} = formData
    if(!checkCode){
        Message.error("请输入验证码")
        return
    }
    await userSelf.deleteUser(checkCode)
  }
  return (
    <Card title={"用户注销"} className={styles.account_delete}>
      <div className={styles.container}>
        <div className={styles.info}>
          <div>
            <span className={styles.h1}>
              在你申请注销美刊帐号之前，请你认真阅读、理解并同意《美刊帐号注销须知》（以下称“注销须知”）。【特别提示】在此善意提醒你，注销美刊帐号为不可恢复的操作，注销帐号后你将无法再使用该美刊帐号或找回账号的个人资料和历史信息
              <span className={styles.inline_strong_font}>
                （即使你使用相同的手机号码或之前绑定过的其他账号体系再次注册并登陆美刊网）。
              </span>
            </span>
          </div>
          <div>
            <span className={styles.h2}>
              建议你在注销前自行备份美刊帐号相关的所有信息，并请确认与该美刊帐号相关的所有服务均已进行妥善处理。注销成功后，我们将删除你的个人信息，或对其进行匿名化处理。
            </span>
          </div>
          <div>
            <span className={styles.font_strong}>
              1.我们对你注销美刊帐号的决定深表遗憾。如果你仍执意注销帐号，你的帐号需同时满足以下条件：
            </span>
            <span className={styles.h3}>
              1.1帐号处于安全状态：帐号处于正常使用状态，无被盗风险。
            </span>
            <span className={styles.h3}>1.2帐号无任何纠纷，包括投诉举报或被投诉举报。</span>
          </div>
          <div>
            <span className={styles.font_strong}>
              2.在你的帐号注销期间，如果你的帐号涉及争议纠纷，包括但不限于：
            </span>
            <span className={styles.h3}>
              2.1投诉、举报、诉讼、仲裁、国家有权机关调查等，你知晓并理解，美刊有权自行决定是否终止本帐号的注销而无需另行得到你的同意。
            </span>
          </div>
          <div>
            <span className={styles.font_strong}>
              3.注销美刊帐号，你将无法再使用该美刊帐号，也将无法找回该美刊帐号中及与帐号相关的任何内容或信息，包括但不限于：
            </span>
            <span className={styles.h3}>
              3.1该美刊帐号的个人资料和历史信息（包括但不限于头像、昵称、加入/创建的美刊专题、订阅、点赞、收藏、发布的推文&动态、评论、积分权益等一切账号关联的信息及数据）都将无法找回；
            </span>
            <span className={styles.h3}>3.2你将无法登录、使用该美刊帐号；</span>
          </div>
          <div>
            <span className={styles.font_strong}>
              4.注销该美刊帐号并不代表注销前的帐号中的行为和相关责任得到豁免或减轻。
            </span>
          </div>
        </div>
        <div className={styles.button_container}>
          <CheckCodeModal
              okText="确认注销"
              buttonText={"申请注销"}
              title={"确认注销后无法找回，确保您已阅读完注销说明"}
              smsType={"userDelete"}
              onOk={handleUserDelete}
          />
        </div>
      </div>
    </Card>
  );
}

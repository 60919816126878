import {Typography, Grid, Tabs, Message, Button, Popover, Tag, Alert} from "@arco-design/web-react";
import styles from "./index.module.less";
import { Content, Honor, PostList, Avatar } from "../../components";
import { useState, useEffect } from "react";
import Subscription from "./Subscription";
import Follow from "./Follow";
import { userSelf, post } from "../../store";
import User from "../../store/user";
import { observer } from "mobx-react-lite";
import TabTitle from "../../components/TabTitle";
import { useParams, Link } from "react-router-dom";
import { IconQuestionCircle } from "@arco-design/web-react/icon";
import vv from "../../assets/images/v.svg";
import dayjs from "dayjs";
const Row = Grid.Row;
const Col = Grid.Col;
const TabPane = Tabs.TabPane;
const userOther = new User();
export default observer(function () {
  let { userId } = useParams();
  // const [activeTab, setActiveTab] = useState('1');
  post.activeTab = "mypost";
  const isSelf = !userId || userId === userSelf.id;
  const user = isSelf ? userSelf : userOther;
  useEffect(() => {
    userId && user.getUserInfo(userId);
    // setActiveTab('1')
    post.activeTab = "mypost";
  }, [userId]);

  const handleChangeIntroduce = text => {
    if (text.length > 240) {
      return Message.info("个人简介最多240字。");
    }
    userSelf.introduce = text;
  };
  const handleTabChange = key => {
    post.activeTab = key;
  };
  const getTabPane = () => {
    if (!user.id) return null;
    return [
      <TabPane key="mypost" title={<TabTitle type={"post"} />}>
        <PostList
          key={userId}
          showCategory
          query={{ user: user.id }}
          tab={"mypost"}
          path={"/api/post/userlist"}
        />
      </TabPane>,
      <TabPane key="2" title={<TabTitle type={"subscribe"} />}>
        <Subscription key={userId} />
      </TabPane>,
      <TabPane key="22" title={<TabTitle type={"collect"} />}>
        <PostList
          key={userId}
          tab={"22"}
          showCategory
          query={{ user: user.id, type: "favorite" }}
          path={"/api/post/action/list/post"}
        />
      </TabPane>,
      <TabPane key="dig" title={<TabTitle type={"dig"} />}>
        <PostList
          key={userId}
          showCategory
          tab={"dig"}
          query={{ user: user.id, type: "dig" }}
          path={"/api/post/action/list/post"}
        />
      </TabPane>,
      // <TabPane key='21' title='点赞'>
      //     <PostList/>
      // </TabPane>,
      <TabPane key="3" title={<TabTitle type={"followee"} />}>
        <Follow key={userId} type={"followee"} />
      </TabPane>,
      <TabPane key="4" title={<TabTitle type={"follower"} />}>
        <Follow key={userId} type={"follower"} />
      </TabPane>
    ].slice(0, !isSelf ? 1 : undefined);
  };
  const isBan =
      user.ban && user.ban.banType && user.ban.banUntil&& dayjs().isBefore(dayjs(user.ban.banUntil));
  return (
    <Content className={styles.user}>
      {isBan ? (
          <Alert
              banner={true}
              className={styles.banner}
              type={'warning'}
              content={`因违反社区规则，${isSelf ? '本' : '该'}账号目前处于禁言状态`} />
      ) : null}

      <div className={styles.header}>
        <div className={styles.left}>
          <Avatar className={styles.avatar} src={user.avatar} size={80} />
          <div className={styles.right}>
            <div className={styles.username}>{user.name}</div>
            <Typography.Paragraph
              className={styles.introduce}
              // editable={
              //   isSelf
              //     ? {
              //         onChange: handleChangeIntroduce,
              //         onEnd(introduce) {
              //           userSelf.updateUserInfo({ introduce });
              //         }
              //       }
              //     : false
              // }
            >
              {user.introduce}
            </Typography.Paragraph>
            <div className={styles.info}>
              {user?.isCertified ? (
                  <div className={styles.certified}>
                    <img src={vv} className={styles.vv}></img>
                    {[user.university, user.major, user.degree].filter(Boolean).map(x => (
                        <span className={styles.tag} key={x}>
                          {x}
                        </span>
                    ))}
                    <div className={styles.weight}>
                      {isSelf && user?.certifiedCategory
                          ? `| ${user?.certifiedCategory}专栏积分权重${user?.weight}`
                          : ""}
                    </div>
                  </div>
              ) : isSelf ? (
                  <div className={styles.uncertified}>
                    学校/专业/学位: 未认证
                    <Popover
                        content={
                          <div style={{ display: "flex", flexDirection: "column" }}>
                            <div>完成认证后：</div>
                            <div>
                              1.在专业对应的专栏榜单计算时会增加计算权重（专科/本科*1.1；硕士*1.2；博士*1.3）；
                            </div>
                            <div>
                              2.认证后增加发布内容的公信度，有助于提高阅读量，提升榜单排名，及粉丝的关注量；
                            </div>
                            <div>3.提高发布内容审核通过率，快来认证吧～</div>
                            <Link to={"/setting/certify"} style={{ alignSelf: "flex-end" }}>
                              <Button type={"primary"} size={"mini"}>
                                去认证
                              </Button>
                            </Link>
                          </div>
                        }
                    >
                      <IconQuestionCircle fontSize={18} className={styles.helper} />
                    </Popover>
                  </div>
              ) : null}
              {
                <span className={styles.tag}>
                  IP属地: {user?.ip?.province||'未知'}
                </span>
              }
            </div>
          </div>
        </div>
      </div>
      <Row gutter={24}>
        <Col span={18} className={styles.main}>
          {user.id && (
            <Tabs key={userId} onChange={handleTabChange} size={"large"}>
              {getTabPane()}
            </Tabs>
          )}
        </Col>
        <Col span={6}>
          <Honor user={user} />
        </Col>
      </Row>
    </Content>
  );
});

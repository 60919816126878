import dayjs from "dayjs";
import * as Bowser from "bowser";
export const browser = Bowser.getParser(window.navigator.userAgent);

const queryString = require('query-string');
export function getQuery(){
    return queryString.parse(window.location.search);
}

/**
 * 驼峰转下划线
 * @param str
 * @returns {string}
 */
export function underline(str){
    return str.replace(/\B([A-Z])/g, '_$1').toLowerCase()
}

/**
 * 格式化时间为：1天前，几小时前
 * @param time
 * @returns {string}
 */
export function formatDate(time){
    let dateTime=dayjs(time).format('YYYY-MM-DD HH:mm')
    if(Date.now()-new Date(time)<3600*1000*6){
        dateTime=dayjs(time).fromNow()
    }
    return dateTime
}

/**
 * 显示简化时间，今年的时间不显示年份
 * @param time
 */
export function simpleDate(time) {
    // 获取当前时间
    const now = dayjs(time);
    // 获取年份
    const year = now.year();
    // 获取月份和日期
    let monthDate = now.format("M月D日");
    // 如果是今年之前的时间，添加年份
    if (now.isBefore(dayjs(`${year}-01-01`))) {
        monthDate = `${year}年${monthDate}`;
    }
    // 获取小时和分钟
    const hourMinute = now.format("HH:mm");
    return `${monthDate} ${hourMinute}`;
}


export function isWechat() {
    return browser.getBrowserName() === "WeChat";
}

export function extractFirstUrl(text) {
    const regex = /((?:https?|ftp):\/\/[\w-]+(?:\.[\w-]+)+(?:[\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?)/;
    const match = regex.exec(text);

    if (match) {
        return match[0];
    } else {
        return '';
    }
}



import { makeAutoObservable } from "mobx"
import { get,post } from "../utils/request"
import {Message} from '@arco-design/web-react';


class Follow {

    constructor() {
        makeAutoObservable(this)
    }

    async getList(query){
        return await get('/api/follow/list',query)
    }

    async follow(userId){
        const result=await post('/api/follow/create',{followee:userId})
        if(result){
            Message.success('关注成功')
        }
        return result
    }
    async unfollow(userId){
        (await post('/api/follow/delete',{followee:userId}))
        &&Message.success('取消成功')
    }

}

export default new Follow()

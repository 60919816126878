import { List, Typography, Spin, Comment, Badge } from "@arco-design/web-react";
import styles from "./index.module.less";
import { List as ListStore } from "../../../store/List";
import { notify, userSelf } from "../../../store";
import { Avatar } from "../../../components";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { formatDate } from "../../../utils";
import { Link } from "react-router-dom";
import {IconLink} from '@arco-design/web-react/icon';

export default observer(props => {
  const { query = {}, hide = false, title } = props;
  const [store] = useState(new ListStore("/api/notify/list"));
  const [list, setList] = useState([]);
  const loadmore = async () => {
    const data = await store.loadmore({
      toUser: userSelf.id,
      ...query
    });
    setList(data);
    return data;
  };

  useEffect(() => {
    userSelf.id &&
      loadmore().then(data => {
        if (!data.length) return;
        notify.readNotify(data[0]);
      });
  }, [userSelf.id]);

  const getJumpUrl = item => {
    const { object, objectType, action } = item;
    if (objectType === "Post" || objectType === "Comment") {
      const postId = objectType === "Post" ? object?._id : object?.post;
      return `/post?id=${postId}`;
    } else if (objectType === "User") {
      return `/user`;
    } else {
      return "";
    }
  };

  return (
    <List
      className={styles.list}
      style={{ maxHeight: "100%", height: "100%" }}
      wrapperClassName={styles.listWrapper}
      wrapperStyle={{ height: hide ? "0" : undefined }}
      bordered={false}
      dataSource={list}
      scrollLoading={store.loading && <Spin loading={true} />}
      onReachBottom={loadmore}
      render={item => (
        <List.Item key={item._id} className={styles.listItem}>
          <Comment
            align="right"
            datetime={formatDate(item.createdAt)}
            avatar={
              <Avatar
                src={item.fromUser?.avatar}
                user={item.fromUser}
                key={item.type !== "system" && item.fromUser?._id}
              />
            }
            author={
              <Badge dot count={item.status === 0 ? 1 : 0} offset={[5, 0]}>
                {item.type === "system" ? (
                  item.fromUser?.name
                ) : (
                  <Link to={`/user/${item.fromUser?._id}`}>{item.fromUser?.name} </Link>
                )}
                {typeof title === "function" ? title(item) : title}
              </Badge>
            }
            content={
              <div>
                {item.message && (
                    <div className={styles.messageText}>
                      {item.message}
                      {item.type==='system'&&item.objectType !== 'User' && ['block','offline'].includes(item.action)?<a target={'_blank'} rel="noreferrer" href={'./rules'}><IconLink style={{marginLeft:'5px'}} /> 社区规范</a>:null}
                    </div>
                )}

                <Link to={getJumpUrl(item)}>
                  <Typography.Text ellipsis>{item.object?.content||item.object?.title}</Typography.Text>
                </Link>
              </div>
            }
          />
        </List.Item>
      )}
    />
  );
});

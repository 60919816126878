import { Alert } from "@arco-design/web-react";
import styles from "./index.module.less";

export const myComponents = [
  <Alert banner={true} className={styles.banner} key="pending" content="您的认证正在审核中。" />,
  <Alert
    banner={true}
    className={styles.banner}
    key="sucess"
    type={"success"}
    content="您已成功认证。"
  />,
  <Alert
    banner={true}
    className={styles.banner}
    key="fail"
    type={"error"}
    content="您的认证失败。"
  />
];
export const evidenceOptions = [
  { value: "studentCard", label: "学生证" },
  { value: "diploma", label: "毕业证" },
  { value: "certificate", label: "学籍报告" }
];
export const evidenceStatus = {
  studentCard: "学生证",
  diploma: "毕业证",
  certificate: "学籍报告"
};
export const certifyInfoStatus  = [
  { value: "major", label: "专业" },
  { value: "degree", label: "学历" },
  { value: "university", label: "学校" }
];
import { makeAutoObservable, runInAction } from "mobx";
import { conversation as store } from "../store/List";
import ChatStore from "../store/chat";
import { IConversation } from "../types";
import userSelf from "../store/userSelf";
import { io, Socket } from "socket.io-client";
import { EVENTS, events } from "../utils/events";
import { post } from "../utils/request";

// @ts-ignore
window.store = store;
class Conversation {
  socket: Socket;
  constructor(socket: Socket) {
    makeAutoObservable(this);
    this.socket = socket;
    this.socket.on("user:conversation:unreadCountChange", this.onConversationUnreadCountChange);
    this.socket.on("user:all:unreadCountChange", this.onAllUnreadCountChange);
    // this.socket.on("user:conversation:listChange", this.onConversationListChange);
    this.socket.on("user:conversation:settingChange", this.onConversationSettingChange);
    this.socket.on("user:conversation:delete", this.onConversationDelete); // 其他设备删除

    events.on(EVENTS.CONVERSATION_PIN, this.onConversationPin);
    events.on(EVENTS.CONVERSATION_DELETE, this.onConversationDelete);  // 本机删除
    events.on(EVENTS.CONVERSATION_NEW, this.onConversationNew);
    this.getAllUnreadCount();
  }

  onConversationUnreadCountChange = ({ unreadMessageCount, conversation }: any) => {
    console.log("onConversationUnreadCountChange", unreadMessageCount, conversation);
    // 新的会话产生时，会同时插入会话列表
    store.upsertItem(conversation._id, conversation);
    this.sortConversationList();
  };

  onAllUnreadCountChange = ({ unreadMessageCount }: any) => {
    console.log("onConversationUnreadCountChange", unreadMessageCount);
    userSelf.allUnreadCount = unreadMessageCount;
  };

  onConversationListChange = (conversation: IConversation) => {
    console.log("onConversationListChange", conversation);
    store.upsertItem(conversation._id, conversation);
    this.sortConversationList();
  };

  onConversationSettingChange = (conversation: IConversation) => {
    console.log("onConversationSettingChange", conversation);
    store.upsertItem(conversation._id, conversation);
    this.sortConversationList();
  };

  getAllUnreadCount() {
    this.socket.emit("get:user:all:unreadCount", (res: any) => {
      runInAction(() => {
        userSelf.allUnreadCount = res;
      });
    });
  }

  sortConversationList = () => {
    // store.sortListBy((it: IConversation) => {
    //   return -new Date(it.lastMessageTime);
    // });

    store.sortListBy(["pinned", "lastMessageTime"], ["desc", "desc"]);
  };

  onConversationPin = async (payload: Record<string, any>) => {
    const { _id:conversationId, pinned } = payload;
    // const newConversation = await post("/api/conversation/pin", { _id, pinned });
    // store.upsertItem(newConversation._id, newConversation);
    // this.sortConversationList();
    store.upsertItem(conversationId, {pinned});
    this.socket.emit("user:conversation:settingChange", {conversationId,pinned}, (response: any) => {
      const { code, sendStatus, msg, data:newConversation } = response;
      if (code === 0) {
        store.upsertItem(newConversation._id,newConversation);
        this.sortConversationList();
      }
    });
  };

  onConversationDelete = async (conversation: Record<string, any>) => {
    const { _id, participants:[fromUser,toUser] } = conversation;
    store.removeItem(_id);
    ChatStore.removeChatInstance(fromUser._id, toUser._id);
  };

  onConversationNew=async (payload: Record<string, any>) => {
    const { conversation } = payload;
    store.upsertItem(conversation._id,conversation,true);
    this.sortConversationList();

  };
}

export default Conversation;

import { Menu, Grid, List, Button, Card, Badge,Upload,Message,Form,Input} from '@arco-design/web-react';
import styles from "./index.module.less"
import {userSelf,notify} from "../../store"
import {SelfAvatar} from "../index"
import {IconNotification, IconBulb} from "@arco-design/web-react/icon";
import { Link,useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite"
import Uploader from "../../pages/Setting/Uploader";
const FormItem = Form.Item;

export default observer(function ({onSuccess,init=false,...props}){
    const handleSubmit=async (formData)=>{
        console.log(formData)
        if(await userSelf.updateUserInfo(formData)){
            Message.success('提交成功，审核通过后自动更新。')
            onSuccess?.()
        }
    }
    const initialValues=init?{
        username:userSelf.modifyInfo?.username||userSelf.name,
        avatarUrl:userSelf.modifyInfo?.avatarUrl||userSelf.avatar,
        introduce:userSelf.modifyInfo?.introduce||userSelf.introduce,
    }:{}

    return (
        <Form
            key={userSelf.id}
            style={{ width: 400 }}
            autoComplete='off'
            onSubmit={handleSubmit}
            initialValues={initialValues}
        >
            <FormItem label='昵称' field={'username'} rules={[{ required: true, maxLength: 16 }]}>
                <Input placeholder='16个字符以内' />
            </FormItem>
            <FormItem label='头像' field={'avatarUrl'} rules={[{ required: true, }]}>
                <Uploader process={'image/resize,h_100,w_100'}/>
            </FormItem>
            {
                init?(
                    <FormItem label='简介' field={'introduce'} rules={[{ required: true, }]}>
                        <Input.TextArea rows={8} placeholder='200个字符以内' />
                    </FormItem>
                ):null
            }
            <FormItem wrapperCol={{ offset: 5 }}>
            </FormItem>
            <FormItem wrapperCol={{ offset: 5 }}>
                <Button style={{width:100}} type='primary' htmlType='submit'>保存</Button>
            </FormItem>
        </Form>
    )
})

import {Form, Input, Avatar, Message, Modal, Button, Alert, Card} from '@arco-design/web-react';
import styles from "./index.module.less"
import { WechatLogin,CheckCodeModal } from '../../../components';
import { userSelf } from '../../../store';
import { getQuery } from '../../../utils';
import {useState,useRef,useEffect} from "react";
import { observer } from "mobx-react-lite"
import {Link} from "react-router-dom";
import Uploader from "../Uploader";
const FormItem = Form.Item;


export default observer(function (){
    const query=getQuery()
    const isBind=!!userSelf.wechatName
    const handleUnbindWechat=async (formData)=>{
        const result=await userSelf.unbindWechat(formData)
        if(result){
            Message.success("解绑成功")
        }
    }

    return (
        <Card title={'绑定微信'} className={styles.wechatBind} bodyStyle={{position:"relative"}}>
            {
                isBind?(
                    <div>
                        <Alert
                            banner={true}
                            className={styles.banner}
                            type={'success'}
                            content='你已成功绑定微信，可使用微信登录电脑版、手机版美刊查看文章。' />
                        <br/>
                        <br/>
                        <Form
                            key={userSelf.id}
                            style={{ width: 400 }}
                            initialValues={userSelf}
                        >
                            <FormItem label='昵称' field={'wechatName'} disabled>
                                <Input placeholder='16个字符以内'  />
                            </FormItem>
                            <FormItem label='头像' field={'wechatAvatar'} disabled>
                                <Avatar size={64}>
                                    <img src={userSelf.wechatAvatar} alt="" />
                                </Avatar>
                            </FormItem>
                            <FormItem label=' '>
                                <br/>
                                {/*<CheckCodeModal*/}
                                {/*    buttonText={'解除绑定'}*/}
                                {/*    title={'解除绑定'}*/}
                                {/*    smsType={'unbind'}*/}
                                {/*    onOk={handleUnbindWechat}*/}
                                {/*/>*/}
                            </FormItem>
                        </Form>
                    </div>
                ):(
                    <div>
                        <Alert
                            banner={true}
                            className={styles.banner}
                            content='你还未绑定微信，无法使用微信登录功能，建议绑定，可直接在朋友圈查看文章。' />
                        <br/>
                        <br/>
                        <br/>
                        <WechatLogin state={{
                            userId:userSelf.id,
                            type:'bind',
                            redirect:'/setting/bind'
                        }} tips={'首次扫码登录即可绑定'}/>
                    </div>
                )
            }

        </Card>
    )
})

export const MP_SUBSCRIBE_URL =
    "https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzkxODM5MjkwMw==&scene=124#wechat_redirect";
// 微信公众号使用介绍文章
export const MP_INTRODUCE_URL = "https://mp.weixin.qq.com/s/Kmu5czyWj02Tn1fK3bVUNA";
// 积分规则文章
export const MP_POINTS_RULE_URL = "https://mp.weixin.qq.com/s/URpOaUHvITHi4_V5vGopqQ";

export const PROTOCOL={
    PERSONAL_INFORMATION_COLLECTION:{
        title:'个人信息收集',
        url:'https://meikan-public-images.oss-cn-beijing.aliyuncs.com/imeikan/assets/2023-10-29115558-%E6%AF%8F%E6%97%A5%E7%BE%8E%E5%88%8A%E4%B8%AA%E4%BA%BA%E4%BF%A1%E6%81%AF%E6%94%B6%E9%9B%86%E6%B8%85%E5%8D%95.pdf'
    },
    INFORMATION_SHARING:{
        title:'信息共享',
        url:'https://meikan-public-images.oss-cn-beijing.aliyuncs.com/imeikan/assets/2023-10-29114934-%E7%BE%8E%E5%88%8A%E4%BF%A1%E6%81%AF%E5%85%B1%E4%BA%AB%E6%B8%85%E5%8D%95.pdf'
    },
    CANCELLATION_NOTICE:{
        title:'注销须知',
        url:'https://meikan-public-images.oss-cn-beijing.aliyuncs.com/imeikan/assets/2023-10-29115820-%E6%AF%8F%E6%97%A5%E7%BE%8E%E5%88%8A%E6%B3%A8%E9%94%80%E9%A1%BB%E7%9F%A5.pdf'
    },
    PRIVACY_AGREEMENT:{
        title:'美刊网隐私政策',
        url:'https://meikan-public-images.oss-cn-beijing.aliyuncs.com/imeikan/assets/2023-10-29121734-%E6%AF%8F%E6%97%A5%E7%BE%8E%E5%88%8A%E9%9A%90%E7%A7%81%E6%94%BF%E7%AD%96.pdf'
    },
    REGISTRATION_AGREEMENT:{
        title:'用户注册协议',
        url:'https://meikan-public-images.oss-cn-beijing.aliyuncs.com/imeikan/assets/2023-10-29122246-%E6%AF%8F%E6%97%A5%E7%BE%8E%E5%88%8A%E7%94%A8%E6%88%B7%E5%8D%8F%E8%AE%AE.pdf'
    }
}

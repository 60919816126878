import { Form, Input, Grid,Typography,Modal,Link ,Button} from '@arco-design/web-react';
import styles from "./index.module.less"
import {useState} from 'react'

const Row = Grid.Row;
const Col = Grid.Col;
const { Text } = Typography;
const FormItem = Form.Item;


export default function ({visible,onCancel,onOk}){

    return (
        <div>
            <Modal
                title={(
                    <div style={{ textAlign: 'left' }}> 用户登录 </div>
                )}
                visible={visible}
                onCancel={onCancel}
                onOk={onOk}
            >
                <Form>
                    <FormItem label='账号'>
                        <Input placeholder='请输入电话号码...' />
                    </FormItem>
                    <FormItem label='密码'>
                        <Input placeholder='请输入密码...' />
                    </FormItem>

                    <FormItem
                        wrapperCol={{
                            offset: 5,
                        }}
                    >
                        <Button type='primary'>登录</Button>
                    </FormItem>
                </Form>
            </Modal>
        </div>
    )
}

import {Layout, PageHeader, Grid, Card, Avatar, Link,Typography} from '@arco-design/web-react';
import {Breadcrumb,CardList,PostList,Author} from '../../components'
import styles from "./index.module.less"
import { getQuery } from "../../utils";
import { observer } from "mobx-react-lite"
import {category} from "../../store/List"
import useTouchBottom from "../../hooks/useTouchBottom";

const Content = Layout.Content;
const Row = Grid.Row;
const Col = Grid.Col;
const { Meta } = Card;

export default observer(function (){
    const query=getQuery()
    const isSubPage=!!query.title
    const routes=[{
        path: '../',
        name: '首页',
    },
    {
        name: '分类',
    }]
    useTouchBottom(()=>{
        category.loadmore({limit:12})
    });

    return (
        <Content className={styles.category}>
            {
                <Breadcrumb routes={routes}/>
            }
            <CardList list={category.list}/>
        </Content>
    )
})

import { Form, Input, Grid,Message,Modal,Checkbox ,Button} from '@arco-design/web-react';
import styles from "./index.module.less"
import { Content,LoginPanel } from '../../components';
import { userSelf } from '../../store';
import {useState,useRef,useEffect} from "react";
import { Link,useNavigate } from "react-router-dom";
import {PROTOCOL} from "../../utils/constants";


const Row = Grid.Row;
const Col = Grid.Col;
const FormItem = Form.Item;

export default function (){
    const [countDown,setCountDown]=useState(0)
    const [agree, setAgree] = useState(false);
    const [form] = Form.useForm();
    const timer = useRef(0);
    const navigate=useNavigate()

    useEffect(()=> {
        if( countDown === 60 ) timer.current = setInterval(()=> setCountDown(time => --time), 1000)
        else if ( countDown === 0 ) {
            clearInterval(timer.current)
            timer.current=0
        }
    }, [countDown])

    const handleSendClick=async ()=>{
        const phoneNumber=form.getFieldValue('phoneNumber')
        if(timer.current) return
        await userSelf.sendCheckCode(phoneNumber)
        setCountDown(60)
    }
    const handleSubmit=async (formData)=>{
        const result=await userSelf.register(formData)
        if(result&&result.id){
            Message.success('注册成功！')
            navigate(`/bind?uid=${result.id}`)
        }
    }
    const handleSubmitFailed=(errors)=>{
        console.log(errors)
    }

    return (
        <LoginPanel>
            <div className={styles.title}>用户注册</div>
            <Form
                autoComplete="off"
                form={form}
                // initialValues={{
                //     phoneNumber:'18500595626',
                //     passwordAgain:'18500595626',
                //     checkCode:'182367',
                //     password:'18500595626',
                // }}
                onSubmit={handleSubmit}
                onSubmitFailed={handleSubmitFailed}>
                <FormItem label='手机号码' field={'phoneNumber'} rules={[{ required: true,  },{match:/^[0-9]{11}$/,message:'请输入正确的电话号码'}]}>
                    <Input placeholder='请输入手机号...' />
                </FormItem>
                <FormItem label='验证码'  style={{ marginBottom: 0 }}>
                    <Grid.Row gutter={8}>
                        <Grid.Col span={14} >
                            <Form.Item field='checkCode' rules={[{ required: true,  }]}>
                                <Input placeholder='请输入验证码...' />
                            </Form.Item>
                        </Grid.Col>
                        <Grid.Col span={10} >
                            <Form.Item>
                                <Button type={'primary'} style={{width:'100%'}} onClick={handleSendClick} disabled={countDown>0}>
                                    {countDown<=0?'发送验证码':countDown+'s后重发'}
                                </Button>
                            </Form.Item>
                        </Grid.Col>
                    </Grid.Row>
                </FormItem>
                <FormItem label='密码' field='password' rules={[{ required: true,  }]}>
                    <Input type={'password'} placeholder='请输入密码...' />
                </FormItem>
                <FormItem label='确认密码' field='passwordAgain' rules={[{ required: true, },{validator:(value,cb)=>value!==form.getFieldValue('password')?cb('两次密码不相同'):''}]}>
                    <Input type={'password'} placeholder='请确认输入密码...' />
                </FormItem>
                <FormItem triggerPropName={'checked'} requiredSymbol={false} label=' ' field='agree'  rules={[{ required: true, message:'请先阅读和同意用户协议'},{ match: /true/, message:'请先阅读和同意用户协议'}]}>
                    <Checkbox>
                        我已阅读并同意
                        <a target={'_blank'} href={PROTOCOL.REGISTRATION_AGREEMENT.url} rel="noreferrer">《{PROTOCOL.REGISTRATION_AGREEMENT.title}》</a>
                        和
                        <a target={'_blank'} href={PROTOCOL.PRIVACY_AGREEMENT.url} rel="noreferrer">《{PROTOCOL.PRIVACY_AGREEMENT.title}》</a>
                    </Checkbox>

                </FormItem>
                <FormItem label={'  '}>
                    <Button style={{width:'100%'}} type='primary' htmlType='submit'>注册</Button>
                </FormItem>
            </Form>
        </LoginPanel>
    )
}

import React from "react";
import styles from "./index.module.less";
import unknownImg from "../../assets/images/unknow.png";

export default function Favicon({ url, source }) {
  const handleError = (e) => {
    e.target.src = unknownImg;
  };
  let origin = "",hostname = "";
  try{
    if (!url && source) {
      const urlInstance = new URL(source);
      origin = urlInstance.origin;
      hostname = urlInstance.hostname;
    }
  }catch (e){
    console.log()
  }

  if(!url&&!source) return null;
  let src = url || `${origin}/favicon.ico`;
  // 不加载http 资源，解决360 不安全提示
  if(src.startsWith('http:')) src=unknownImg
  return (
      <span className={styles.box}>
        <img onError={handleError} className={styles.favicon} src={src} alt="" />
        {hostname}
      </span>
  );
}
